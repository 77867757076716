import { CalendarIcon, EmailIcon } from '@chakra-ui/icons'
import {
  Box,
  BoxProps,
  Button,
  Center,
  CircularProgress,
  Divider,
  Flex,
  FlexProps,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  colors,
  Delivery,
  getCoverageNextActions,
  getDateString,
  getPracticeAssessmentCollectionPath,
  InsuranceCoverage,
  InsuranceCoverageId,
  NextAction,
  NextActionType,
  stringifyNextAction,
  toSearchString,
  WithId,
  WithMetadata,
} from '@hb/shared'
import React, { PropsWithChildren, useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { DataListContext } from '../../../../contexts'
import cashIcon from '../../../../icons/cash.svg'
import { AppData } from '../../../../types'
import { DataColumn, DataColumnRenderProps } from '../../../../types/data'
import { ActionButton } from '../../../Buttons'
import { getRowBackground } from '../../../DataView'
import { EllipsisText } from '../../../EllipsisText'
import { useAssessmentThread } from '../../../Messaging/hooks'
import { RecentMessageView } from '../../../Messaging/ThreadView/RecentMessageView'
import {
  CollectionNextActionContent,
  CoverageNextActionContent,
} from '../../../Users/Profile/NextAction'
import { SortButton } from '../../SortButton'
import { AdminAssessmentColumn, AssessmentColumn, PracticeAssessmentColumn } from './types'
import { UnreadMessagesBadge } from './UnreadMessageBadge'

const getMatchesQuery = (
  nextAction: NextAction | undefined,
  searchQuery: string,
  coverage: InsuranceCoverage | null | undefined,
) => {
  if (!searchQuery) return false
  const stringified = stringifyNextAction(nextAction, coverage)
  const stringifiedQuery = toSearchString(searchQuery)
  return stringified.includes(stringifiedQuery)
}
const CollectionNextActionPopover = ({
  item,
  collection,
  searchQuery,
  label,
}: {
  collection: string
  label: string
  item: WithId
  searchQuery: string
}) => {
  const { nextActionText, nextAction, id } = item || {}
  const { text: baseText } = nextAction || {}
  const matchesQuery = getMatchesQuery(
    nextAction || { text: nextActionText },
    searchQuery,
    undefined,
  )
  const text = nextActionText || baseText
  const bg = matchesQuery ? 'green.400' : 'gray.50'
  const color = matchesQuery ? 'white' : 'blue.600'

  return (
    <Box
      w="100%"
      onClick={e => {
        e.stopPropagation()
      }}>
      <Popover strategy="fixed" isLazy>
        {({ isOpen }) => (
          <>
            <PopoverTrigger>
              <Button
                w="100%"
                justifyContent="flex-start"
                size="sm"
                h={8}
                boxShadow={`0 0 4px ${matchesQuery ? colors.green.hex : '#00000044'}`}
                bg={isOpen ? 'blue.600' : bg}
                _hover={{
                  bg: isOpen ? 'blue.400' : 'blue.200',
                  color: 'white',
                }}>
                <Text
                  position="relative"
                  // width={itemWidth}
                  height="18px"
                  textAlign="left"
                  color={isOpen ? 'white' : color}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  fontWeight={400}
                  fontSize="sm">
                  {!text || text === 'zzz' ? 'None' : text}
                </Text>
              </Button>
            </PopoverTrigger>
            <Portal>
              <PopoverContent px={2} py={1} bg="gray.50" w="auto">
                <PopoverBody>
                  <Text fontWeight={500}>{label} Next Action</Text>
                  <Flex w="350px">
                    <CollectionNextActionContent
                      id={id}
                      collection={collection}
                      item={item as any}
                    />
                  </Flex>
                </PopoverBody>
                <PopoverCloseButton />
                <PopoverArrow bg="gray.50" />
              </PopoverContent>
            </Portal>
          </>
        )}
      </Popover>
    </Box>
  )
}

const nextActionPopoverContentFlexProps: FlexProps = {
  flexFlow: 'column-reverse',
  gap: 0,
}

const nextActionDateContainerProps: BoxProps = {
  py: 1,
}

const CoverageNextActionPopover = ({
  nextAction,
  id,
  searchQuery,
  coverage,
  itemId,
}: {
  nextAction: NextAction | undefined
  id: InsuranceCoverageId
  coverage: InsuranceCoverage | undefined | null
  searchQuery: string
  itemId: string
}) => {
  const { text } = nextAction || {}
  const matchesQuery = getMatchesQuery(nextAction, searchQuery, coverage)
  const bg = matchesQuery ? 'green.400' : 'gray.50'
  const color = matchesQuery ? 'white' : 'blue.600'
  const label = useMemo(() => {
    if (id === 'primaryCoverage') return 'Primary'
    if (id === 'medicaidCoverage') return 'Medicaid'
    return 'Potential Plan'
  }, [id])
  return (
    <Popover strategy="fixed" isLazy>
      {({ isOpen }) => (
        <>
          <PopoverTrigger>
            <Button
              w="100%"
              justifyContent="flex-start"
              size="sm"
              h={9}
              position="relative"
              boxShadow={`0 0 4px ${matchesQuery ? colors.green.hex : '#00000022'}`}
              bg={isOpen ? 'blue.600' : bg}
              _hover={{
                bg: isOpen ? 'blue.400' : 'blue.200',
                color: 'white',
              }}>
              <Text
                position="relative"
                top="6px"
                // width={itemWidth}
                height="18px"
                textAlign="left"
                color={isOpen ? 'white' : color}
                overflow="hidden"
                textOverflow="ellipsis"
                fontWeight={400}
                fontSize="sm">
                {!text || text === 'zzz' ? 'None' : text}
              </Text>
              <Text
                fontSize="xs"
                textShadow={matchesQuery || isOpen ? '1px 1px 3px #00000055' : 'none'}
                color={matchesQuery || isOpen ? 'gray.100' : 'gray.500'}
                opacity={0.7}
                top="2px"
                position="absolute">
                {label.toUpperCase()} - {coverage?.insuranceProvider?.name || 'No Insurer'}
              </Text>
            </Button>
          </PopoverTrigger>
          <PopoverContent bg="gray.50" w="auto">
            <PopoverArrow bg="gray.50" />
            <PopoverBody p={0}>
              <Text px={2} py={1} fontWeight={500}>
                {label} Next Action
              </Text>
              <Flex w="350px">
                <CoverageNextActionContent
                  flexProps={nextActionPopoverContentFlexProps}
                  dateContainerProps={nextActionDateContainerProps}
                  assessmentId={itemId}
                  id={id}
                  nextAction={nextAction}
                />
              </Flex>
            </PopoverBody>
            <PopoverCloseButton />
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}

export const getRenderItemNextAction =
  <T extends NextActionType>(collection: string, label: string): DataColumn<T>['Render'] =>
  ({ data, searchQuery }) => (
    <Flex pr={2} gap={1} w="100%" align="center">
      <CollectionNextActionPopover
        label={label}
        searchQuery={searchQuery}
        collection={collection}
        item={data}
      />
    </Flex>
  )

export const RenderCoverageNextActions: AdminAssessmentColumn['Render'] = ({
  data,
  searchQuery,
}) => {
  const nextActionsArr = getCoverageNextActions(data)
  return (
    <Flex gap={1} w="100%" flexFlow="column" align="center">
      {nextActionsArr.map(({ id: coverageId, coverage, nextAction }) => (
        <Box flex={1} key={coverageId} w="100%">
          <CoverageNextActionPopover
            itemId={data.id}
            coverage={coverage}
            id={coverageId}
            searchQuery={searchQuery}
            nextAction={nextAction || undefined}
          />
        </Box>
      ))}
    </Flex>
  )
}

export const getRenderPracticeAssessmentNextAction = (practiceId: string) =>
  getRenderItemNextAction(
    getPracticeAssessmentCollectionPath(practiceId),
    'Pregnancy',
  ) as PracticeAssessmentColumn['Render']

export const insurerColumn: AssessmentColumn = {
  title: 'INSURER',
  Header: () => <SortButton sortKey="insuranceCoverage">INSURER</SortButton>,
  sortKey: 'insuranceCoverage',
  Render: ({ data }) => (
    <EllipsisText
      // maxWidth={itemWidth}
      text={
        'insuranceCoverage' in data && data?.insuranceCoverage && data.insuranceCoverage !== 'zzz'
          ? `${data?.insuranceCoverage}${data.planState ? ` | ${data.planState}` : ''}`
          : 'No coverage'
      }
    />
  ),
}

type DeliveredOnItem = {
  deliveredOn: number | null
  delivery?: WithMetadata<Partial<Delivery>> | null
}
export const getDeliveredOnColumn = <T extends DeliveredOnItem>(): DataColumn<T> => ({
  title: 'Date of Delivery',
  Header: () => (
    <Flex align="center" flexGrow={1}>
      <SortButton defaultDir="asc" sortKey="deliveredOn">
        <CalendarIcon />
        <Text>DELIVERY</Text>
      </SortButton>
    </Flex>
  ),
  width: 106,
  sortKey: 'deliveredOn',
  defaultSortDirection: 'desc',
  Render: props => {
    let text = 'None'
    if (props.data?.deliveredOn && props.data?.deliveredOn !== Infinity) {
      text = getDateString(props.data.deliveredOn, 'short')
    } else if (props.data?.delivery?.isTransfer || props.data?.deliveredOn === Infinity) {
      text = 'Transfer'
    }
    return (
      <Stack direction="row" spacing={2} pl={1} pr={1} width="100%" align="center">
        <Text
          opacity={props.data?.deliveredOn || props?.data?.delivery?.isTransfer ? 1 : 0.7}
          flex={1}>
          {text}
        </Text>
      </Stack>
    )
  },
  mobile: {
    width: 160,
    Render: ({ data }) => {
      const { deliveredOn } = data || {}
      return (
        <Center
          py={1}
          boxShadow="1px 1px 3px #00000077"
          gap={1}
          bg="gray.100"
          h="28px"
          px={2}
          borderRadius={4}
          w="100%"
          flex={1}>
          <Text
            lineHeight={1}
            fontFamily="Hero-New"
            fontSize="xs"
            fontWeight={600}
            color="gray.500">
            DELIVERED
          </Text>
          <Text ml="auto" lineHeight={1} fontFamily="Open Sans" fontSize="sm" color="gray.600">
            {deliveredOn ? getDateString(deliveredOn, 'short') : 'No'}
          </Text>
        </Center>
      )
    },
  },
})

const OverduePaymentIndicator = ({
  hasOverduePayment,
  ...props
}: BoxProps & { hasOverduePayment?: boolean }) => (
  <Box px={1} {...props}>
    <Tooltip
      placement="top"
      hasArrow
      bg={hasOverduePayment ? colors.green.hex : 'yellow.600'}
      label={hasOverduePayment ? 'Overdue payment' : 'No overdue payments'}>
      <Image
        opacity={hasOverduePayment ? 1 : 0.7}
        filter={`grayscale(${hasOverduePayment ? 0 : 100}%)`}
        width="20px"
        alt="payment past due"
        src={cashIcon}
      />
    </Tooltip>
  </Box>
)

type HasOverduePaymentItem = {
  hasOverduePayment?: boolean
}

export const getOverduePaymentColumn = <T extends HasOverduePaymentItem>(): DataColumn<T> => ({
  title: 'Overdue Payment',
  Header: () => {
    const { sortKey } = useContext(DataListContext)
    return (
      <Stack align="center" direction="row" width="100%">
        <SortButton defaultDir="asc" noArrow sortKey="hasOverduePayment">
          <OverduePaymentIndicator hasOverduePayment={sortKey === 'hasOverduePayment'} />
        </SortButton>
      </Stack>
    )
  },
  // flexProps: { width: '100%' },
  width: 40,
  sortKey: 'hasOverduePayment',
  defaultSortDirection: 'asc',
  Render: ({ data: { hasOverduePayment } }) => (
    <Center w="40px">
      <OverduePaymentIndicator hasOverduePayment={hasOverduePayment} />
    </Center>
  ),
  mobile: {
    width: 130,
    Render: ({ data }) => {
      const { hasOverduePayment } = data || {}
      return (
        <Center
          py={1}
          boxShadow="1px 1px 3px #00000077"
          gap={1}
          bg={hasOverduePayment ? 'yellow.500' : 'gray.100'}
          borderRadius={4}
          w="100%"
          h="28px"
          flex={1}>
          <OverduePaymentIndicator hasOverduePayment={hasOverduePayment} />
          <Text
            textShadow={hasOverduePayment ? '1px 1px 3px #00000077' : 'none'}
            lineHeight={1}
            fontFamily="Hero-New"
            fontSize="xs"
            fontWeight={600}
            color={hasOverduePayment ? 'white' : 'gray.500'}>
            {hasOverduePayment ? 'OVERDUE' : 'NOT OVERDUE'}
          </Text>
        </Center>
      )
    },
  },
})

const UnreadMessagePreviewContent = <T extends { unreadThreadMessages: number }>({
  assessmentId,
  item,
  tabName,
  app,
}: {
  assessmentId: string
  item: WithId<T>
  tabName: string
  app: AppData
}) => {
  const { data: thread, loading } = useAssessmentThread(assessmentId)

  const mostRecent = useMemo(
    () => (thread?.mostRecentMessages ? thread.mostRecentMessages.slice(0, 3) : []),
    [thread],
  )

  return (
    <Flex flexFlow="column" w="350px">
      <VStack w="100%" divider={<Divider />} spacing={0}>
        <Flex py={1} pl={2} pr={10} align="center" w="100%" gap={2}>
          <Text fontWeight={500}>Thread Messages</Text>
          <CircularProgress
            isIndeterminate={loading}
            opacity={loading ? 1 : 0}
            transition="opacity 300ms"
            size={4}
            color="green"
          />
          <Link
            style={{
              marginLeft: 'auto',
            }}
            to={`${
              app.appName === 'app' ? '/admin' : ''
            }/pregnancies/${item.id}/${tabName === 'Claim' ? 'claims' : 'assessment'}`}>
            <ActionButton size="xs" colorScheme="green" borderRadius="full">
              View Assessment
            </ActionButton>
          </Link>
        </Flex>
        {mostRecent.length ? (
          mostRecent.map((message, i) => (
            <Box bg={getRowBackground(i)} px={2} key={`${message.createdOn}`} py={1} w="100%">
              <RecentMessageView vertical message={message} />
            </Box>
          ))
        ) : (
          <Text py={1} px={2} fontStyle="italic">
            No unread messages
          </Text>
        )}
      </VStack>
      {/* <CollectionNextActionContent id={id} collection={collection} item={item as any} /> */}
    </Flex>
  )
}

const UnreadMessagePreviewPopover = <T extends { unreadThreadMessages: number }>({
  children,
  data: item,
  tabName,
  app,
  mobile,
}: PropsWithChildren<DataColumnRenderProps<WithId<T>> & { mobile?: boolean }>) => {
  return (
    <Popover placement="right" trigger={mobile ? 'click' : 'hover'} strategy="fixed" isLazy>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent p={0} bg="gray.50" w="auto">
        <PopoverArrow bg="gray.50" />
        <UnreadMessagePreviewContent
          app={app}
          tabName={tabName}
          item={item}
          assessmentId={item.id}
        />
        <PopoverCloseButton />
      </PopoverContent>
    </Popover>
  )
}

type UnreadThreadMessagesItem = {
  unreadThreadMessages: number
}
export const getUnreadMessagesColumn = <
  Data extends UnreadThreadMessagesItem,
>(): DataColumn<Data> => ({
  title: 'Unread Messages',
  Header: () => {
    const { sortKey } = useContext(DataListContext)
    return (
      <SortButton defaultDir="asc" noArrow sortKey="unreadThreadMessages">
        <Tooltip placement="top" hasArrow label="Unread Messages">
          <Box>
            <UnreadMessagesBadge isUnread={sortKey === 'unreadThreadMessages'} />
          </Box>
        </Tooltip>
      </SortButton>
    )
  },
  defaultSortDirection: 'asc',
  width: 34,
  sortKey: 'unreadThreadMessages',
  Render: props => {
    const { unreadThreadMessages } = props.data || {}
    return (
      <UnreadMessagePreviewPopover {...props}>
        <Center cursor="pointer" w="100%" flex={1}>
          <UnreadMessagesBadge isUnread={!!unreadThreadMessages} />
        </Center>
      </UnreadMessagePreviewPopover>
    )
  },
  mobile: {
    width: 100,
    Render: props => {
      const { unreadThreadMessages } = props.data || {}
      return (
        <UnreadMessagePreviewPopover mobile {...props}>
          <Center
            boxShadow="1px 1px 3px #00000077"
            gap={1}
            bg={unreadThreadMessages ? colors.green.hex : 'gray.100'}
            borderRadius={4}
            w="100%"
            height="28px"
            flex={1}>
            <EmailIcon height="16px" color={!unreadThreadMessages ? 'gray.500' : 'white'} />
            <Text
              lineHeight={1}
              fontFamily="Hero-New"
              fontSize="xs"
              textShadow={unreadThreadMessages ? '1px 1px 3px #00000077' : 'none'}
              fontWeight={600}
              color={unreadThreadMessages ? 'white' : 'gray.500'}>
              {unreadThreadMessages} UNREAD
            </Text>
          </Center>
        </UnreadMessagePreviewPopover>
      )
    },
  },
})
