import { Box, Flex, HStack, Progress, Stack, StackDivider } from '@chakra-ui/react'
import { adminsCollection, AnyObject, CollectionFilter, SortKey, WithId } from '@hb/shared'
import useResizeObserver from '@react-hook/resize-observer'
import { FieldPath } from 'firebase/firestore'
import React, {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useHistory } from 'react-router'
import { VariableSizeGrid, VariableSizeList } from 'react-window'
import { useCollection } from '../../collections/hooks/useCollection'
import { DataListContext, ScreenContext, SearchBarContext } from '../../contexts'
import { useApp } from '../../contexts/AppContext'
import { useSortedCollection } from '../../hooks/backend/useSortedCollection'
import { useAuth } from '../../store'
import { DataColumns, DataListTab } from '../../types/data'
import { GenericEditModal } from '../DataView'
import { DataListFooter } from './DataListFooter'
import { DataListTabs } from './DataListTabs'
import { DesktopDataList } from './DesktopDataList'
import { MobileDataList } from './MobileDataList'
import { DataListPresetFilters } from './PresetFilters'
import { SearchBar } from './SearchBar'
import { getFlexColumnWidth } from './utils'

const excludeOmittedBooleanFilters = (
  filters: CollectionFilter[],
  userFilters: CollectionFilter[],
) => filters.filter(f => !userFilters.find(uf => uf[0] === f[0] && typeof uf[2] === 'boolean'))
const defaultItemHeight = 36
const numItems = 100

export const DataList = <Data extends AnyObject, ExtraColumnProps extends AnyObject = AnyObject>({
  tabs,
  rootPath,
  tabIndex: _tabIndex,
  onRowClick,
  onTabChange,
  preview,
  width: propWidth,
  extraColumnProps,
  height,
}: {
  tabs: Record<string, DataListTab<Data, ExtraColumnProps>>
  rootPath: string
  extraColumnProps?: ExtraColumnProps
  tabIndex?: number
  onTabChange?: (index: number) => void
  preview?: boolean
  onRowClick?: (id: string, tabIndex: number) => void
  width?: number
  height: number
}) => {
  const { searchQuery } = useContext(SearchBarContext)
  const history = useHistory()
  const selectItem = useCallback(
    (id: string | null) => history.push(id ? `/admin/${rootPath}/${id}` : `/admin/${rootPath}`),
    [history, rootPath],
  )

  const [userFilters, setUserFilters] = useState<Array<CollectionFilter>>([])
  const [autoTabIndex, setTabIndex] = useState(0)

  const tabChangeLoadTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)
  const [tabChangeLoading, setTabChangeLoading] = useState(false)
  useEffect(() => {
    if (tabChangeLoading) {
      tabChangeLoadTimeout.current = setTimeout(() => {
        setTabChangeLoading(false)
      }, 500)
    }
    return () => {
      if (tabChangeLoadTimeout.current) {
        clearTimeout(tabChangeLoadTimeout.current)
        tabChangeLoadTimeout.current = null
      }
    }
  }, [tabChangeLoading])

  const tabIndex = useMemo(
    () => (_tabIndex !== undefined ? _tabIndex : autoTabIndex),
    [_tabIndex, autoTabIndex],
  )
  const handleTabChange = useCallback(
    (index: number) => {
      if (index === tabIndex) return
      setTabChangeLoading(true)
      setUserFilters([])
      if (onTabChange) onTabChange(index)
      else setTabIndex(index)
    },
    [onTabChange, tabIndex],
  )
  const tabName = useMemo(() => Object.keys(tabs)[tabIndex], [tabIndex, tabs])
  const [sortKey, setSortKey] = useState<SortKey<Data>>(tabs[tabName]?.defaultSortKey)
  const [sortAsc, setSortAsc] = useState<boolean>(false)

  const sortBy = useCallback(
    (k: SortKey<Data>, direction?: 'asc' | 'desc') => {
      if (k === sortKey) {
        setSortAsc(!sortAsc)
      } else {
        if (direction) setSortAsc(direction === 'asc')
        else setSortAsc(false)
        setSortKey(k)
      }
    },
    [sortKey, sortAsc],
  )

  const filterBy = useCallback(
    (filter: CollectionFilter) => {
      setUserFilters(prev => {
        const newFilters = prev.filter(f => f[0] !== filter[0])
        newFilters.push(filter)
        return newFilters
      })
    },
    [setUserFilters],
  )

  const removeFilter = useCallback(
    (filterKey: string | FieldPath) => {
      setUserFilters(prev => prev.filter(f => f[0] !== filterKey))
    },
    [setUserFilters],
  )

  const tab = useMemo(() => tabs[tabName] || tabs[Object.keys(tabs)[0]], [tabName, tabs])

  const filters = useMemo(
    () => [...excludeOmittedBooleanFilters(tab.filters || [], userFilters), ...userFilters],
    [tab, userFilters],
  )

  const { columns, transformData, itemName, defaultSortKey, creation, itemHeight } = tab

  const appData = useApp()

  const [newItemModalOpen, setNewItemModalOpen] = useState(false)

  useEffect(() => {
    const defaultKey = tabs[tabName]?.defaultSortKey
    const defaultColumn = Object.values(tabs[tabName]?.columns).find(c => c.sortKey === defaultKey)
    const defaultDirection = defaultColumn?.defaultSortDirection || 'desc'
    if (defaultKey) {
      setSortKey(defaultKey)
      setSortAsc(defaultDirection === 'asc')
    }
  }, [tabName, tabs])
  const {
    items,
    loading: dataLoading,
    goNext,
    goPrev,
    canGoNext,
    canGoPrev,
    goToPage,
    pageNum,
    queryCount,
    totalPages,
  } = useSortedCollection<Data>(
    {
      query: searchQuery,
      sortKey,
      sortFunc: columns[sortKey]?.sortFunc,
      sortDesc: sortAsc,
      ...tabs[tabName],
      filters,
    },
    numItems,
  )
  const ref = useRef<VariableSizeList | VariableSizeGrid>(null)

  const hasMultipleTabs = useMemo(() => Object.keys(tabs).length > 1, [tabs])

  const admins = useCollection(adminsCollection)

  const { width: screenWidth } = useContext(ScreenContext)

  const fullWidth = useMemo(
    () => propWidth || Math.max(0, screenWidth - screenWidth * 0.0725),
    [screenWidth, propWidth],
  )

  const [headerHeight, setHeaderHeight] = useState(0)
  const [footerHeight, setFooterHeight] = useState(0)
  const headerRef = useRef<HTMLDivElement>(null)

  const onHeaderResize = useCallback(
    (entry: ResizeObserverEntry) => {
      setHeaderHeight(entry.contentRect.height)
    },
    [setHeaderHeight],
  )

  useResizeObserver(headerRef, onHeaderResize)

  const bodyHeight = useMemo(
    () => (preview ? height : height - headerHeight - footerHeight - 50),
    [height, headerHeight, footerHeight, preview],
  )

  const gridView = useMemo(() => fullWidth > 800, [fullWidth])
  const width = useMemo(() => {
    const hasNoFlexCols = Object.values(tab.columns).every(c => !c.width)
    const preScrollbar = hasNoFlexCols
      ? Object.values(tab.columns).reduce((acc, curr) => acc + (curr.width || 0), 0)
      : fullWidth
    const gridItemHeight = typeof itemHeight === 'number' ? itemHeight || defaultItemHeight : null
    const hasScrollbar =
      gridView && gridItemHeight
        ? gridItemHeight * Object.keys(items || {}).length > bodyHeight
        : false
    return preScrollbar - (hasScrollbar ? 8 : 0)
  }, [fullWidth, tab, gridView, items, bodyHeight, itemHeight])

  const usedItemHeight = useMemo(() => itemHeight || defaultItemHeight, [itemHeight])

  const contextData = useMemo(
    () => ({
      sortBy: sortBy as any,
      tab,
      filterBy,
      removeFilter,
      toggleSortDirection: () => setSortAsc(!sortAsc),
      filters,
      admins,
      sortKey,
      userFilters,
      onRowClick,
      extraColumnProps,
      gridView,
      sortDesc: !sortAsc,
    }),
    [
      sortBy,
      sortAsc,
      filters,
      sortKey,
      onRowClick,
      gridView,
      extraColumnProps,
      admins,
      filterBy,
      removeFilter,
      tab,
      userFilters,
    ],
  )

  const initialNewItemData = useMemo(() => {
    const initData = creation?.initialData
    if (!initData || !newItemModalOpen) return null
    return typeof initData === 'function' ? initData() : initData
  }, [creation, newItemModalOpen])

  const loading = useMemo(() => dataLoading || tabChangeLoading, [dataLoading, tabChangeLoading])

  const { onCreate } = creation || {}
  useEffect(() => {
    const asGrid = ref.current as VariableSizeGrid | undefined
    const asList = ref.current as VariableSizeList | undefined
    const rowIndexReset = gridView ? asGrid?.resetAfterRowIndex : asList?.resetAfterIndex
    if (!rowIndexReset) return
    rowIndexReset(0)
  }, [usedItemHeight, gridView, items])
  const onCreateClick = useMemo(() => {
    if (!creation) return undefined
    const { onCreateClick } = creation
    if (onCreateClick) return () => onCreateClick()
    else return () => setNewItemModalOpen(true)
  }, [creation])
  // const [selectedIndex, setSelectedIndex] = useState(0)
  return (
    <DataListContext.Provider value={contextData}>
      <Flex height={`${height}px`} position="relative" direction="column" align="flex-start">
        <Stack
          width={fullWidth}
          divider={<StackDivider />}
          spacing={0}
          height="100%"
          zIndex={1}
          boxShadow="0 0 6px rgba(0,0,0,0.5)"
          bg="white">
          {preview ? null : (
            <Flex zIndex={2} flexFlow="column" w="100%" ref={headerRef}>
              {hasMultipleTabs ? (
                <DataListTabs index={tabIndex} onChange={handleTabChange} tabs={tabs} />
              ) : null}
              <HStack px={1} pt={hasMultipleTabs ? 0 : 1} spacing={0}>
                {tab.searchStringPath ? (
                  <Box flex={1}>
                    <SearchBar
                      onAddClick={onCreateClick}
                      itemName={itemName}
                      creationItemName={creation?.itemName}
                    />
                  </Box>
                ) : null}
                <DataListPresetFilters />
              </HStack>
              <Progress colorScheme="green" height="5px" isIndeterminate={loading} />
            </Flex>
          )}
          {!gridView ? (
            <MobileDataList
              ref={ref as RefObject<VariableSizeList>}
              active
              sortAsc={sortAsc}
              admins={admins}
              toggleSortDirection={() => setSortAsc(!sortAsc)}
              sortKey={sortKey}
              loading={loading}
              selectUser={selectItem}
              preview={preview}
              width={width}
              fullWidth={fullWidth}
              height={bodyHeight}
              onRowClick={onRowClick ? id => onRowClick(id, tabIndex) : undefined}
              numRows={numItems}
              tabName={tabName}
              extraColumnProps={extraColumnProps}
              collection={tabs[tabName].collection}
              defaultSortKey={defaultSortKey}
              columns={columns}
              transformData={transformData}
              sortBy={setSortKey as any}
              tab={tab}
              items={items}
            />
          ) : (
            <DesktopDataList
              ref={ref as RefObject<VariableSizeGrid>}
              active
              admins={admins}
              sortAsc={sortAsc}
              toggleSortDirection={() => setSortAsc(!sortAsc)}
              sortKey={sortKey}
              loading={loading}
              selectUser={selectItem}
              preview={preview}
              width={width}
              fullWidth={fullWidth}
              height={bodyHeight}
              extraColumnProps={extraColumnProps}
              numRows={numItems}
              tabName={tabName}
              onRowClick={onRowClick ? id => onRowClick(id, tabIndex) : undefined}
              collection={tabs[tabName].collection}
              defaultSortKey={defaultSortKey}
              columns={columns}
              transformData={transformData}
              sortBy={setSortKey as any}
              tab={tab}
              itemHeight={usedItemHeight}
              // filtered={users}
              items={items}
            />
          )}
          {preview ? null : (
            <DataListFooter
              canGoNext={canGoNext}
              canGoPrev={canGoPrev}
              onGoNext={goNext}
              onGoPrev={goPrev}
              mobileLayout={!gridView}
              numItems={numItems}
              isGrid={gridView}
              onHeightChange={setFooterHeight}
              pageNum={pageNum}
              totalPages={totalPages}
              goToPage={goToPage}
              items={items}
              loading={loading}
              queryCount={queryCount}
              gridRef={ref}
            />
          )}
        </Stack>
      </Flex>
      {creation && onCreate && newItemModalOpen ? (
        <GenericEditModal
          field={creation.field}
          isOpen={newItemModalOpen}
          data={initialNewItemData}
          onClose={() => setNewItemModalOpen(false)}
          submitText={creation?.submitText}
          onSubmit={d => onCreate(d, appData)}
        />
      ) : null}
    </DataListContext.Provider>
  )
}

export const StandaloneRow = <T extends WithId, ExtraProps extends AnyObject = AnyObject>({
  columns,
  width,
  extraProps,
  data,
}: {
  columns: DataColumns<T, ExtraProps>
  extraProps?: ExtraProps
  width: number
  data: T
}) => {
  const flexColumnWidth = useMemo(() => getFlexColumnWidth({ width, columns }), [columns, width])
  const { searchQuery } = useContext(SearchBarContext)
  const admins = useCollection(adminsCollection)
  const auth = useAuth()
  const app = useApp()
  return (
    <Flex width={width}>
      {Object.values(columns).map((c, i) => (
        <Flex width={c.width ? `${c.width}px` : flexColumnWidth} key={`col_${i}`}>
          {c.Render({
            data,
            app,
            searchQuery,
            admins,
            preview: {
              openPreview: () => {},
              closePreview: () => {},
              preview: null,
            },
            tabName: '',
            auth,
            isScrolling: false,
            cell: { rowIndex: 0, columnIndex: i },
            ...(extraProps as ExtraProps),
          })}
        </Flex>
      ))}
    </Flex>
  )
}
