import { isSubTabGroup, SubTab, Tabs } from './types'

export const getTab = (tabs: Tabs, path: string | null): SubTab | null => {
  if (!path) return null
  const pathParts = path.split('/')
  const tabName = pathParts[0]
  const tab = tabs.find(t => t.path === tabName)
  if (!tab) return null
  if (isSubTabGroup(tab)) return getTab(tab.children, pathParts.slice(1).join('/'))
  return tab
}
