import { CalendarIcon, DownloadIcon, EditIcon, RepeatIcon } from '@chakra-ui/icons'
import {
  Badge,
  BadgeProps,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  IconButton,
  Img,
  StackDivider,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import {
  adminRoles,
  backupDeliveryPlanField,
  colors,
  currentPregnancyField,
  deliveryPlanField,
  getDateTimeRangeString,
  getFullName,
  gravidaField,
  historyReviewField,
  isPreviousVisit,
  localizeDateRange,
  PracticeVisit,
  PracticeVisitDraft,
  PreviousVisit,
  riskAssessmentField,
  SubmittedAuthentication,
  SubmittedVisitData,
  visitHistoryField,
  visitTypeNames,
  WithId,
} from '@hb/shared'
import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import { addPracticeVisit, downloadFromStorage, requestVisitPdf } from '../../../../../backend'
import { useCachedUser } from '../../../../../collections/hooks/cached'
import { useApp } from '../../../../../contexts/AppContext'
import { PopUpMessageContext } from '../../../../../contexts/PopUpMessage/PopUpMessageContext'
import { usePracticeAccess } from '../../../../../contexts/PracticeAccess/PracticeAccessProvider'
import pregnancyIcon from '../../../../../icons/breastfeeding.svg'
import { useAuth } from '../../../../../store'
import { ItemViewProps } from '../../../../../types'
import { formatValue } from '../../../../../utils'
import { ActionConfirm } from '../../../../Alerts'
import { ModalCloseButton } from '../../../../Buttons/ModalCloseButton'
import { RelativeCopyId } from '../../../../CopyId'
import { DataCell } from '../../../../DataView'
import { SimpleForm } from '../../../../forms'
import { DataLabel } from '../../../../forms/Input/DataLabel'
import { StoragePathPdfViewModal } from '../../../../Modals'
import { AuthenticateAndSubmit } from '../AuthenticateCheckbox'
import { AuthenticationView } from '../AuthenticationView'
import { newVisitField } from '../fields'
import { RenderVisitNextAction } from '../visitsList'
import { SendVisitFaxPopover } from './SendVisitFaxPopover'

const DataBadge = (props: BadgeProps) => (
  <Badge
    bg={colors.green.hex}
    textShadow="1px 1px 3px #00000077"
    color="white"
    boxShadow="1px 1px 4px #00000055"
    {...props}
  />
)

const CurrentPregnancyView = ({ value }: { value: PracticeVisit['currentPregnancy'] }) => {
  const formattedValue = useMemo(
    () =>
      formatValue({
        field: currentPregnancyField,
        value,
      }),
    [value],
  )

  return (
    <Flex flexFlow="column" w="100%">
      <DataLabel>Current Pregnancy</DataLabel>
      <DataCell pt={0}>{formattedValue}</DataCell>
    </Flex>
  )
}

export const ParaView = ({ para }: { para: PracticeVisit['para'] }) => (
  <Flex gap={2} flexFlow="row wrap" align="center" py={1}>
    <DataLabel>Para:</DataLabel>
    {para.map((p, i) => (
      <DataBadge fontSize="sm" key={i}>
        {p}
      </DataBadge>
    ))}
  </Flex>
)
const HistoryReviewView = ({
  historyReview,
}: {
  historyReview: PracticeVisit['historyReview'] | undefined
}) => {
  const formattedHistoryReview = useMemo(
    () =>
      formatValue({
        field: historyReviewField,
        value: historyReview,
      }),
    [historyReview],
  )

  if (!historyReview) return null

  return (
    <Flex gap={1} align="center">
      <DataCell opacity={historyReview ? 1 : 0.7} py={0}>
        {historyReview ? formattedHistoryReview : 'None'}
      </DataCell>
    </Flex>
  )
}

const HistoryView = ({
  history,
  historyDetails,
  historyReview,
}: {
  history: PracticeVisit['history']
  historyDetails: string | undefined
  historyReview: PracticeVisit['historyReview'] | undefined
}) => {
  const options = useMemo(
    () => history.map(v => visitHistoryField.options.find(o => o.id === v)),
    [history],
  )

  return (
    <>
      <Flex flexFlow="column" w="100%" gap={1}>
        <DataLabel>History</DataLabel>
        <HistoryReviewView historyReview={historyReview} />
        <Flex flex={1} gap={2} minW="0" flexFlow="row wrap">
          {options.map((o, i) => (
            <DataBadge key={i}>{o?.text}</DataBadge>
          ))}
        </Flex>
      </Flex>
      {historyDetails ? (
        <Flex flexFlow="column" mt={2} gap={0}>
          <DataLabel>History Details</DataLabel>
          <DataCell py={0}>{historyDetails}</DataCell>
        </Flex>
      ) : null}
    </>
  )
}

const DeliveryPlanView = ({
  deliveryPlan,
  backupDeliveryPlan,
}: {
  deliveryPlan: PracticeVisit['deliveryPlan']
  backupDeliveryPlan?: PracticeVisit['backupDeliveryPlan']
}) => {
  const formattedDeliveryPlan = useMemo(
    () =>
      formatValue({
        field: deliveryPlanField,
        value: deliveryPlan,
      }),
    [deliveryPlan],
  )

  return (
    <>
      <Flex flexFlow="column">
        <DataLabel>Delivery Plan</DataLabel>
        <DataCell p={0}>{formattedDeliveryPlan}</DataCell>
      </Flex>
      <BackupDeliveryPlanView backupDeliveryPlan={backupDeliveryPlan} />
    </>
  )
}

const DeliveryAdditionalDetailsView = ({
  deliveryPlanDetails,
}: {
  deliveryPlanDetails: string | undefined
}) =>
  deliveryPlanDetails ? (
    <Flex flexFlow="column">
      <DataLabel>Delivery - Additional Details</DataLabel>
      <DataCell py={0}>{deliveryPlanDetails}</DataCell>
    </Flex>
  ) : null

const HospitalView = ({ hospital }: { hospital: string }) => (
  <Flex gap={2} align="center">
    <DataLabel>Hospital</DataLabel>
    <DataCell py={0}>{hospital}</DataCell>
  </Flex>
)

const KeywordsView = ({ keywords }: { keywords: string | undefined }) => (
  <Flex gap={2} pt={1} align="center">
    <DataLabel>Keywords</DataLabel>
    {keywords ? (
      keywords.split(',').map((k, i) => <DataBadge key={i}>{k}</DataBadge>)
    ) : (
      <DataCell py={0}>None</DataCell>
    )}
  </Flex>
)

const RiskAssessmentView = ({
  riskAssessment,
}: {
  riskAssessment: PracticeVisit['riskAssessment']
}) => {
  const formattedRiskAssessment = useMemo(
    () =>
      formatValue({
        field: riskAssessmentField,
        value: riskAssessment,
      }),
    [riskAssessment],
  )

  return (
    <Flex gap={2} align="center">
      <DataLabel>{riskAssessmentField.placeholder}</DataLabel>
      <DataCell pt={0}>{formattedRiskAssessment}</DataCell>
    </Flex>
  )
}

const GravidaView = ({ gravida }: { gravida: number }) => (
  <Flex gap={2} align="center" py={1}>
    <DataLabel>{gravidaField.placeholder}</DataLabel>
    <DataBadge fontSize="sm">{gravida}</DataBadge>
  </Flex>
)

const BackupDeliveryPlanView = ({
  backupDeliveryPlan,
}: {
  backupDeliveryPlan: PracticeVisit['backupDeliveryPlan']
}) => {
  const formatted = useMemo(
    () =>
      backupDeliveryPlan
        ? formatValue({
            field: backupDeliveryPlanField,
            value: backupDeliveryPlan,
          })
        : 'None',
    [backupDeliveryPlan],
  )
  return (
    <Flex
      align={backupDeliveryPlan ? 'flex-start' : 'center'}
      flexFlow={backupDeliveryPlan ? 'column' : 'row'}
      gap={backupDeliveryPlan ? 0 : 2}>
      <DataLabel>{backupDeliveryPlanField.placeholder}</DataLabel>
      <DataCell opacity={backupDeliveryPlan ? 1 : 0.6} py={0}>
        {formatted}
      </DataCell>
    </Flex>
  )
}

const AllergyNotesView = ({ allergyNotes }: { allergyNotes: string | undefined }) =>
  allergyNotes ? (
    <>
      <Divider />
      <Flex py={1} flexFlow="column" gap={0}>
        <DataLabel>Allergy Notes</DataLabel>
        <DataCell py={0}>{allergyNotes || ''}</DataCell>
      </Flex>
    </>
  ) : null
const NotesView = ({ notes }: { notes: string | undefined }) =>
  notes ? (
    <>
      <Divider />
      <Flex pt={1} flexFlow="column" gap={0}>
        <DataLabel>Notes</DataLabel>
        <DataCell pt={0}>{notes || ''}</DataCell>
      </Flex>
    </>
  ) : null

const VisitTimeLabel = ({ visit }: { visit: WithId<PracticeVisit> | PreviousVisit }) => {
  const { startTime, endTime } = visit
  const visitTimeLabel = useMemo(() => {
    const now = Date.now()
    if (startTime > now) return 'Future Visit'
    if (endTime < now) return 'Past Visit'
    return 'Current Visit'
  }, [startTime, endTime])
  return (
    <Badge ml={2} colorScheme="green">
      {visitTimeLabel}
    </Badge>
  )
}

const VisitPregnancyLabel = ({
  visit,
}: {
  visit: PracticeVisit | PreviousVisit | PracticeVisitDraft | SubmittedVisitData
}) => {
  const { assessmentName, assessmentId } = visit

  if (!assessmentId) return null
  return (
    <HStack w="100%" divider={<StackDivider />}>
      <Flex
        borderRadius="full"
        py={1}
        pl={2}
        pr={3}
        bg="green.500"
        color="white"
        gap={0.5}
        fontFamily="Hero-New"
        align="center">
        <Img filter="drop-shadow(1px 1px 3px #00000066)" w="22px" src={pregnancyIcon} />
        <Text textShadow="1px 1px 3px #00000077" fontWeight={500} lineHeight={1}>
          {assessmentName}
        </Text>
      </Flex>
    </HStack>
  )
}

const VisitViewBody = ({
  visit,
  isPreviousVersion,
  practiceId,
  onEditClick,
  inTab,
}: {
  visit: WithId<PracticeVisit> | PreviousVisit
  isPreviousVersion?: boolean
  practiceId: string
  onEditClick?: () => void
  inTab?: boolean
}) => {
  const {
    startTime,
    endTime,
    type,
    currentPregnancy,
    patientFirst,
    patientLast,
    history,
    deliveryPlan,
    historyReview,
    para,
    allergyNotes,
    notes,
    keywords,
    gravida,
    backupDeliveryPlan,
    deliveryPlanDetails,
    hospital,
    historyDetails,
    riskAssessment,
    signedPdfStoragePath,
  } = visit

  const [viewingFaxIndex, setViewingFaxIndex] = useState<number | null>(null)
  const viewingFax = useMemo(
    () => (viewingFaxIndex !== null ? visit.sentFaxes?.[viewingFaxIndex] || null : null),
    [visit.sentFaxes, viewingFaxIndex],
  )

  const viewedStoragePath = useMemo(() => {
    if (viewingFaxIndex === null) return null
    return viewingFax?.storagePath || visit.signedPdfStoragePath || null
  }, [viewingFaxIndex, viewingFax, visit.signedPdfStoragePath])
  const visitTypeName = useMemo(() => visitTypeNames[type], [type])
  const { processResponse } = useContext(PopUpMessageContext)
  const [downloading, setDownloading] = useState(false)
  const onDownload = useCallback(
    async (forceRegenerate?: boolean) => {
      const asCurrentVersion = visit as WithId<PracticeVisit>
      if (!asCurrentVersion.id || isPreviousVersion) {
        processResponse({
          error: 'Unable to download PDF for previous versions',
        })
        return
      }
      setDownloading(true)
      try {
        if (signedPdfStoragePath && !forceRegenerate)
          await downloadFromStorage(signedPdfStoragePath)
        else {
          const {
            data: { storagePath },
          } = await requestVisitPdf({
            practiceId,
            visitId: asCurrentVersion.id,
            forceRegenerate: !!forceRegenerate,
          })
          await downloadFromStorage(storagePath)
        }
      } catch (err: any) {
        processResponse({ error: err?.message || 'Failed to download PDF' })
        console.error(err)
      }
      setDownloading(false)
    },
    [signedPdfStoragePath, processResponse, visit, practiceId, isPreviousVersion],
  )

  return (
    <Flex position="relative" py={2} px={3} flexFlow="column">
      <Flex minH={8} pr={inTab ? 0 : 8} w="100%">
        <Text fontSize="lg" fontFamily="Open Sans" color="gray.600">
          {visitTypeName} with {patientFirst} {patientLast}
        </Text>
        <Box ml="auto">
          <Flex gap={1} position="relative" align="center" ml="auto">
            <Box>
              <VisitTimeLabel visit={visit} />
            </Box>
            {isPreviousVisit(visit) ? null : <RelativeCopyId item={visit} />}
            {onEditClick ? (
              <Button
                size="sm"
                borderRadius="full"
                variant="ghost"
                color={colors.green.hex}
                aria-label="Edit and Reauthenticate"
                onClick={onEditClick}>
                <EditIcon />
                <Text ml={1}>Edit and Reauth</Text>
              </Button>
            ) : null}
          </Flex>
        </Box>
      </Flex>
      <VisitPregnancyLabel visit={visit} />
      <Flex gap={2} align="center" py={1}>
        <CalendarIcon color="gray.500" />
        <Text position="relative" top="1px" lineHeight={1} fontSize="sm" fontFamily="Hero-New">
          {getDateTimeRangeString(startTime, endTime)}
        </Text>
      </Flex>
      <GravidaView gravida={gravida} />
      <ParaView para={para} />
      <CurrentPregnancyView value={currentPregnancy} />
      <Divider mb={2} />
      <HistoryView
        historyReview={historyReview}
        history={history}
        historyDetails={historyDetails}
      />
      <Divider my={2} />
      <DeliveryPlanView backupDeliveryPlan={backupDeliveryPlan} deliveryPlan={deliveryPlan} />
      <Divider my={1} />
      <HospitalView hospital={hospital} />
      <DeliveryAdditionalDetailsView deliveryPlanDetails={deliveryPlanDetails} />
      <RiskAssessmentView riskAssessment={riskAssessment} />
      <AllergyNotesView allergyNotes={allergyNotes} />
      <NotesView notes={notes} />
      <Divider />
      <KeywordsView keywords={keywords} />
      <Divider my={2} />
      <Flex align="center" w="100%" gap={2}>
        <Flex minW="0" flex={1}>
          <AuthenticationView authentication={visit.authentication} />
        </Flex>
        {isPreviousVersion ? null : (
          <Flex gap={2} align="center">
            <Tooltip bg="gray.50" color="gray.600" placement="top" hasArrow label="Download PDF">
              <IconButton
                onClick={() => onDownload()}
                isLoading={downloading}
                color="gray.600"
                aria-label="Download"
                icon={<DownloadIcon />}
                borderRadius="full"
                bg="white"
                boxShadow="1px 1px 4px #00000066"
              />
            </Tooltip>
            {visit.signedPdfStoragePath ? (
              <Tooltip
                bg="gray.50"
                color="gray.600"
                placement="top"
                hasArrow
                label="Regenerate PDF">
                <IconButton
                  onClick={() => onDownload(true)}
                  isLoading={downloading}
                  color="gray.600"
                  aria-label="Regenerate"
                  icon={<RepeatIcon />}
                  borderRadius="full"
                  size="sm"
                  bg="gray.50"
                  boxShadow="1px 1px 4px #00000066"
                />
              </Tooltip>
            ) : null}
            {isPreviousVersion ? null : (
              <SendVisitFaxPopover
                onViewFax={(faxIdx: number) => setViewingFaxIndex(faxIdx)}
                practiceId={practiceId}
                visit={visit as WithId<PracticeVisit>}
              />
            )}
          </Flex>
        )}
      </Flex>
      {viewedStoragePath ? (
        <StoragePathPdfViewModal
          isOpen
          onClose={() => setViewingFaxIndex(null)}
          header="Faxed Visit PDF"
          storagePath={viewedStoragePath}
        />
      ) : null}
    </Flex>
  )
}

const EditAndReauthenticateVisit = ({
  visit,
  practiceId,
  onClose,
}: {
  visit: WithId<PracticeVisit>
  onClose: () => void
  practiceId: string
}) => {
  const { appName } = useApp()
  // visit startTime(number) and endTime(number) should be replaced with startTime(string), endTime(string), and date(string)
  const [hasGoneBack, setHasGoneBack] = useState(false)
  const [formData, setFormData] = useState<Partial<SubmittedVisitData>>(() => {
    const {
      startTime,
      endTime,
      gravida,
      para,
      currentPregnancy,
      historyReview,
      history,
      historyDetails,
      deliveryPlan,
      backupDeliveryPlan,
      hospital,
      riskAssessment,
      allergyNotes,
      notes,
      keywords,
    } = visit

    const localized = localizeDateRange(startTime, endTime)
    return {
      ...localized,
      gravida,
      para,
      currentPregnancy,
      historyReview,
      history,
      historyDetails,
      deliveryPlan,
      backupDeliveryPlan,
      hospital,
      riskAssessment,
      allergyNotes,
      notes,
      keywords,
    }
  })
  const [savedData, setSavedData] = useState<PracticeVisitDraft | null>(null)
  const [authChecked, setAuthChecked] = useState(false)
  const { assessmentId, patientId } = visit
  const onSubmitForm = useCallback(
    async (data: PracticeVisitDraft) => {
      setSavedData({ ...data, assessmentId, patientId })
      return undefined
    },
    [assessmentId, patientId],
  )
  const [confirmDiscard, setConfirmDiscard] = useState(false)

  const onSubmit = useCallback(
    async (authentication: SubmittedAuthentication): Promise<void> => {
      if (!savedData) throw new Error('No saved data to submit')
      const visitId = visit.id
      if (!visitId) throw new Error('No visit id to submit')

      await addPracticeVisit({
        appName,
        authentication,
        visitId: visit.id,
        ...savedData,
        practiceId,
      })
      onClose()
    },
    [appName, savedData, visit, onClose, practiceId],
  )

  if (savedData) {
    return (
      <Box w="100%" p={3}>
        <VisitDraftView
          onEditClick={() => {
            setFormData(savedData)
            setSavedData(null)
            setHasGoneBack(true)
          }}
          draft={savedData}
        />
        <AuthenticateAndSubmit
          authChecked={authChecked}
          isReauth
          itemName="visit"
          onChange={setAuthChecked}
          onSubmit={onSubmit}
          practiceId={practiceId}
        />
        <ActionConfirm
          colorScheme="red"
          header="Discard edited visit?"
          body={null}
          confirmText="Discard and close"
          isOpen={confirmDiscard}
          onClose={() => setConfirmDiscard(false)}
          onConfirm={onClose}
        />
        <ModalCloseButton onClick={() => setConfirmDiscard(true)} />
      </Box>
    )
  }
  return (
    <Box w="100%">
      <SimpleForm
        value={formData}
        field={newVisitField}
        canSubmitClean={hasGoneBack}
        onCancel={() => onClose()}
        onSubmit={onSubmitForm}
      />
      <ModalCloseButton onClick={() => onClose()} />
    </Box>
  )
}

export const VisitView = ({
  visit,
  onClose,
}: {
  visit: WithId<PracticeVisit>
  onClose?: () => void
}) => {
  const { practiceId } = visit || {}
  const isAdmin = useAuth(s => s.admin)
  const { practiceAccess } = usePracticeAccess()
  const [isEditing, setIsEditing] = useState(false)

  const canEdit = useMemo(() => {
    if (isAdmin) return true
    if (!practiceId) return false
    const role = practiceAccess?.[practiceId]
    return !!role && adminRoles.includes(role)
  }, [isAdmin, practiceAccess, practiceId])

  const numPreviousVersions = useMemo(
    () => visit.previousVersions?.length || 0,
    [visit.previousVersions],
  )

  if (isEditing) {
    return (
      <EditAndReauthenticateVisit
        onClose={() => setIsEditing(false)}
        visit={visit}
        practiceId={practiceId}
      />
    )
  }

  if (numPreviousVersions) {
    return (
      <Tabs colorScheme="green" w="100%">
        <TabList bg="white">
          <Tab py={3} key={0}>
            <Text>Current Version</Text>
          </Tab>
          {visit.previousVersions.map((v, i) => (
            <Tab key={i + 1}>
              <Text>Version {numPreviousVersions - i}</Text>
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          <TabPanel p={0} key={0}>
            <VisitViewBody
              inTab
              onEditClick={canEdit ? () => setIsEditing(true) : undefined}
              visit={visit}
              practiceId={practiceId}
            />
          </TabPanel>
          {visit.previousVersions.map((v, i) => (
            <TabPanel p={0} key={i + 1}>
              <VisitViewBody inTab isPreviousVersion key={i} practiceId={practiceId} visit={v} />
            </TabPanel>
          ))}
        </TabPanels>
        {onClose ? <ModalCloseButton onClick={() => onClose()} /> : null}
      </Tabs>
    )
  }

  return (
    <Box position="relative" p={2} w="100%">
      <VisitViewBody
        practiceId={practiceId}
        onEditClick={canEdit ? () => setIsEditing(true) : undefined}
        visit={visit}
      />
      {onClose ? <ModalCloseButton onClick={() => onClose()} /> : null}
    </Box>
  )
}

export const VisitDraftView = ({
  draft,
  onEditClick,
}: {
  draft: PracticeVisitDraft | SubmittedVisitData
  onEditClick?: () => void
}) => {
  const {
    currentPregnancy,
    date,
    startTime,
    endTime,
    deliveryPlan,
    deliveryPlanDetails,
    backupDeliveryPlan,
    gravida,
    historyReview,
    history,
    historyDetails,
    hospital,
    patientId,
    para,
    riskAssessment,
  } = draft

  const { data: user } = useCachedUser(patientId)

  const fullName = useMemo(() => getFullName(user), [user])

  const visitTimeLabel = useMemo(() => {
    const visitStart = new Date(`${date}T${startTime}`).getTime()
    const visitEnd = new Date(`${date}T${endTime}`).getTime()

    const now = Date.now()
    if (visitStart > now) return 'Future Visit'
    if (visitEnd < now) return 'Past Visit'
    return 'Current Visit'
  }, [startTime, endTime, date])

  return (
    <Flex w="100%" px={2} flexFlow="column">
      <Flex pr={8} w="100%" align="center">
        <Text fontSize="lg" fontFamily="Open Sans" color="gray.600">
          Visit Draft for {fullName}
        </Text>
        <Badge ml={2} colorScheme="green">
          {visitTimeLabel}
        </Badge>
        {onEditClick ? (
          <Button onClick={onEditClick} ml="auto" size="sm" colorScheme="green" variant="ghost">
            <EditIcon />
            <Text ml={1}>Edit Draft</Text>
          </Button>
        ) : null}
      </Flex>
      <VisitPregnancyLabel visit={draft} />
      <Flex gap={2} align="center" py={1}>
        <CalendarIcon color="gray.500" />
        <Text position="relative" top="1px" lineHeight={1} fontSize="sm" fontFamily="Hero-New">
          {getDateTimeRangeString(new Date(`${date}T${startTime}`), new Date(`${date}T${endTime}`))}
        </Text>
      </Flex>
      <Flex gap={2} align="center" py={1}>
        <DataLabel>Gravida:</DataLabel>
        <DataBadge fontSize="sm">{gravida}</DataBadge>
      </Flex>
      <ParaView para={para} />
      <CurrentPregnancyView value={currentPregnancy} />
      <Divider mb={2} />
      <HistoryView
        historyReview={historyReview}
        history={history}
        historyDetails={historyDetails}
      />
      <Divider my={2} />
      <DeliveryPlanView backupDeliveryPlan={backupDeliveryPlan} deliveryPlan={deliveryPlan} />
      <Divider my={1} />
      <HospitalView hospital={hospital} />
      <DeliveryAdditionalDetailsView deliveryPlanDetails={deliveryPlanDetails} />
      <RiskAssessmentView riskAssessment={riskAssessment} />
      <AllergyNotesView allergyNotes={draft.allergyNotes} />
      <NotesView notes={draft.notes} />
      <Divider />
      <KeywordsView keywords={draft.keywords} />
    </Flex>
  )
}

type VisitPreviewType = FC<ItemViewProps<PracticeVisit>>
export const VisitPreview: VisitPreviewType = props => {
  const { data, index } = props
  const { items } = data
  const visit = items[index]
  const { startTime, endTime, type, patientFirst, patientLast } = visit

  const visitTypeName = visitTypeNames[type]
  return (
    <Flex w="100%" px={2} flexFlow="column">
      <Flex w="100%" align="center">
        <Text fontSize="lg" fontFamily="Open Sans" color="gray.600">
          {visitTypeName} with {patientFirst} {patientLast}
        </Text>
      </Flex>
      <Flex gap={2} align="center">
        <CalendarIcon color="gray.500" />
        <Text position="relative" top="1px" lineHeight={1} fontSize="sm" fontFamily="Hero-New">
          {getDateTimeRangeString(startTime, endTime)}
        </Text>
        <Box ml="auto">
          <VisitTimeLabel visit={visit} />
        </Box>
      </Flex>
      <Box pt={2}>
        <RenderVisitNextAction {...data} data={visit} />
      </Box>
    </Flex>
  )
}
