import { ChevronRightIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Divider,
  Flex,
  Img,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Assessment, colors, getAssessmentName, PracticeVisit } from '@hb/shared'
import React, { useMemo } from 'react'
import { ProfileEntity, useProfile } from '../../../../../../contexts/ProfileContext'
import pregnancyIcon from '../../../../../../icons/breastfeeding.svg'
import patientIcon from '../../../../../../icons/person_fill.svg'
import { isSubTabGroup, SubTab } from '../../../../../shared/Nav/types'

type PathPart = {
  header?: string
  name: string
  path: string
  entity?: ProfileEntity
  icon?: {
    src: string
    alt: string
    filter: string
    invertFilter: string
  }
  children?: PathPart[]
}

const Separator = () => (
  <ChevronRightIcon w={6} h={6} color="white" filter="drop-shadow(1px 1px 3px #00000077)" />
)

const LeafPathButton = ({ part, depth = 0 }: { part: PathPart; depth?: number }) => {
  const { icon, name, header, entity } = part
  const { onTabSelect } = useProfile()
  return (
    <Button
      variant="link"
      colorScheme="whiteAlpha"
      onClick={() => onTabSelect(part.path, entity || null)}
      fontFamily="Hero-New"
      fontWeight={500}
      w="100%"
      justifyContent="flex-start"
      textShadow={depth ? 'none' : '1px 1px 3px #00000077'}
      color={depth ? 'gray.600' : 'white'}>
      <Flex gap={1} align="center">
        {icon ? (
          <Img
            src={icon.src}
            alt={icon.alt}
            h="24px"
            filter={depth ? icon.invertFilter : icon.filter}
          />
        ) : null}
        <Flex align="flex-start" flexFlow="column">
          {header ? (
            <Text whiteSpace="nowrap" lineHeight={1.2} fontSize="xs">
              {header.toUpperCase()}
            </Text>
          ) : null}
          <Text whiteSpace="nowrap" maxW="300px" isTruncated lineHeight={1}>
            {name}
          </Text>
        </Flex>
      </Flex>
    </Button>
  )
}

const GroupPathButton = ({ part, depth = 0 }: { part: PathPart; depth?: number }) => {
  const { children, path } = part
  return (
    <Box w={depth ? '100%' : 'auto'} position="relative">
      <Popover
        isLazy
        placement={depth % 2 === 0 ? 'bottom-start' : 'right-start'}
        strategy="fixed"
        trigger="hover">
        <PopoverTrigger>
          <Box>
            <LeafPathButton depth={depth} part={part} />
          </Box>
        </PopoverTrigger>
        <PopoverContent w="auto">
          <PopoverBody maxH="360px" overflowY="auto" p={0}>
            <VStack spacing={0} divider={<Divider />} align="flex-start" flexFlow="column">
              {children?.map((child, i) => (
                <Box key={`${path}-child-${i}`} w="100%" py={1} px={2}>
                  <PathButton depth={depth + 1} key={i} part={child} />
                </Box>
              ))}
            </VStack>
          </PopoverBody>
          {depth ? <PopoverArrow /> : null}
        </PopoverContent>
      </Popover>
    </Box>
  )
}

const PathButton = ({ part, depth = 0 }: { part: PathPart; depth?: number }) => {
  return part.children ? (
    <GroupPathButton depth={depth} part={part} />
  ) : (
    <LeafPathButton depth={depth} part={part} />
  )
}

const getAssessmentPathPart = (
  assessmentId: string,
  assessment: Assessment | null,
  assessmentTabs: SubTab[],
): PathPart => {
  return {
    name: getAssessmentName(assessment),
    header: 'Pregnancy',
    path: 'pregnancy',
    entity: { type: 'pregnancy', id: assessmentId },
    children: assessmentTabs.map(t => ({
      type: 'assessment',
      id: assessmentId,
      name: t.name,
      path: `pregnancy/${t.path}`,
      entity: { type: 'pregnancy', id: assessmentId },
    })),
    icon: {
      src: pregnancyIcon,
      alt: 'Pregnancy',
      filter: 'brightness(150%) drop-shadow(1px 1px 3px #00000077)',
      invertFilter: 'brightness(50%)',
    },
  }
}

const getPatientPathPart = (
  reverseName: string,
  assessmentTabs: SubTab[],
  assessments: Record<string, Assessment> | null,
  officeVisits: Record<string, PracticeVisit> | null,
): PathPart => {
  return {
    name: reverseName,
    header: 'Patient',
    path: 'patient',
    icon: {
      src: patientIcon,
      alt: 'Patient',
      filter: 'brightness(300%) drop-shadow(1px 1px 3px #00000077)',
      invertFilter: 'brightness(50%)',
    },
    children: [
      ...Object.entries(assessments || {}).map(([id, a]) =>
        getAssessmentPathPart(id, a, assessmentTabs),
      ),
      {
        name: `Office Visits (${Object.keys(officeVisits || {}).length})`,
        path: 'visits',
      },
    ],
  }
}

export const BodyHeader = () => {
  const {
    tabs,
    user,
    tabName,
    subTab,
    selectedAssessment,
    assessmentId,
    assessments,
    officeVisits: { data: officeVisits },
  } = useProfile()
  const { reverseName } = user || {}

  const pathParts = useMemo(() => {
    const assessmentTab = tabs.find(t => t.path === 'pregnancy')
    const assessmentChildren =
      assessmentTab && isSubTabGroup(assessmentTab) ? assessmentTab.children || [] : []
    const parts: PathPart[] = [
      getPatientPathPart(
        reverseName || 'No name entered',
        assessmentChildren,
        assessments,
        officeVisits,
      ),
    ]
    if (tabName === 'patient' && !subTab) return parts
    const tab = tabs.find(t => t.path === tabName)

    switch (tabName) {
      case 'patient':
        break
      default:
        if (assessmentId)
          parts.push(
            getAssessmentPathPart(
              assessmentId,
              selectedAssessment.assessmentData,
              assessmentChildren,
            ),
          )
        break
    }
    if (!tab) return parts
    // parts.push({ name: tab.name, path: tab.path })
    if (isSubTabGroup(tab) && subTab) {
      const subTabGroup = tab.children?.find(t => t.path === subTab)
      if (subTabGroup) {
        parts.push({ name: subTabGroup.name, path: subTabGroup.path })
      }
    }
    return parts
  }, [
    subTab,
    tabName,
    tabs,
    selectedAssessment,
    reverseName,
    assessmentId,
    assessments,
    officeVisits,
  ])

  return (
    <Flex align="center" bg={colors.pink.hex} w="100%" p={2}>
      {pathParts.map((part, i) => (
        <Flex key={part.path} alignItems="center">
          {i ? <Separator /> : null}
          <PathButton part={part} />
        </Flex>
      ))}
    </Flex>
  )
}
