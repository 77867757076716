import { ArrowForwardIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react'
import { colors } from '@hb/shared'
import React, { useMemo } from 'react'
import { isSubTabGroup, SubTab, SubTabGroup } from './types'

const SubTabSelect = ({ tab, onSelect }: { tab: SubTab; onSelect: () => void }) => {
  return (
    <Box bg="white">
      <Button px={2.5} py={1.5} variant="link" onClick={onSelect}>
        {tab.name}
      </Button>
    </Box>
  )
}

const SubTabGroupSelect = ({
  group,
  onSelect,
}: {
  group: SubTabGroup
  onSelect: (subTab?: string) => void
}) => {
  return (
    <Flex bg="gray.100" w="100%" direction="column">
      <SubTabSelect tab={group} onSelect={() => onSelect()} />
      <Flex borderTop="1px solid #dedede" pl={2} flexFlow="column">
        {group.children.map((t, i) => (
          <SubTabSelect key={i} tab={t} onSelect={() => onSelect(t.path)} />
        ))}
      </Flex>
    </Flex>
  )
}

export const MenuTabList = ({
  tabs,
  onSelect,
  path,
}: {
  tabs: Array<SubTab | SubTabGroup>
  path?: string | null
  onSelect: (path: string) => void
}) => {
  // const field = useMemo<DropdownField>(() => {
  //   return {
  //     type: FieldTypes.DROPDOWN,
  //     options: tabs.map((t, i) => ({ id: `${i}`, text: t })),
  //     placeholder: 'Select a tab',
  //   }
  // }, [tabs])
  const curr = useMemo<{ tab: SubTab | SubTabGroup | null; subTab: SubTab | null }>(() => {
    if (!path) return { tab: tabs[0] || null, subTab: null }
    const pathParts = path.split('/')
    const tabName = pathParts[0]
    const tab = tabs.find(t => (t as SubTab).path === tabName) || tabs[0]
    const subTabName = pathParts[1]
    const subTab =
      subTabName && isSubTabGroup(tab)
        ? tab.children?.find(t => t.path === subTabName) || null
        : null
    return { tab, subTab }
  }, [path, tabs])

  return (
    <Box py={1} px={2} position="relative" w="100%" bg={`${colors.green.hex}44`}>
      <Menu gutter={7} matchWidth>
        {({ onClose }) => (
          <>
            <MenuButton px={3} size="sm" as={Button}>
              <Flex gap={2} align="center">
                <Text fontSize="sm">{curr.tab?.name.toUpperCase() || ''}</Text>
                {curr.tab && isSubTabGroup(curr.tab) ? (
                  <>
                    <ArrowForwardIcon />
                    <Text fontSize="xs">
                      {(curr.subTab || curr.tab.children[0]).name.toUpperCase()}
                    </Text>
                  </>
                ) : null}
              </Flex>
            </MenuButton>
            <MenuList p={0}>
              <VStack align="flex-start" spacing={0} divider={<Divider />}>
                {tabs.map((t, i) =>
                  isSubTabGroup(t) ? (
                    <SubTabGroupSelect
                      key={i}
                      group={t}
                      onSelect={subPath => {
                        onSelect(`${t.path}${subPath ? `/${subPath}` : ''}`)
                        onClose()
                      }}
                    />
                  ) : (
                    <SubTabSelect
                      key={i}
                      tab={t as SubTab}
                      onSelect={() => {
                        onSelect((t as SubTab).path)
                        onClose()
                      }}
                    />
                  ),
                )}
              </VStack>
            </MenuList>
          </>
        )}
      </Menu>
    </Box>
  )
}
