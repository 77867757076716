import useObserver from '@react-hook/resize-observer'
import { RefObject, useCallback, useEffect, useState } from 'react'

const getSizeFromEntry = (entry: ResizeObserverEntry, type: SizeType) => {
  switch (type) {
    case 'content':
      return {
        width: Math.floor(entry.contentRect.width),
        height: Math.floor(entry.contentRect.height),
      }
    case 'border':
      return {
        width: Math.floor(entry.borderBoxSize[0].inlineSize),
        height: Math.floor(entry.borderBoxSize[0].blockSize),
      }
    default:
      return {
        width: Math.floor(entry.contentBoxSize[0].inlineSize),
        height: Math.floor(entry.contentBoxSize[0].blockSize),
      }
  }
}

export type SizeType = 'content' | 'border' | 'scroll'
export type Size = { width: number; height: number }

export const useResizeObserver = (ref: RefObject<HTMLElement>, sizeType: SizeType): Size => {
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  })

  const handleResize = useCallback(
    (e: ResizeObserverEntry) => {
      if (ref.current) setSize(getSizeFromEntry(e, sizeType))
    },
    [ref, sizeType],
  )
  useEffect(() => {
    if (ref.current) {
      const { width, height } = ref.current.getBoundingClientRect()
      setSize({ width, height })
    }
  }, [ref])
  // Where the magic happens
  useObserver(ref, handleResize)
  return size
}
