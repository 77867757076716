import { WarningTwoIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  Portal,
  Text,
  VStack,
} from '@chakra-ui/react'
import { UserGroup } from '@hb/shared'
import React, { useCallback, useContext, useState } from 'react'
import { updateAssessmentMiscarriedStatus } from '../../backend/functions'
import { PopUpMessageContext } from '../../contexts/PopUpMessage/PopUpMessageContext'
import { ProfileContext } from '../../contexts/ProfileContext'
import { ScreenContext } from '../../contexts/ScreenContext'
import { AddAlertModal } from './AddAlertModal'

const AddAlertButton = ({ onClick }: { onClick?: () => void }) => (
  <Button
    // variant="unstyled"
    variant="outline"
    size="xs"
    onClick={onClick}
    bg="white"
    colorScheme="red"
    fontSize="xs">
    + ADD ALERT
  </Button>
)

export const AddAlertPopover = ({
  accessLevel,
  type,
  inList,
  placement = 'bottom',
}: {
  accessLevel?: UserGroup
  type?: 'assessment' | 'patient'
  inList?: boolean
  placement?: PopoverProps['placement']
}) => {
  const [addingAlert, setAddingAlert] = useState(false)
  const { isMobile } = useContext(ScreenContext)
  const {
    selectedAssessment: { populated: selectedAssessment },
    assessmentId,
  } = useContext(ProfileContext)
  const { miscarried } = selectedAssessment || {}
  const [reportingMiscarried, setReportingMiscarried] = useState(false)
  const { processResponse } = useContext(PopUpMessageContext)
  const handleReportMiscarried = useCallback(async () => {
    if (!assessmentId) {
      processResponse({ error: 'No pregnancy ID' })
      return
    }
    setReportingMiscarried(true)
    try {
      await updateAssessmentMiscarriedStatus({
        assessmentId,
        miscarried: true,
      })
    } catch (e: any) {
      processResponse({
        error: e?.message || 'Error updating miscarried status',
      })
    }
    setReportingMiscarried(false)
  }, [assessmentId, processResponse])

  const body =
    assessmentId && type !== 'patient' && !miscarried ? (
      <Popover trigger="hover" closeOnBlur={false} strategy="fixed" isLazy placement={placement}>
        <PopoverTrigger>
          <Box>
            {inList ? (
              <AddAlertButton />
            ) : (
              <Button
                aria-label="Add Alert"
                variant="ghost"
                gap={1}
                color="blackAlpha.600"
                size="sm"
                _hover={{
                  bg: 'blackAlpha.200',
                }}>
                <WarningTwoIcon w={4} h={4} />
                {isMobile ? <Text>No alerts</Text> : null}
              </Button>
            )}
          </Box>
        </PopoverTrigger>
        <Portal>
          <PopoverContent w="220px" p={0} boxShadow="1px 1px 3px #00000077">
            <PopoverArrow bg={placement === 'bottom' ? 'red.500' : 'gray.50'} />
            <PopoverBody borderRadius={6} overflow="hidden" bg="gray.50">
              <VStack spacing={1}>
                <Button
                  w="100%"
                  onClick={() => setAddingAlert(true)}
                  color="red.600"
                  bg="white"
                  size="sm"
                  borderColor="red.600"
                  borderWidth="1px">
                  ADD
                  {type ? ` ${type === 'assessment' ? 'PREGNANCY' : 'PATIENT'}` : ''} ALERT
                </Button>
                <Button
                  w="100%"
                  onClick={handleReportMiscarried}
                  isLoading={reportingMiscarried}
                  bg="red.600"
                  size="sm"
                  color="white">
                  REPORT MISCARRIED
                </Button>
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    ) : (
      <AddAlertButton onClick={() => setAddingAlert(true)} />
    )

  return (
    <Box>
      {body}
      {addingAlert ? (
        <AddAlertModal
          type={type}
          accessLevel={accessLevel}
          onClose={() => setAddingAlert(false)}
        />
      ) : null}
    </Box>
  )
}
