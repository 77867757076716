import { PracticeInvoiceOmission } from '../invoicing/types'
import {
  AdminAssessmentData,
  AssessmentPaymentsData,
  Claim,
  Payment,
  PaymentDue,
  PaymentReceived,
  PaymentRecord,
  PaymentStatus,
  PaymentType,
  PopulatedAssessment,
  PopulatedPayment,
  PracticeAssessmentData,
  SortedPayments,
  WithId,
} from '../types'
import { capitalizeFirstLetter, formatDollarValue, objectToArray, sortByDateKey } from './data'
import { addNDaysTo, getDateString } from './dates'

export const populatePaymentsFromSorted = (
  sortedDue: SortedPayments<PaymentDue>,
  sortedReceived: SortedPayments<PaymentReceived>,
) =>
  Object.entries(sortedDue).reduce((acc, [paymentType, paymentTypeData]) => {
    let remaining = sortedReceived[paymentType as PaymentType].total
    const populatedPayments = Object.entries(paymentTypeData.payments).reduce(
      (withAmountPaid, [paymentId, payment]) => {
        let amountPaid = Math.max(0, remaining)
        remaining -= payment.amount || 0
        if (remaining > 0) amountPaid = payment.amount || 0
        Object.assign(withAmountPaid, {
          [paymentId]: {
            ...payment,
            amountPaid,
            status: getPaymentStatus(payment, amountPaid),
          },
        })
        return withAmountPaid
      },
      {} as Record<string, PopulatedPayment>,
    )
    acc[paymentType as PaymentType] = {
      ...paymentTypeData,
      payments: populatedPayments,
    }
    return acc
  }, {} as SortedPayments<PopulatedPayment>)

export const populatePayments = (data?: AssessmentPaymentsData) => {
  const { due, received } = data || {}
  const sortedDue = sortPaymentTypes(due)
  const sortedReceived = sortPaymentTypes(received)
  return populatePaymentsFromSorted(sortedDue, sortedReceived)
}

const paymentTypeSortOrder: Array<PaymentType> = [
  'retainer',
  'deposit',
  'home-birth-retainer',
  'other',
]

const sortByDueDate = (
  populated: PaymentRecord<PopulatedPayment>,
): PaymentRecord<PopulatedPayment> =>
  Object.entries(populated)
    .sort(([, a], [, b]) => {
      if (a.dueDate === b.dueDate) {
        return paymentTypeSortOrder.indexOf(a.type) - paymentTypeSortOrder.indexOf(b.type)
      }
      return (a.dueDate || 0) - (b.dueDate || 0)
    })
    .reduce(
      (acc, [paymentId, payment]) => ({ ...acc, [paymentId]: payment }),
      {} as PaymentRecord<PopulatedPayment>,
    )

export const sortPopulatedPaymentsByDate = (
  populated: SortedPayments<PopulatedPayment>,
): PaymentRecord<PopulatedPayment> => {
  const all = Object.values(populated).reduce(
    (acc, { payments: populatedPayments }) => ({
      ...acc,
      ...populatedPayments,
    }),
    {} as PaymentRecord<PopulatedPayment>,
  )

  return sortByDueDate(all)
}

export const getSortedPopulatedPayments = (data?: AssessmentPaymentsData) =>
  sortPopulatedPaymentsByDate(populatePayments(data))

export const getPaymentReminderString = (payments: PaymentRecord<PopulatedPayment>) =>
  Object.values(payments)
    .map(
      ({ amount, amountPaid, dueDate, type }) =>
        `${capitalizeFirstLetter(type)} | Amount Due: ${formatDollarValue(
          amount,
        )} | Paid: ${formatDollarValue(amountPaid)} | Due on: ${getDateString(dueDate!, 'short')}`,
    )
    .join('\n')

export const isOverdue = (payment: PopulatedPayment) => {
  if (!payment.amount || !payment.dueDate) return false
  if (payment.amountPaid >= payment.amount) return false
  if (Date.now() > payment.dueDate) return true
  return false
}

export const getHasOverduePayments = (data?: AssessmentPaymentsData) => {
  if (!data) return false
  const populated = populatePayments(data)
  const types = Object.values(populated)

  for (let i = 0; i < types.length; i += 1) {
    const typePayments = Object.values(types[i].payments)
    for (let j = 0; j < typePayments.length; j += 1) {
      if (isOverdue(typePayments[j])) return true
    }
  }
  return false
}

const needsUpcomingPaymentReminder = (payment: PopulatedPayment) => {
  if (payment?.remindersSent?.length || !payment.dueDate || payment.status !== 'unpaid') {
    return false
  }
  const now = Date.now()
  const reminderDate = addNDaysTo(payment.dueDate, -10)
  if (payment.dueDate < now) return false
  if (now < reminderDate) return false
  return true
}
export const getPendingPaymentReminders = (data?: AssessmentPaymentsData) =>
  objectToArray(getSortedPopulatedPayments(data), 'createdOn')?.reduce((acc, curr) => {
    // filter out first reminder
    if (curr.type === 'retainer') {
      return acc
    }
    if (needsUpcomingPaymentReminder(curr)) {
      return { ...acc, [curr.createdOn]: curr }
    }
    return acc
  }, {} as PaymentRecord<PopulatedPayment>)

export const sortPaymentTypes = <T extends Payment = Payment>(
  payments?: PaymentRecord<T>,
): SortedPayments<T> => {
  const res: SortedPayments<T> = {
    retainer: { payments: {}, total: 0 },
    'home-birth-retainer': { payments: {}, total: 0 },
    deposit: { payments: {}, total: 0 },
    other: { payments: {}, total: 0 },
  }
  const sorted = sortByDateKey(payments || {})
  const updatePaymentType = (payment: WithId<T>) => {
    const { type, id: paymentId } = payment
    if (!res[type]) return
    res[type].payments[parseInt(paymentId, 10)] = payment
    res[type].total += payment.amount || 0
  }
  if (payments) sorted.forEach(updatePaymentType)

  return res
}

export const getPaymentStatus = (payment: PaymentDue, amountPaid: number): PaymentStatus => {
  if (!payment.amount) return 'no-amount'
  if (amountPaid >= payment.amount || Math.abs(amountPaid - payment.amount) < 0.005) return 'paid'
  return 'unpaid'
}
export const formatPaymentType = (type: PaymentType) => {
  switch (type) {
    case 'home-birth-retainer':
      return 'Birth Assistant Retainer'
    case 'retainer':
      return 'retainer'
    case 'deposit':
      return 'deposit'
    default:
      return 'other'
  }
}

export const getTotalFromPatient = (
  assessment?: PracticeAssessmentData | PopulatedAssessment | AdminAssessmentData | null,
) => {
  const { payments } = assessment || {}
  if (!payments) return undefined
  const { due, received } = payments
  if (!due && !received) return undefined
  const receivedTotal = Object.values(received || {})
    .filter(v => v.paidBy === 'patient')
    .reduce((acc, p) => acc + (p.amount || 0), 0)

  return receivedTotal
}

export const getTotalFromInsurer = <T extends { payments?: AssessmentPaymentsData }>(
  assessment?: T | null,
) => {
  const { payments } = assessment || {}
  if (!payments) return undefined
  const { due, received } = payments
  if (!due && !received) return undefined
  const receivedTotal = Object.values(received || {})
    .filter(v => v.paidBy === 'insurance')
    .reduce((acc, p) => acc + (p.amount || 0), 0)

  return receivedTotal
}

export const getTotalReceived = (assessment?: AdminAssessmentData | PopulatedAssessment) => {
  const { payments } = assessment || {}
  if (!payments) return undefined
  const { received } = payments
  if (!received) return undefined
  const receivedTotal = Object.values(received || {}).reduce((acc, p) => acc + (p.amount || 0), 0)

  return receivedTotal
}

export const getTotalDue = (assessment?: AdminAssessmentData | PopulatedAssessment) => {
  const { payments } = assessment || {}
  if (!payments) return undefined
  const { due } = payments
  if (!due) return undefined
  const receivedTotal = Object.values(due || {}).reduce((acc, p) => acc + (p.amount || 0), 0)

  return receivedTotal
}

export const getTotalDepositsDue = (assessment?: AdminAssessmentData | PopulatedAssessment) => {
  const { payments } = assessment || {}
  if (!payments) return undefined
  const { due } = payments
  if (!due) return undefined
  const receivedTotal = Object.values(due || {})
    .filter(v => v.type === 'deposit')
    .reduce((acc, p) => acc + (p.amount || 0), 0)

  return receivedTotal
}

export const getTotalDepositsReceived = (
  assessment?: AdminAssessmentData | PopulatedAssessment,
) => {
  const { payments } = assessment || {}
  if (!payments) return undefined
  const { received } = payments
  if (!received) return undefined
  const receivedTotal = Object.values(received || {})
    .filter(v => v.type === 'deposit')
    .reduce((acc, p) => acc + (p.amount || 0), 0)

  return receivedTotal
}

const invalidClaimStatuses = ['DELETED', 'ERROR', 'DENIED']
export const getFee = (
  assessment?: AdminAssessmentData | PopulatedAssessment,
  claims?: Record<string, Claim>,
) => {
  const claimKeys = Object.keys(claims || {})
  if (claimKeys.length > 0) {
    return Object.values(claims || {})
      .filter(c => !invalidClaimStatuses.includes(c.status) && !c.nullified)
      .reduce((acc, { abilitySnippet }) => {
        const charge = parseFloat(abilitySnippet?.ClaimCharge || '0')
        return acc + charge
      }, 0)
  }
  return assessment?.payments?.prm || 0
}

export const getBalanceTowardsFee = (
  assessment?: AdminAssessmentData | PopulatedAssessment,
  claims?: Record<string, Claim>,
) => {
  const { financialAdj } = assessment || {}
  const totalReceived = getTotalReceived(assessment)
  const fee = getFee(assessment, claims)
  return Math.max(0, fee - (totalReceived || 0) + Math.min(financialAdj || 0, 0))
}

export const getBalanceTowardsPRM = (assessment?: AdminAssessmentData | PopulatedAssessment) => {
  const totalReceived = getTotalReceived(assessment)
  const totalDeposits = getTotalDepositsReceived(assessment) || 0
  const initVal = (assessment?.payments?.prm || 0) - (totalReceived || 0)
  if (initVal >= 0) return initVal
  if (initVal < -totalDeposits) return -totalDeposits || 0
  return initVal
}

export const separatePayments = <T extends Payment>(
  payments: PaymentRecord<T>,
  assessmentId?: string,
  omissions?: Record<string, PracticeInvoiceOmission>,
) =>
  Object.entries(payments).reduce(
    (acc, [paymentId, payment]) => {
      if (omissions?.[`${assessmentId}-${paymentId}`]) return acc
      if (payment.type === 'deposit') {
        acc.deposits[paymentId] = payment
      } else if (payment.type === 'retainer') {
        acc.retainers[paymentId] = payment
      } else if (payment.type === 'home-birth-retainer') {
        acc.homeBirthRetainers[paymentId] = payment
      } else {
        acc.other[paymentId] = payment
      }
      return acc
    },
    {
      deposits: {} as Record<string, T>,
      retainers: {} as Record<string, T>,
      homeBirthRetainers: {} as Record<string, T>,
      other: {} as Record<string, T>,
    },
  )
