import React from 'react'
import { useProfile } from '../../../../contexts'
import { PatientConsentForms } from './PatientConsentForms'

export const ProfileConsentForms = () => {
  const { user } = useProfile()
  const { id } = user || {}
  if (!id) return null
  return <PatientConsentForms patientId={id} adminView />
}
