import { Badge, Flex, Text } from '@chakra-ui/react'
import {
  DropdownField,
  DropdownOptionItem,
  getInsurancePlansField,
  InsuranceCoverageId,
  InsurancePlans,
  InsuranceProvider,
  providersCollection,
  WithId,
} from '@hb/shared'
import { get as nestedGet } from 'nested-property'
import React, { useMemo } from 'react'
import { useCollection } from '../../../../collections/hooks/useCollection'

const RenderPlanOption = ({
  option,
  insurancePlans,
  allInsuranceProviders,
}: {
  option: DropdownOptionItem
  insurancePlans: Partial<InsurancePlans>
  allInsuranceProviders: WithId<InsuranceProvider>[]
}) => {
  const plan = nestedGet(insurancePlans, option.id as InsuranceCoverageId)
  const provider = allInsuranceProviders.find(p => p.id === plan?.insuranceProviderId)
  let badge: string | null = null
  if (option.id === 'primaryCoverage') {
    badge = 'Primary'
  } else if (option.id === 'medicaidCoverage') {
    badge = 'Medicaid'
  } else if (plan?.label === 'secondary') {
    badge = 'Secondary'
  } else {
    badge = 'Potential'
  }
  return (
    <Flex w="100%" gap={2} pr={2} align="center">
      {provider && (
        <Text fontWeight={600} color="gray.600">
          {provider.name}
        </Text>
      )}
      <Text lineHeight={1} color="gray.500" fontSize="sm">
        {plan?.memberId || 'Missing member ID'}
      </Text>
      {badge ? (
        <Badge ml="auto" colorScheme="green" variant="solid" fontSize="0.7em">
          {badge}
        </Badge>
      ) : null}
    </Flex>
  )
}

export const useInsurancePlanField = (
  plans: Partial<InsurancePlans> | undefined,
  modifier?: (f: DropdownField) => DropdownField,
) => {
  const { items: insuranceProviders } = useCollection(providersCollection)
  return useMemo(() => {
    const baseField: DropdownField = {
      ...getInsurancePlansField(plans || {}),
      renderOption: option => (
        <RenderPlanOption
          option={option}
          insurancePlans={plans || {}}
          allInsuranceProviders={insuranceProviders}
        />
      ),
    }
    return modifier ? modifier(baseField) : baseField
  }, [plans, modifier, insuranceProviders])
}
