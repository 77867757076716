import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Flex,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  CheckboxField,
  colors,
  dobField,
  Field,
  FieldTypes,
  getDateString,
  getMostRecentAssessment,
  optIntoTextField,
  phoneField,
  UserInvite,
  voicemailOkField,
} from '@hb/shared'

import React, { useCallback, useContext, useMemo, useState } from 'react'

import { deleteField } from 'firebase/firestore'
import { AppContext } from '../../../../contexts/AppContext'
import { PopUpMessageContext } from '../../../../contexts/PopUpMessage/PopUpMessageContext'
import { ProfileContext } from '../../../../contexts/ProfileContext'
import { manuallySetEmailVerified } from '../../../../hooks/backend/user/userUtils'
import { useUpdateDoc } from '../../../../hooks/backend/useUpdateDoc'
import { ActionLog } from '../../../ActionLog'
import { Editable, EditableRow } from '../../../forms'
import { UserBadge } from '../../UserBadge'

const emailField: Field = {
  type: FieldTypes.EMAIL,
  placeholder: 'Email',
  optional: true,
}

const InviteSummary = ({ invite }: { invite: UserInvite }) => {
  const invitedOnText = useMemo(
    () => `INVITED ${invite.createdOn ? ` ${getDateString(invite.createdOn, 'short')}` : ''}`,
    [invite],
  )
  return (
    <HStack>
      <Text fontSize="sm" fontWeight="bold" opacity={0.75} color="color">
        {invitedOnText}
      </Text>
      {invite.createdBy ? <UserBadge userId={invite.createdBy} /> : null}
    </HStack>
  )
}

// const JoinedSummary = ({ user }: { user: User | null }) => {
//   const joinedOnText = useMemo(
//     () => `Joined${user?.createdOn ? ` on ${getDateString(user.createdOn)}` : ''}`,
//     [user],
//   )
//   return (
//     <Text fontSize="sm" fontFamily="Open Sans">
//       {joinedOnText}
//     </Text>
//   )
// }

const checkboxVoicemailOkField: CheckboxField = {
  ...voicemailOkField,
  type: FieldTypes.CHECKBOX,
}
const checkboxOptIntoTextField: CheckboxField = {
  ...optIntoTextField,
  type: FieldTypes.CHECKBOX,
}

const PatientEmailVerificationView = () => {
  const { user, patientRef, emailVerification } = useContext(ProfileContext)

  const { emailVerified } = user || {}
  const { refetch } = emailVerification || {}
  const [manuallyVerifying, setManuallyVerifying] = useState(false)
  const { processResponse } = useContext(PopUpMessageContext)

  const handleManuallyVerifyEmail = useCallback(async () => {
    if (!patientRef) return processResponse({ error: 'No patient ref' })
    setManuallyVerifying(true)
    try {
      await manuallySetEmailVerified(patientRef.id)
      await refetch()
      setManuallyVerifying(false)

      return { success: 'Email set as verified' }
    } catch (err: any) {
      setManuallyVerifying(false)
      return processResponse({
        error: err?.message || 'Error manually verifying email',
      })
    }
  }, [patientRef, processResponse, refetch])
  return (
    <HStack align="center">
      {emailVerified ? (
        <ActionLog
          group="admin"
          style={{ width: 'auto', padding: 0 }}
          action="Manually verified"
          on={emailVerified.on}
          by={emailVerified.by}
        />
      ) : null}
      {emailVerification?.loading ? (
        <CircularProgress isIndeterminate size={4} />
      ) : (
        <Checkbox colorScheme="green" isChecked={emailVerification?.verified}>
          {emailVerification?.verified ? 'Verified' : 'Not verified'}
        </Checkbox>
      )}
      {emailVerification?.loading || emailVerification?.verified ? null : (
        <Button
          size="xs"
          isLoading={manuallyVerifying}
          onClick={handleManuallyVerifyEmail}
          bg={colors.green.hex}
          color="white">
          Manually Verify Email
        </Button>
      )}
    </HStack>
  )
}

export const PatientContact = () => {
  const {
    user,
    patientRef,
    selectedAssessment: { populated: selectedAssessment },
    assessments,
  } = useContext(ProfileContext)
  const { email, isInvite, phone, dob, voicemailOk, textOk } = user || {}

  const { appName } = useContext(AppContext)
  const displayedAssessment = useMemo(
    () => selectedAssessment || getMostRecentAssessment(assessments || {}),
    [selectedAssessment, assessments],
  )
  const { signOnData, signOnCorrections } = displayedAssessment || {}
  const patientAddress = useMemo(() => {
    if (!signOnData?.address && !signOnCorrections?.address) return 'None'
    const { streetAddress, streetAddress2, city, state, zip } = {
      ...signOnData?.address,
      ...signOnCorrections?.address,
    } as Record<string, string>
    let res = `${streetAddress || ''}\n`
    if (streetAddress2) res += `${streetAddress2}\n`
    res += `${city || 'No city'}, ${state || 'No state'}\n`
    res += zip || 'No ZIP'
    return res
  }, [signOnCorrections, signOnData])
  const update = useUpdateDoc('patient')

  return (
    <Flex w="100%" flexFlow="column">
      {isInvite ? (
        <InviteSummary invite={user as UserInvite} />
      ) : // <JoinedSummary user={user} />
      null}
      <VStack pl={1} spacing={0} w="100%" align="flex-start">
        <Text fontWeight={600} color="gray.600" lineHeight={1} fontSize="sm">
          Email:
        </Text>
        <Flex align="center" w="100%">
          <Flex minW="0" gap={1} flex={1}>
            {isInvite ? (
              <Editable
                field={emailField}
                value={email}
                onSubmit={async v => update(patientRef, '', { email: v || deleteField() })}
              />
            ) : (
              <Text ml={1} fontSize="15px">
                {email}
              </Text>
            )}
          </Flex>
          {isInvite ? null : <PatientEmailVerificationView />}
        </Flex>
      </VStack>
      <VStack spacing={0} w="100%" align="flex-start">
        <Box w="220px">
          <EditableRow
            label="Phone"
            stackProps={{ p: 0 }}
            field={phoneField}
            value={phone}
            onSubmit={async v => update(patientRef, '', { phone: v })}
          />
        </Box>
        <Editable
          field={checkboxVoicemailOkField}
          value={voicemailOk}
          onSubmit={async v => update(patientRef, '', { voicemailOk: v })}
        />
        <Editable
          field={checkboxOptIntoTextField}
          value={textOk}
          onSubmit={async v => update(patientRef, '', { textOk: v })}
        />
      </VStack>
      <HStack pl={1} spacing={2} w="100%" align="center">
        <Text lineHeight={1} fontWeight={600} color="gray.600" fontSize="sm">
          Date of Birth:
        </Text>
        <Editable
          style={{ background: 'transparent', padding: 0 }}
          dataCellProps={{ style: { padding: '0.2rem 0' }, fontSize: 'sm' }}
          field={dobField}
          value={dob}
          onSubmit={async v => update(patientRef, '', { dob: v })}
        />
      </HStack>
      {appName === 'app' ? (
        <VStack pl={1} spacing={0} w="100%" align="flex-start">
          <Text fontWeight={600} color="gray.600" fontSize="sm">
            Address (from sign-on info):
          </Text>
          <Text ml={1} fontSize="15px" whiteSpace="pre-wrap">
            {patientAddress}
          </Text>
        </VStack>
      ) : null}
    </Flex>
  )
}
