import { Button, Flex, Text } from '@chakra-ui/react'
import {
  AppName,
  CollectionFilter,
  dateToDateString,
  dateToTimeString,
  formatTime,
  getDateString,
  getDateTimeString,
  getDurationString,
  PracticeVisit,
  PracticeVisitDraft,
  timeParse,
  VISITS,
  visitTypeNames,
  VISIT_DRAFTS,
  WithId,
} from '@hb/shared'
import React from 'react'
import { Link } from 'react-router-dom'
import { DataColumn, DataColumnRenderProps, DataColumns, DataListTab } from '../../../../types'
import { getRenderItemNextAction } from '../../../DataList/columns/users/shared'
import { SortButton } from '../../../DataList/SortButton'
import { newVisitField } from './fields'
import { VisitPreview } from './VisitView/VisitView'

export type PracticeVisitColumn = DataColumn<PracticeVisit>

type PracticeVisitDraftColumn = DataColumn<PracticeVisitDraft>
export type VisitsTab = DataListTab<PracticeVisit>

const getVisitsInitialData = () => {
  const d = new Date()
  return {
    date: dateToDateString(d),
    startTime: timeParse(dateToTimeString(d)),
  }
}

const upcomingStartTimeColumn: PracticeVisitColumn = {
  sortKey: 'startTime',
  defaultSortDirection: 'asc',
  Header: () => (
    <SortButton defaultDir="asc" sortKey="startTime">
      Start Time
    </SortButton>
  ),
  Render: ({ data: { startTime } }) => <Text px={1}>{getDateTimeString(startTime)}</Text>,
  title: 'Start Time',
  mobile: {
    width: 220,
    Render: ({ data: { startTime } }) => (
      <Text px={1}>
        <span style={{ fontWeight: 600 }}>Starts at</span> {getDateTimeString(startTime)}
      </Text>
    ),
  },
  width: 180,
}

const upcomingEndTimeColumn: PracticeVisitColumn = {
  sortKey: 'endTime',
  defaultSortDirection: 'asc',
  Header: () => (
    <SortButton defaultDir="asc" sortKey="endTime">
      End Time
    </SortButton>
  ),
  Render: ({ data: { endTime } }) => <Text px={1}>{getDateTimeString(endTime)}</Text>,
  title: 'End Time',
  mobile: {
    width: 220,
    Render: ({ data: { endTime } }) => (
      <Text px={1}>
        <span style={{ fontWeight: 600 }}>Ends at</span> {getDateTimeString(endTime)}
      </Text>
    ),
  },
  width: 180,
}

const draftDateColumn: PracticeVisitDraftColumn = {
  sortKey: 'date',
  defaultSortDirection: 'desc',
  Header: () => (
    <SortButton defaultDir="desc" sortKey="date">
      Date
    </SortButton>
  ),
  Render: ({ data: { date } }) => <Text px={1}>{getDateString(date)}</Text>,
  title: 'Date',
  width: 150,
  mobile: {
    width: 200,
    Render: ({ data: { date } }) => (
      <Text position="relative" top="5px" fontSize="lg" px={1}>
        {getDateString(date)}
      </Text>
    ),
  },
}

const RenderDraftTime = ({
  data: { startTime, endTime },
}: DataColumnRenderProps<WithId<PracticeVisitDraft>>) => (
  <Text px={1}>
    {typeof startTime === 'string' ? formatTime(startTime) : 'None'} -{' '}
    {typeof endTime === 'string' ? formatTime(endTime) : 'None'}
  </Text>
)
const draftTimeColumn: PracticeVisitDraftColumn = {
  title: 'Time',
  width: 150,
  Header: () => <Text>Time</Text>,
  Render: RenderDraftTime,
  mobile: {
    Render: RenderDraftTime,
    width: 200,
  },
}

const durationColumn: PracticeVisitColumn = {
  defaultSortDirection: 'asc',
  Header: () => <Text>Duration</Text>,
  Render: ({ data: { startTime, endTime } }) => (
    <Text>{getDurationString(startTime, endTime)}</Text>
  ),
  title: 'Duration',
  // width: 160,
}

export const RenderVisitNextAction = getRenderItemNextAction<PracticeVisit>(VISITS, 'Visit')
const visitNextActionColumn: PracticeVisitColumn = {
  title: 'Next Action',
  Header: () => (
    <Flex align="center">
      <SortButton sortKey="nextActionDate">Next Action</SortButton>
    </Flex>
  ),
  isLazy: true,
  sortKey: 'nextActionDate',
  Render: RenderVisitNextAction,
}

const getPatientNameColumn = (onPatientProfile: boolean): PracticeVisitColumn => ({
  sortKey: 'patientLast',
  defaultSortDirection: 'asc',
  Header: () => (
    <SortButton px={2} sortKey="patientLast">
      Patient Name
    </SortButton>
  ),
  width: 220,
  Render: ({ data: { patientFirst, patientLast, patientId }, app: { appName } }) => {
    const text = (
      <Text isTruncated fontSize="md" px={2}>
        {patientLast}, {patientFirst}
      </Text>
    )
    if (!onPatientProfile)
      return (
        <Link to={`${appName === 'app' ? '/admin' : ''}/patients/${patientId}/visits`}>
          <Button variant="link">{text}</Button>
        </Link>
      )

    return text
  },
  title: 'Patient Name',
  mobile: {
    width: 200,
    Render: ({ data: { patientFirst, patientLast, patientId }, app: { appName } }) => {
      const text = (
        <Text isTruncated fontSize="md" px={2}>
          <span style={{ fontWeight: 600 }}>Patient:</span> {patientLast}, {patientFirst}
        </Text>
      )
      if (!onPatientProfile)
        return (
          <Link to={`${appName === 'app' ? '/admin' : ''}/patients/${patientId}/visits`}>
            <Button variant="link">{text}</Button>
          </Link>
        )
      return text
    },
  },
  // width: 150,
})

const visitTypeColumn: PracticeVisitColumn = {
  sortKey: 'type',
  defaultSortDirection: 'asc',
  Header: () => <SortButton sortKey="type">Type</SortButton>,
  Render: ({ data: { type } }) => <Text px={1}>{visitTypeNames[type] || 'Unknown'}</Text>,
  title: 'Type',
  width: 100,
}

const getVisitsFilters = (
  practiceId: string | null,
  patientId: string | null,
  assessmentId: string | null,
  onlyStandaloneVisits: boolean,
): CollectionFilter[] => {
  const filters: CollectionFilter[] = []
  if (patientId) {
    filters.push(['patientId', '==', patientId])
  }
  if (practiceId) {
    filters.push(['practiceId', '==', practiceId])
  }
  if (assessmentId || onlyStandaloneVisits) {
    filters.push(['assessmentId', '==', assessmentId])
  }
  return filters
}

const getVisitColumns = (
  appName: AppName,
  patientId: string | null,
): DataColumns<PracticeVisit> => {
  if (appName === 'providers-app') {
    return {
      patientName: getPatientNameColumn(!!patientId),
      type: visitTypeColumn,
      startTime: upcomingStartTimeColumn,
      endTime: upcomingEndTimeColumn,
      duration: durationColumn,
    }
  }
  return {
    patientName: getPatientNameColumn(!!patientId),
    type: visitTypeColumn,
    startTime: upcomingStartTimeColumn,
    endTime: upcomingEndTimeColumn,
    duration: { ...durationColumn, width: 150 },
    nextAction: visitNextActionColumn,
  }
}

export const getVisitsTab = (
  appName: AppName,
  practiceId: string | null,
  patientId: string | null,
  assessmentId: string | null,
  onlyStandaloneVisits: boolean,
  onCreateClick?: () => void,
  // onCreateNew?: (data: FieldMapValue) => Promise<UpdateCallback>,
): VisitsTab => ({
  collection: VISITS,
  filters: getVisitsFilters(practiceId, patientId, assessmentId, onlyStandaloneVisits),
  searchStringPath: 'searchString',
  columns: getVisitColumns(appName, patientId),
  mobile: {
    ItemView: VisitPreview,
    itemHeight: appName === 'providers-app' ? 80 : 110,
  },
  itemHeight: 40,
  creation: onCreateClick ? getVisitsCreation(onCreateClick) : undefined,
  defaultSortKey: 'startTime',
  itemName: 'visit',
})

const getVisitsCreation = (
  onCreateClick: () => void,
): DataListTab<WithId<PracticeVisit>>['creation'] => ({
  field: newVisitField,
  submitText: 'Submit Visit or Save Draft',
  itemName: 'visit',
  onCreateClick,
  initialData: getVisitsInitialData,
})

const getVisitDraftsFilters = (
  patientId: string,
  assessmentId: string | null,
  practiceId: string | null,
  onlyStandaloneVisits: boolean,
) => {
  const filters: CollectionFilter[] = []
  filters.push(['patientId', '==', patientId])
  if (assessmentId || onlyStandaloneVisits) {
    filters.push(['assessmentId', '==', assessmentId])
  }
  if (practiceId) {
    filters.push(['practiceId', '==', practiceId])
  }
  return filters
}

export const getVisitDraftsTab = (
  patientId: string,
  practiceId: string | null,
  assessmentId: string | null,
  onCreateClick: () => void,
  onlyStandaloneVisits: boolean,
): DataListTab<WithId<PracticeVisitDraft>> => ({
  collection: VISIT_DRAFTS,
  filters: getVisitDraftsFilters(patientId, assessmentId, practiceId, onlyStandaloneVisits),
  searchStringPath: 'searchString',
  creation: getVisitsCreation(onCreateClick),
  columns: {
    type: visitTypeColumn as any,
    date: draftDateColumn,
    time: draftTimeColumn,
    patientName: getPatientNameColumn(true) as any,
  },
  defaultSortKey: 'date',
  itemName: 'visit draft',
})
