import {
  Center,
  Flex,
  HStack,
  Image,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { colors } from '@hb/shared'
import React, { useEffect } from 'react'
import useSound from 'use-sound'
import { useDebounce } from '../../../hooks/useDebounce'
import volumeIcon from '../../../icons/volume.svg'
import { useThreadsList } from '../contexts'
import { useMyMessageSound } from './hooks'

export const NotificationsVolumeControl = () => {
  const { widget, settings } = useThreadsList()
  const {
    data: widgetData,
    updateLocalNotificationVolume,
    localNotificationVolume,
    update,
  } = widget || {}
  const { notificationVolume: volume } = widgetData || {}

  const debouncedValue = useDebounce(localNotificationVolume, 500)
  const messageSound = useMyMessageSound(settings, 'messaging')

  const [play] = useSound(messageSound, {
    volume: localNotificationVolume || 0.25,
  })
  useEffect(() => {
    if (debouncedValue && widgetData?.notificationVolume !== debouncedValue) {
      update({ notificationVolume: debouncedValue }).catch(err => {
        console.error(err)
      })
    }
  }, [debouncedValue, widgetData, update])
  return (
    <Flex flexFlow="column">
      <Text fontFamily="Open Sans" fontSize="sm" color="gray.600">
        Volume
      </Text>
      <HStack w="100%">
        <Image src={volumeIcon} w="22px" opacity={0.5} />
        <Slider
          value={(localNotificationVolume || volume || 0.25) * 100}
          max={100}
          min={0}
          onPointerUp={() => {
            play()
          }}
          step={1}
          colorScheme="gray"
          onChange={e => {
            updateLocalNotificationVolume(e / 100)
          }}>
          <SliderTrack overflow="hidden" borderRadius="full" h="6px" bg="blackAlpha.400">
            <SliderFilledTrack bg={colors.green.hex} />
          </SliderTrack>
          <Tooltip
            label={((localNotificationVolume || volume || 0.25) * 100).toFixed(0)}
            placement="top"
            hasArrow
            bg="gray.50"
            color="gray.600">
            <SliderThumb />
          </Tooltip>
        </Slider>
      </HStack>
    </Flex>
  )
}

export const NotificationVolumePopover = () => (
  <Center position="relative">
    <Popover strategy="fixed" trigger="hover">
      <PopoverTrigger>
        <Image filter="invert(100%)" cursor="pointer" src={volumeIcon} w="22px" opacity={0.8} />
      </PopoverTrigger>
      <PopoverContent filter="drop-shadow(1px 1px 4px #00000077)" border="none" bg="transparent">
        <NotificationsVolumeControl />
        <PopoverArrow />
      </PopoverContent>
    </Popover>
  </Center>
)
