import { CalendarIcon, CloseIcon, EditIcon } from '@chakra-ui/icons'
import { Box, Button, Collapse, Flex, IconButton, Img, Text } from '@chakra-ui/react'
import {
  Assessment,
  dateTo24HourTimeString,
  FieldMapValue,
  getAssessmentName,
  getDateString,
  getEdd,
  mergeAssessmentData,
  SubmittedVisitData,
  VISIT_DRAFTS,
  WithId,
} from '@hb/shared'
import { addDoc, collection } from 'firebase/firestore'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { db } from '../../../../backend/db'
import { PopUpMessageContext } from '../../../../contexts/PopUpMessage/PopUpMessageContext'
import pregnancyIcon from '../../../../icons/breastfeeding.svg'
import { ToggleButton } from '../../../Buttons'
import { SimpleForm } from '../../../forms'
import { DefaultModal } from '../../../Modals'
import { SelectPatientAssessment } from '../../../Patients'
import { VisitsContext } from './contexts'
import { newVisitField } from './fields'
import { populateVisitDraft } from './utils'

type NewVisitProps = {
  onComplete: (draftId: string) => void
  practiceId: string | null
  onClose: () => void
}

const CloseButton = ({ onClose }: { onClose: () => void }) => {
  return (
    <Button
      bg="whiteAlpha.600"
      borderRadius="full"
      variant="outline"
      ml="auto"
      size="xs"
      onClick={onClose}>
      <Flex gap={1.5} align="center">
        <CloseIcon position="relative" top="1px" w={2} h={2} />
        <Text>CLOSE</Text>
      </Flex>
    </Button>
  )
}

const NewVisitContent = ({ onComplete, practiceId, onClose }: NewVisitProps) => {
  const [visitType, setVisitType] = useState<'pregnancy' | 'office-visit' | null>(null)
  const [pregnancy, setPregnancy] = useState<WithId<Assessment> | null>(null)
  const { pregnancyName, pregnancyEdd } = useMemo(() => {
    if (!pregnancy) return { pregnancyName: null, pregnancyEdd: null }
    return {
      pregnancyName: getAssessmentName(pregnancy),
      pregnancyEdd: getEdd(mergeAssessmentData(pregnancy)),
    }
  }, [pregnancy])
  const { patient, patientId } = useContext(VisitsContext)
  const stage = useMemo(() => {
    if (!visitType) return 'type'
    if (visitType === 'pregnancy') return pregnancy ? 'form' : 'type'
    return 'form'
  }, [visitType, pregnancy])
  const { processResponse } = useContext(PopUpMessageContext)
  const onCreateNew = useCallback(
    async (data: FieldMapValue) => {
      if (!patient || !patientId) return { error: 'No patient selected' }
      if (!visitType) return { error: 'No visit type selected' }
      if (visitType === 'pregnancy' && !pregnancy) return { error: 'No pregnancy selected' }
      if (!data.startTime) return { error: 'Start time is required' }
      if (!data.endTime) return { error: 'End time is required' }
      if (!practiceId)
        return {
          error: "Practice ID is not selected. For now, add a practice to the patient's pregnancy",
        }
      const startDate = new Date(data.startTime)
      const endDate = new Date(data.endTime)
      if (startDate > endDate) return { error: 'Start time must be before end time' }
      // Update this when adding new types of visits

      const draftData = {
        assessmentEdd: pregnancyEdd,
        assessmentName: pregnancyName,
        assessmentId: visitType === 'pregnancy' && pregnancy ? pregnancy.id : null,
        patientId,
        practiceId,
        type: 'encounter',
        ...data,
      } as SubmittedVisitData
      try {
        const res = await addDoc(
          collection(db, VISIT_DRAFTS),
          populateVisitDraft(draftData, practiceId, patient),
        )
        onComplete(res.id)
        processResponse({ success: 'Draft saved' })
      } catch (e: any) {
        console.error({ error: e?.message || 'error' })
        processResponse({ error: e.message || 'error' })
      }
      return { success: 'Created draft, save or submit?' }
    },
    [
      patientId,
      patient,
      visitType,
      pregnancy,
      processResponse,
      practiceId,
      onComplete,
      pregnancyEdd,
      pregnancyName,
    ],
  )
  const initFormData = useMemo(() => {
    const now = new Date()
    const isoString = now.toISOString()
    const [date] = isoString.split('T')
    return {
      date,
      startTime: dateTo24HourTimeString(now),
    }
  }, [])

  return (
    <Flex w="100%" flexFlow="column">
      <Collapse in={stage === 'type'} style={{ width: '100%' }}>
        <Flex bg="gray.100" p={2} gap={1} w="100%" flexFlow="column">
          <Flex px={2} align="center">
            <Text fontSize="lg" fontFamily="Hero-New">
              Is this visit related to a pregnancy?
            </Text>
            <CloseButton onClose={onClose} />
          </Flex>
          <Flex gap={1.5} align="center">
            <ToggleButton
              size="sm"
              onClick={() => setVisitType('pregnancy')}
              isActive={visitType === 'pregnancy'}
              flex={1}>
              Yes
            </ToggleButton>
            <ToggleButton
              size="sm"
              onClick={() => setVisitType('office-visit')}
              isActive={visitType === 'office-visit'}
              flex={1}>
              No
            </ToggleButton>
          </Flex>
          <Collapse unmountOnExit in={visitType === 'pregnancy'} style={{ width: '100%' }}>
            <Box px={2} h="48px" w="100%" pt={1}>
              {visitType === 'pregnancy' ? (
                <SelectPatientAssessment
                  openOnMount
                  onSelect={async (id, assessment) => {
                    setPregnancy({ ...assessment, id })
                    return { success: 'Selected' }
                  }}
                  patientId={patientId}
                />
              ) : null}
            </Box>
          </Collapse>
        </Flex>
      </Collapse>
      <Collapse in={stage === 'form'} style={{ width: '100%' }}>
        <Flex w="100%" flexFlow="column">
          <Flex bg="gray.50" p={2} w="100%" borderBottom="1px solid #cdcdcd" flexFlow="row">
            <Flex w="100%" flexFlow="column">
              <Flex w="100%" align="center" gap={1}>
                <Text pl={2} fontWeight={500} fontFamily="Hero-New">
                  {visitType === 'pregnancy' ? 'Pregnancy Visit' : 'Standalone Visit'}
                </Text>
                <IconButton
                  aria-label="Edit type"
                  size="xs"
                  variant="ghost"
                  icon={<EditIcon />}
                  onClick={() => {
                    setVisitType(null)
                    setPregnancy(null)
                  }}
                />
                <CloseButton onClose={onClose} />
              </Flex>
              {visitType === 'pregnancy' ? (
                <Flex w="100%" align="center">
                  <Img filter="brightness(0.5)" src={pregnancyIcon} w={6} h={6} />
                  <Text color="gray.600">{pregnancyName}</Text>
                  <Flex gap={1} ml="auto" align="center">
                    <CalendarIcon color="gray.600" />
                    <Text fontWeight={600} fontSize="sm" color="gray.600">
                      EDD
                    </Text>
                    <Text fontSize="sm" color="gray.600">
                      {pregnancyEdd ? getDateString(pregnancyEdd, 'short') : 'None'}
                    </Text>
                  </Flex>
                </Flex>
              ) : null}
            </Flex>
          </Flex>
          <SimpleForm value={initFormData} onSubmit={onCreateNew} field={newVisitField} />
        </Flex>
      </Collapse>
    </Flex>
  )
}
export const NewVisitModal = ({
  isOpen,
  onClose,
  ...props
}: NewVisitProps & { isOpen: boolean }) => {
  return (
    <DefaultModal
      isOpen={isOpen}
      size="xl"
      onClose={onClose}
      closeDisabled
      overlayHeader
      render={() => <NewVisitContent onClose={onClose} {...props} />}
    />
  )
}
