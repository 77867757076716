import { Flex, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import React, { useContext, useMemo, useState } from 'react'
import { ScreenContext } from '../../../../../contexts'
import { useApp } from '../../../../../contexts/AppContext'
import { ProfileContext, useProfile } from '../../../../../contexts/ProfileContext'
import { AssessmentSummary } from '../../../../Assessments'
import { LoadingOverlay } from '../../../../LoadingOverlay'
import { AssessmentAuthorizationsTab } from '../../Authorizations/AssessmentAuthorizations'
import { ProfileVisits } from '../../Charting/Visits'
import { AssessmentClaims } from '../../Claims/AssessmentClaims'
import { AssessmentFiles } from '../../Files/AssessmentFiles'
import { AssessmentLog } from '../../Log/AssessmentLog'
import { Resizable } from '../../Log/Resizable'
import { useV1ProfileTabs } from '../../Nav/hooks'
import { PatientSummary } from '../../Patient/PatientSummary'
import { AssessmentPaymentsTab } from '../../Payments'
import { ProfileHeader } from './ProfileHeader'

const ProfileTabsPanel = ({ width, height }: { width: number; height: number }) => {
  const { appName } = useApp()
  const { assessmentId, userId, assessments } = useContext(ProfileContext)
  const numAssessments = Object.keys(assessments || {}).length
  const mobileLayout = useMemo(() => width < 769, [width])
  return (
    <TabPanels
      overflowY="auto"
      style={{ width: 'auto' }}
      bg="#f3f3f3"
      height={`${height}px`}
      // height={contentHeight}
    >
      <TabPanel bg="gray.100" minH="100%" px={0}>
        <PatientSummary />
      </TabPanel>
      {numAssessments ? (
        <TabPanel bg="gray.100" minH="100%" p={0}>
          <AssessmentSummary width={width} />
        </TabPanel>
      ) : null}
      {assessmentId ? (
        <TabPanel p={mobileLayout ? 1 : 2}>
          <AssessmentPaymentsTab />
        </TabPanel>
      ) : null}
      {appName === 'app' && assessmentId ? (
        <TabPanel p={0}>
          <AssessmentAuthorizationsTab />
        </TabPanel>
      ) : null}
      {appName === 'app' && assessmentId ? (
        <TabPanel p={0}>
          <AssessmentClaims />
        </TabPanel>
      ) : null}
      {/* {appName === 'app' ? ( */}
      {assessmentId ? (
        <TabPanel>
          <AssessmentFiles />
        </TabPanel>
      ) : null}
      <TabPanel h="100%" p={0}>
        {userId ? (
          <ProfileVisits onlyStandaloneVisits={false} assessmentId={null} />
        ) : (
          <LoadingOverlay text="Loading visits..." />
        )}
      </TabPanel>
      {/* ) : null} */}
    </TabPanels>
  )
}

export const ProfileBody = ({ height, width }: { height: number; width: number }) => {
  const { tabName, onTabSelect } = useProfile()
  const { appName } = useApp()
  const profileTabNames = useV1ProfileTabs()
  const index = useMemo(
    () => (tabName ? profileTabNames.indexOf(tabName) : 0),
    [tabName, profileTabNames],
  )

  const { isMobile } = useContext(ScreenContext)
  const [headerHeight, setHeaderHeight] = useState(0)
  const contentHeight = useMemo(
    () => (isMobile ? height : height - headerHeight),
    [height, isMobile, headerHeight],
  )
  return (
    <Flex height={height} flexFlow="column">
      <Tabs
        index={index}
        onChange={i => onTabSelect(profileTabNames[i], null)}
        style={{
          height: `${height}px`,
          background: 'rgba(255,255,255,0.9)',
        }}
        isLazy>
        <ProfileHeader
          width={width}
          onResize={size => {
            setHeaderHeight(size.height)
          }}
        />
        {isMobile || appName === 'providers-app' ? (
          <ProfileTabsPanel height={contentHeight} width={width} />
        ) : (
          <Resizable
            width={width}
            height={contentHeight}
            localStorageId="user-profile-resize"
            leftChild={leftWidth => <ProfileTabsPanel height={contentHeight} width={leftWidth} />}
            rightChild={() => (
              <AssessmentLog
                // width={rightWidth}
                maxHeight={contentHeight}
              />
            )}
          />
        )}
      </Tabs>
    </Flex>
  )
}
