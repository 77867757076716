import { WarningIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  CircularProgress,
  Divider,
  Flex,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  CLAIMS_ABILITY_DETAIL,
  colors,
  defaultStageValidate,
  FieldMap,
  FieldMapValue,
  getCurrentlyOnMedicaidPlan,
  getDateString,
  getMedicaidCoverage,
  getPrimaryCoverage,
  InsuranceCoverage,
  isInsuranceCoverageRequest,
} from '@hb/shared'

import { FORM_ERROR, ValidationErrors } from 'final-form'
import arrayMutators from 'final-form-arrays'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { PopUpMessageContext } from '../../../../../../contexts/PopUpMessage/PopUpMessageContext'
import { ProfileContext } from '../../../../../../contexts/ProfileContext'
import { ScreenContext } from '../../../../../../contexts/ScreenContext'
import { ThemeContext } from '../../../../../../contexts/ThemeContext'
import { submitNewClaim } from '../../../../../../hooks/backend/claims'
import { useDocument } from '../../../../../../hooks/backend/useDocument'
import { Condition } from '../../../../../forms'
import { FormElement } from '../../../../../forms/Input'
import { DefaultModal } from '../../../../../Modals/DefaultModal'
import { Subheader2 } from '../../../../Text'

import { abilityDataToFormData, formDataToAbilityData } from '../../utils'
import {
  abilityClaimField,
  customRelateCodePrimary,
  customRelateCodeSecondary,
  diagnosisCodesField,
  servicesValidationField,
  shouldUseCustomRelateCodeField,
  shouldUseCustomRenderingProviderField,
} from '../fields'
import { SelectInsurer } from './AdditionalPlans'
import { AffiliatedHospitals } from './AffiliatedHospitals'
import { DeliveryDateView } from './DeliveryDateView'
import { DiagnosisCodesView } from './DiagnosisCodes'
import { ReferringProvider } from './ReferringProvider'
import { RenderingProvider } from './RenderingProvider'
import { SaveDraftModal } from './SaveDraftModal'
import { SelectDraftMenu } from './SelectDraftMenu'
import { Services } from './Services'

const validationField: FieldMap = {
  name: 'New ability claim',
  children: {
    ...abilityClaimField.children,
    DiagnosisCodes: diagnosisCodesField,
    Services: servicesValidationField,
    shouldUseCustomRelateCodePrimary: shouldUseCustomRelateCodeField,
    customRelateCodePrimary,
    shouldUseCustomRelateCodeSecondary: shouldUseCustomRelateCodeField,
    customRelateCodeSecondary,
    shouldUseCustomRenderingProvider: shouldUseCustomRenderingProviderField,
  },
}

const AddAbilityClaimProvidersSection = () => {
  const {
    selectedAssessment: { populated: selectedAssessment },
  } = useContext(ProfileContext)
  const { midwife } = selectedAssessment || {}
  return (
    <VStack
      borderRadius={4}
      border="1px solid #cdcdcd"
      spacing={0}
      bg="white"
      boxShadow="md"
      align="flex-start"
      w="100%"
      px={3}
      py={2}>
      <Text fontWeight={600} color="gray.500" fontSize="md">
        Provider
      </Text>
      {midwife ? (
        <>
          <ReferringProvider />
          <Divider pt={2} />
          <RenderingProvider />
          <Divider pt={2} />
          <AffiliatedHospitals />
        </>
      ) : (
        <Text color="red.500" fontSize="md">
          No provider listed for this assessment
        </Text>
      )}
    </VStack>
  )
}

const newbornClaimCondition = (data: FieldMapValue) => data?.serviceType === 'newborn'

const NewbornClaimInfo = () => {
  const {
    selectedAssessment: { populated: selectedAssessment },
  } = useContext(ProfileContext)
  const { deliveredOn, delivery } = selectedAssessment || {}
  const { newbornFname, newbornLname, newbornSex } = delivery || {}
  return (
    <Condition basePath="" condition={newbornClaimCondition}>
      <Box w="100%" px={4}>
        <Flex
          py={1}
          px={3}
          borderRadius={6}
          border="1px solid #cdcdcd"
          bg="gray.50"
          flexFlow="column"
          w="100%">
          {!deliveredOn || !newbornSex ? (
            <HStack bg="white" my={1} px={2} py={1} border="1px solid #880000" borderRadius={4}>
              <WarningIcon color="red.600" />
              <Flex flexFlow="column">
                <Subheader2 color="red.600" fontSize="sm">
                  Newborn claim requires delivery date and newborn sex
                </Subheader2>
                <Subheader2 color="red.600" fontSize="sm">
                  (Can be added on the delivery section of the profile header)
                </Subheader2>
              </Flex>
            </HStack>
          ) : null}
          <Subheader2 color="gray.600" fontSize="md">
            Newborn Claim - Using newborn information (from delivery)
          </Subheader2>
          <Flex gap={1} align="center">
            <Subheader2 w="80px" fontWeight={600}>
              First name
            </Subheader2>
            <Text borderLeft="1px solid #cdcdcd" pl={2} color="gray.600">
              {newbornFname || 'None - using default value "Baby"'}
            </Text>
          </Flex>
          <Flex gap={1} align="center">
            <Subheader2 w="80px" fontWeight={600}>
              Last name
            </Subheader2>
            <Text borderLeft="1px solid #cdcdcd" pl={2} color="gray.600">
              {newbornLname || 'None - using default value "Girl/Boy"'}
            </Text>
          </Flex>
          <Flex gap={1} align="center">
            <Subheader2 w="80px" fontWeight={600}>
              DOB
            </Subheader2>
            <Text
              borderLeft="1px solid #cdcdcd"
              pl={2}
              color={deliveredOn ? 'gray.600' : 'red.600'}>
              {deliveredOn ? getDateString(deliveredOn) : 'None'}
            </Text>
          </Flex>
          <Flex gap={1} align="center">
            <Subheader2 w="80px" fontWeight={600}>
              Sex
            </Subheader2>
            <Text borderLeft="1px solid #cdcdcd" pl={2} color={newbornSex ? 'gray.600' : 'red.600'}>
              {newbornSex?.toUpperCase() || 'None'}
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Condition>
  )
}

export const AbilityClaimSubmission = (props: {
  initialValue?: FieldMapValue
  existingAbilityId?: string | null
  onClose?: () => void
}) => {
  const {
    selectedAssessment: { populated: selectedAssessment, adminRef },
    assessmentId,
  } = useContext(ProfileContext)
  const { claimsDrafts, additionalPlans, mergedData } = selectedAssessment || {}

  const currentlyOnMedicaid = useMemo(() => getCurrentlyOnMedicaidPlan(mergedData), [mergedData])
  const mainCoverage = useMemo(
    () => (currentlyOnMedicaid ? getMedicaidCoverage(mergedData) : getPrimaryCoverage(mergedData)),
    [mergedData, currentlyOnMedicaid],
  )

  const secondaryCoverage = useMemo<InsuranceCoverage | null>(() => {
    const match = Object.entries(additionalPlans || {}).find(([, plan]) => {
      if (isInsuranceCoverageRequest(plan)) return false
      if (plan.label === 'secondary') {
        return true
      }
      return false
    })
    return match
      ? {
          ...(match[1] as InsuranceCoverage),
          id: `additionalPlans.${match[0]}`,
        }
      : null
  }, [additionalPlans])

  const { initialValue, existingAbilityId, onClose } = props || {}
  const { showError } = useContext(PopUpMessageContext)
  const [selectedDraftId, setSelectedDraftId] = useState<string | null>(null)
  const [savingAsDraft, setSavingAsDraft] = useState<{
    id: string
    data: FieldMapValue
  } | null>(null)
  const onSaveDraftComplete = useCallback((id: string) => {
    setSavingAsDraft(null)
    setSelectedDraftId(id)
  }, [])

  const onSaveDraftClick = useCallback(
    async (data: FieldMapValue) => {
      if (!adminRef) {
        showError('No assessment selected')
        return { [FORM_ERROR]: 'No assessment selected' }
      }
      const id = selectedDraftId || `${Date.now()}`
      setSavingAsDraft({ id, data })
      return undefined
    },
    [selectedDraftId, showError, adminRef],
  )

  const onSubmit = useCallback(
    async (data: FieldMapValue): Promise<ValidationErrors> => {
      if (!assessmentId) {
        showError('No assessment selected')
        return { error: 'No assessment selected' }
      }
      const submitted: FieldMapValue = formDataToAbilityData(data, assessmentId, selectedDraftId)
      if (existingAbilityId) submitted.claimId = existingAbilityId
      return submitNewClaim(submitted)
        .then(() => {
          if (onClose) onClose()
          return { success: 'test claim submitted' }
        })
        .catch((err: any) => {
          console.error(err)
          const msg = err.message
          showError(msg)
          return { [FORM_ERROR]: msg }
        })
    },
    [assessmentId, showError, onClose, selectedDraftId, existingAbilityId],
  )

  const mainCoverageId = useMemo(() => {
    if (currentlyOnMedicaid) return mainCoverage ? 'medicaidCoverage' : ''
    return mainCoverage ? 'primaryCoverage' : ''
  }, [currentlyOnMedicaid, mainCoverage])

  const defaultInitValue = useMemo(
    () => ({
      ...initialValue,
      mainCoverageId,
      otherCoverageId: secondaryCoverage ? secondaryCoverage.id : '',
    }),
    [secondaryCoverage, initialValue, mainCoverageId],
  )
  const [initValue, setInitValue] = useState<FieldMapValue>(
    selectedDraftId ? claimsDrafts?.[selectedDraftId]?.data || {} : defaultInitValue,
  )
  const [reinitializing, setReinitializing] = useState(false)

  useEffect(() => {
    if (selectedDraftId) {
      if (claimsDrafts?.[selectedDraftId]?.data) {
        setReinitializing(true)
        setTimeout(() => {
          const init = claimsDrafts?.[selectedDraftId]?.data || {}
          const { Services: initServices } = init || {}
          setInitValue({
            ...init,
            Services: initServices?.map(s => ({
              ...s,
              DiagnosisPointer:
                typeof s.DiagnosisPointer === 'string' ? [s.DiagnosisPointer] : s.DiagnosisPointer,
            })),
          })
          setReinitializing(false)
        }, 200)
      }
    } else {
      setReinitializing(true)
      setTimeout(() => {
        setInitValue({ ...initialValue, ...defaultInitValue })
        setReinitializing(false)
      }, 200)
    }
  }, [selectedDraftId, claimsDrafts, initialValue, defaultInitValue])

  const sufficientInfoCondition = useCallback(
    (data: FieldMapValue) => {
      if (data.serviceType !== 'newborn') return true
      const { deliveredOn, delivery } = selectedAssessment || {}

      const { newbornSex } = delivery || {}
      return !!deliveredOn && !!newbornSex
    },
    [selectedAssessment],
  )

  const validate = useMemo(() => defaultStageValidate(validationField), [])

  return (
    <>
      <ThemeContext.Provider value={{ theme: 'detailed' }}>
        <VStack w="100%" spacing={2} align="flex-start" px={4} pt={2} pb="60px">
          {reinitializing ? (
            <Center w="100%" minH="500px">
              <CircularProgress color="green.400" isIndeterminate />
            </Center>
          ) : (
            <Form
              onSubmit={onSubmit}
              initialValues={initValue}
              keepDirtyOnReinitialize={true}
              validate={validate}
              mutators={{ ...(arrayMutators as any) }}>
              {({ handleSubmit, submitting, hasValidationErrors }) => (
                <>
                  <HStack justify="space-between" spacing={3} px={2} align="center" w="100%">
                    <Text flex={1} color="gray.500" fontSize="lg">
                      {existingAbilityId ? 'RE-SUBMIT CLAIM ' : 'SUBMIT NEW CLAIM'}
                    </Text>
                    <HStack flex={1} justify="center" position="relative">
                      <SelectDraftMenu
                        onSaveDraftClick={onSaveDraftClick}
                        selectedDraftId={selectedDraftId}
                        onSelect={setSelectedDraftId}
                      />
                    </HStack>
                    <HStack flex={1} justify="flex-end" align="center">
                      {onClose ? (
                        <Button
                          size="sm"
                          boxShadow="md"
                          onClick={onClose}
                          bg="white"
                          color={colors.green.hex}>
                          CANCEL
                        </Button>
                      ) : null}
                      <Button
                        size="sm"
                        boxShadow="md"
                        isLoading={submitting}
                        onClick={handleSubmit}
                        bg={hasValidationErrors ? 'gray.300' : colors.green.hex}
                        color="white">
                        {existingAbilityId ? 'RE-SUBMIT' : 'SUBMIT'}
                      </Button>
                    </HStack>
                  </HStack>
                  <VStack w="100%">
                    <VStack maxW="800px" w="100%">
                      <VStack
                        borderRadius={4}
                        border="1px solid #cdcdcd"
                        bg="white"
                        boxShadow="md"
                        spacing={0}
                        width="100%"
                        align="flex-start"
                        flex={1}
                        px={2}
                        pb={3}
                        // py={1}
                      >
                        <FormElement root active name="" field={abilityClaimField} />
                        <DeliveryDateView />
                        <Services />
                        <DiagnosisCodesView />
                        <NewbornClaimInfo />
                      </VStack>
                      <Condition basePath="" condition={sufficientInfoCondition}>
                        <AddAbilityClaimProvidersSection />
                        <SelectInsurer assessment={selectedAssessment || null} />
                      </Condition>
                    </VStack>
                  </VStack>
                </>
              )}
            </Form>
          )}
        </VStack>
      </ThemeContext.Provider>
      <SaveDraftModal
        savingAsDraft={savingAsDraft}
        onClose={() => setSavingAsDraft(null)}
        onComplete={onSaveDraftComplete}
      />
    </>
  )
}

export const ResubmitAbilityClaim = (props: {
  existingAbilityId: string
  serviceType: string | null | undefined
  onClose?: () => void
}) => {
  const { existingAbilityId, serviceType, onClose } = props
  const { data: existingData, loading } = useDocument<FieldMapValue>(
    CLAIMS_ABILITY_DETAIL,
    existingAbilityId,
  )

  const initData = useMemo(
    () => ({
      serviceType: serviceType || '',
      ...abilityDataToFormData(existingData || {}),
    }),
    [existingData, serviceType],
  )
  if (loading) {
    return (
      <HStack p={3}>
        <CircularProgress size={5} color="gray" isIndeterminate />
        <Text>Loading submitted claim...</Text>
      </HStack>
    )
  }

  if (!existingData) {
    return (
      <HStack p={2}>
        <Text color="red.600">ERROR: Submitted claim not found</Text>
      </HStack>
    )
  }

  return (
    <AbilityClaimSubmission
      onClose={onClose}
      existingAbilityId={existingAbilityId}
      initialValue={initData}
    />
  )
}

export const AbilityClaimSubmissionModal = (props: {
  isOpen: boolean
  onClose: () => void
  existingAbilityId?: string
}) => {
  const screen = useContext(ScreenContext)
  return (
    <DefaultModal
      closeOnOverlayClick={false}
      closeOnEsc={false}
      overlayHeader
      {...props} // eA8geZtpl6eIR43DYrE1
      size="6xl"
      scrollBehavior="inside"
      bodyProps={{ maxH: screen.height, pt: 0 }}
      contentProps={{ pt: 0 }}
      render={() =>
        props.isOpen ? (
          <AbilityClaimSubmission {...props} />
        ) : (
          <HStack p={3}>
            <CircularProgress size={5} color="gray" isIndeterminate />
            <Text>Loading...</Text>
          </HStack>
        )
      }
    />
  )
}
