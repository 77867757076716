import {
  Button,
  Flex,
  HStack,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { colors } from '@hb/shared'

import React, { useContext, useMemo } from 'react'
import { ProfileContext } from '../../../../contexts'
import deliveryIcon from '../../../../icons/breastfeeding_green.svg'
import { ReportDeliveryContent } from './ReportDeliveryContent'

// const getDeliveryComplete = (delivery: Partial<Delivery>) => {
//   const { newbornFname, newbornLname, newbornWeight, newbornSex, deliveryTypes, location, locationOther, summary } = delivery
// }

export const ReportDeliveryPopover = ({ mobileLayout }: { mobileLayout: boolean }) => {
  const {
    selectedAssessment: { populated: selectedAssessment },
  } = useContext(ProfileContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { deliveredOn, delivery } = selectedAssessment || {}
  const text = useMemo(() => {
    if (deliveredOn && deliveredOn !== Infinity) return 'DELIVERED'
    if (delivery?.isTransfer) return 'TRANSFER'
    return 'DELIVERY / TRANSFER'
  }, [deliveredOn, delivery])
  const hasData = useMemo(() => !!deliveredOn || !!delivery?.isTransfer, [deliveredOn, delivery])
  return (
    <Popover
      closeOnBlur={false}
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      isLazy
      placement={mobileLayout ? 'bottom' : 'left-start'}
      strategy="fixed">
      <PopoverTrigger>
        <Flex ml={mobileLayout ? 'auto' : 0} w="auto">
          <Tooltip
            hasArrow
            placement="bottom"
            label={deliveredOn ? 'Click to view / update' : 'Click to report delivery'}>
            <Button
              size={mobileLayout ? 'xs' : 'sm'}
              fontWeight={300}
              w={hasData ? '140px' : '200px'}
              fontFamily="hero-new"
              bg={hasData ? colors.green.hex : 'gray.50'}
              color="gray.800"
              _hover={{ bg: hasData ? 'gray.300' : 'green.300' }}>
              <HStack spacing={0}>
                {hasData ? (
                  <Image
                    width="21px"
                    src={deliveryIcon}
                    opacity={hasData ? 1 : 0.5}
                    filter={hasData ? 'grayscale(100%) brightness(200%)' : 'none'}
                  />
                ) : null}
                <Text
                  height="14px"
                  fontFamily="Comfortaa"
                  px={1}
                  fontSize={mobileLayout ? 'xs' : 'sm'}
                  fontWeight={600}
                  color={hasData ? 'gray.50' : colors.green.hex}>
                  {text}
                </Text>
              </HStack>
            </Button>
          </Tooltip>
        </Flex>
      </PopoverTrigger>
      <Portal>
        <PopoverContent h="auto" w="auto" p={0} borderRadius={8}>
          <PopoverBody h="auto" maxW="100vw" p={0} w="450px" overflow="hidden">
            <ReportDeliveryContent onClose={onClose} />
          </PopoverBody>
          <PopoverArrow bg={colors.green.hex} />
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
