import { Flex, Text } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { ProfileContext } from '../../../../contexts/ProfileContext'

export const PronounsView = () => {
  const { user } = useContext(ProfileContext)
  const { pronouns } = user || {}
  if (!pronouns) return null
  return (
    <Flex py={1} px={2} bg="whiteAlpha.400" borderRadius={4} flexFlow="column">
      <Text fontSize="sm" lineHeight={1} fontWeight={600} color="gray.600">
        Pronouns
      </Text>
      <Text lineHeight={1} color="gray.800" fontSize="md">
        {pronouns}
      </Text>
    </Flex>
  )
}

export const AlternatePronounsView = () => {
  const { user } = useContext(ProfileContext)
  const { pronouns } = user || {}
  if (!pronouns) return null
  return (
    <Flex align="center" gap={1.5}>
      <Text lineHeight={1} fontWeight={500} color="gray.600">
        Pronouns
      </Text>
      <Text lineHeight={1} fontSize="md">
        {pronouns}
      </Text>
    </Flex>
  )
}
