import { V1ProfileTabName } from './types'

export const v1adminProfileTabNames: Array<V1ProfileTabName> = [
  'patient',
  'pregnancy',
  'payments',
  'auth',
  'claims',
  'files',
  'visits',
]

export const v1practiceProfileTabNames: Array<V1ProfileTabName> = [
  'patient',
  'pregnancy',
  'payments',
  'files',
  'visits',
]
