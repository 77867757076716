import {
  Box,
  Flex,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  TextProps,
  VStack,
} from '@chakra-ui/react'
import { colors, getDateTimeString, ThreadMessage, ThreadType } from '@hb/shared'
import React, { useContext, useMemo } from 'react'
import read1Icon from '../../../icons/read-1.svg'
import read2Icon from '../../../icons/read-2.svg'
import { SvgIcon } from '../../../icons/SvgIcon'
import { UserPreview } from '../../Users/UserPreview'
import { ThreadViewContext } from './contexts'

const ReadByUserPreview = ({ userId, readOn }: { userId: string; readOn?: number }) => (
  <Flex align="center" pr={3} w="100%">
    <Box position="relative" minW="0" flex={1}>
      <UserPreview noEmail noWrap withProfilePicture size="xs" key={userId} userId={userId} />
    </Box>
    {readOn ? (
      <Text ml="auto" fontSize="xs" fontWeight={600} color="gray.500">
        {getDateTimeString(readOn, 'short')}
      </Text>
    ) : null}
  </Flex>
)

const ReadByLabel = (props: TextProps) => (
  <Text
    textAlign="center"
    px={2}
    py={1}
    bg="gray.100"
    w="100%"
    borderBottom="1px solid #cdcdcd"
    lineHeight={1}
    fontSize="sm"
    fontWeight={600}
    {...props}
  />
)

export const ReadByPopover = ({ message, type }: { message: ThreadMessage; type: ThreadType }) => {
  const { readBy, editedOn, editedReadBy, createdBy, readOn } = message || {}

  const { thread } = useContext(ThreadViewContext)
  const { subscribers = [] } = thread || {}
  // const relevantReadBy = useMemo(
  //   () => (readBy || []).filter((s) => s !== senderId),
  //   [readBy, senderId],
  // )
  // const readByAll = useMemo(
  //   () => readBy?.length === subscribers?.length,
  //   [readBy, subscribers],
  // )

  const readByAll = useMemo(
    () => !!readBy && readBy.length === subscribers.length - 1,
    [readBy, subscribers],
  )

  const usedReadBy = useMemo(
    () => (editedOn ? editedReadBy : readBy),
    [editedOn, editedReadBy, readBy],
  )

  const editsReadByAll = useMemo(
    () => !!editedReadBy && editedReadBy.length === subscribers.length - 1,
    [editedReadBy, subscribers],
  )

  const unreadBy = useMemo(
    () => subscribers.filter(s => !(readBy || []).includes(s) && s !== createdBy),
    [readBy, subscribers, createdBy],
  )

  const editsUnreadBy = useMemo(
    () =>
      subscribers.filter(
        s => !unreadBy.includes(s) && !(editedReadBy || []).includes(s) && s !== createdBy,
      ),
    [subscribers, createdBy, editedReadBy, unreadBy],
  )

  return (
    <Popover placement="bottom" strategy="fixed" trigger="hover" isLazy>
      <PopoverTrigger>
        <IconButton
          aria-label="Read By"
          variant="unstyled"
          w="18px"
          h="auto"
          minW="0"
          minH="0"
          color="gray.500"
          p={0}
          _hover={{
            color: 'gray.600',
          }}
          icon={
            <div
              style={{
                overflow: 'hidden',
                position: 'relative',
                width: '20px',
                minWidth: '20px',
                display: 'flex',
                alignItems: 'center',
              }}>
              <SvgIcon
                filter={`grayscale(${readByAll ? 0 : 1})`}
                src={read1Icon}
                w="20px"
                h="auto"
                color="gray.500"
              />
              {editedOn ? (
                <SvgIcon
                  src={read2Icon}
                  filter={`grayscale(${editsReadByAll ? 0 : 1})`}
                  position="absolute"
                  w="20px"
                  left={0}
                  h="auto"
                  color="gray.500"
                />
              ) : null}
            </div>
          }
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          bg="gray.50"
          borderRadius="12px"
          overflowY="auto"
          overflowX="hidden"
          border="none"
          maxH="300px"
          filter="drop-shadow(1px 1px 4px #00000066)"
          w="300px">
          <VStack spacing={0} w="100%">
            {type === 'assessment' || (usedReadBy && usedReadBy.length) ? (
              <>
                <ReadByLabel color={colors.blue.hex}>READ BY</ReadByLabel>
                <Flex flexFlow="column" w="100%">
                  {usedReadBy?.length ? (
                    usedReadBy.map(userId => (
                      <ReadByUserPreview readOn={readOn?.[userId]} key={userId} userId={userId} />
                    ))
                  ) : (
                    <Text w="100%" textAlign="center" opacity={0.7} fontStyle="italic">
                      None
                    </Text>
                  )}
                </Flex>
              </>
            ) : null}
            {type === 'assessment' || readByAll ? null : (
              <Flex w="100%" borderTop="1px solid #cdcdcd" flexFlow="column">
                <ReadByLabel color="gray.600">DELIVERED TO</ReadByLabel>
                {unreadBy ? (
                  <Flex flexFlow="column" w="100%">
                    {unreadBy.map(userId => (
                      <ReadByUserPreview key={userId} userId={userId} />
                    ))}
                  </Flex>
                ) : (
                  <Text fontSize="sm" color="gray.500">
                    Unavailable for older messages
                  </Text>
                )}
              </Flex>
            )}
            {type === 'assessment' || !editedOn || !editsUnreadBy.length ? null : (
              <>
                <ReadByLabel color="gray.600">EDITS UNREAD BY</ReadByLabel>
                <Flex flexFlow="column" w="100%">
                  {editsUnreadBy ? (
                    editsUnreadBy.map(userId => <ReadByUserPreview key={userId} userId={userId} />)
                  ) : (
                    <Text fontSize="sm" color="gray.500">
                      Unavailable for older messages
                    </Text>
                  )}
                </Flex>
              </>
            )}
          </VStack>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
