import {
  addNDaysTo,
  arrayToObject,
  Field,
  FieldMap,
  FieldTypes,
  getCorrectedValue,
  objectToArray,
  PaymentDue,
} from '@hb/shared'
import { writeBatch } from 'firebase/firestore'
import React, { useCallback, useContext, useMemo } from 'react'
import { db } from '../../../../../backend/db'
import { ProfileContext } from '../../../../../contexts/ProfileContext'
import { GenericEditModal } from '../../../../DataView/GenericEditModal'
import { PaymentsContext } from '../PaymentsProvider'

const getPaymentChildren = (edd?: number): Record<string, Field> => ({
  type: {
    placeholder: 'Payment Type',
    type: FieldTypes.DROPDOWN,
    selectInput: true,
    options: [
      { id: 'deposit', text: 'Deposit' },
      { id: 'home-birth-retainer', text: 'Birth Assistant Retainer' },
      { id: 'retainer', text: 'Retainer' },
      { id: 'other', text: 'Other (see notes)' },
    ],
  },
  amount: {
    type: FieldTypes.DOLLAR_AMOUNT,
    placeholder: 'Amount',
    min: 0,
  },
  dueDate: {
    type: FieldTypes.DATE,
    placeholder: 'Due Date',
    nativeInput: true,
    presets: edd
      ? [
          // 28 weeks
          { title: '28w', data: addNDaysTo(edd, -12 * 7) },
          // 32 weeks
          { title: '32w', data: addNDaysTo(edd, -8 * 7) },
          // 36 weeks
          { title: '36w', data: addNDaysTo(edd, -4 * 7) },
        ]
      : undefined,
  },
  notes: {
    type: FieldTypes.TEXTAREA,
    placeholder: 'Notes...',
    optional: true,
  },
})
const getPaymentsField = (edd?: number): FieldMap => ({
  name: 'Payments',
  children: {
    prm: {
      type: FieldTypes.DOLLAR_AMOUNT,
      placeholder: 'PRM',
      optional: true,
    },
    due: {
      name: 'Payments Due',
      displayedField: 'dueDate',
      optional: true,
      itemName: 'Payment',
      itemFields: {
        initExpanded: true,
        name: 'Payment Due',
        children: getPaymentChildren(edd),
      },
    },
  },
})

export const PaymentsDueModal = () => {
  const {
    selectedAssessment: { populated: selectedAssessment, adminRef },
  } = useContext(ProfileContext)
  const {
    payments,
    prm,
    modal: { isOpen, onClose },
  } = useContext(PaymentsContext)

  const edd = useMemo(
    () => getCorrectedValue(selectedAssessment, 'delivery.edd'),
    [selectedAssessment],
  )
  const paymentsField = useMemo(() => getPaymentsField(edd), [edd])
  const onSubmit = useCallback(
    async ({ prm: updatedPrm, due }: { prm?: number; due?: Array<PaymentDue> }) => {
      if (!adminRef) return { error: 'internal error' }
      if (adminRef) {
        const batch = writeBatch(db)
        batch.update(adminRef, 'payments.due', arrayToObject(due || [], 'createdOn', 'rank'))
        if (updatedPrm) batch.update(adminRef, 'payments.prm', updatedPrm)
        return batch
          .commit()
          .then(() => ({ success: 'Updated payments' }))
          .catch(() => ({ error: 'Internal error' }))
      }
      return { error: 'Internal error' }
    },
    [adminRef],
  )

  const data = useMemo(
    () => ({
      prm,
      due: objectToArray(payments?.due || {}, 'createdOn', 'rank'),
    }),
    [prm, payments],
  )

  return (
    <GenericEditModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      data={data}
      field={paymentsField}
    />
  )
}
