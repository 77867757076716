import { Flex, HStack, StackDivider, VStack } from '@chakra-ui/react'
import { colors } from '@hb/shared'

import React, { useContext } from 'react'

import { AppContext } from '../../../../contexts/AppContext'
import { ProfileContext } from '../../../../contexts/ProfileContext'
import { useCreateAssessment } from '../../../../hooks/backend/assessments'
import { useUpdateDoc } from '../../../../hooks/backend/useUpdateDoc'
import { SolidActionButton } from '../../../Buttons'
import { NoteForm } from '../../../forms/NoteForm'
import { Subheader } from '../../Text'
import { AddAssessmentAlert } from '../Assessments/AddAssessmentAlert'
import { PatientConsentForms } from '../ConsentForms/PatientConsentForms'
import { DraftView } from '../Documents/DraftView'
import { PatientContact } from './PatientContact'
import { PatientPregnancySummary } from './PatientPregnancySummary'

const PregnancySummaryBox = () => {
  return (
    <VStack
      spacing={0}
      px={2}
      py={3}
      boxShadow="1px 1px 3px #00000077"
      w="100%"
      bg="white"
      align="flex-start"
      borderRadius={6}>
      <PatientPregnancySummary />
    </VStack>
  )
}

export const PatientSummary: React.FC = () => {
  const {
    user,
    adminPatientRef,
    practicePatientRef,
    selectedAssessment: { populated: selectedAssessment },
  } = useContext(ProfileContext)
  const { patientNote, drafts } = user || {}

  const { appName } = useContext(AppContext)
  const update = useUpdateDoc('patient')

  const { addLoading, confirmingNewAssessment, createAssessment, setConfirmingNewAssessment } =
    useCreateAssessment()

  return (
    <VStack spacing={2} px={4} width="100%">
      <Flex
        px={3}
        flexFlow="column"
        borderRadius={6}
        pt={1}
        w="100%"
        boxShadow="1px 1px 3px #00000077"
        bg="white">
        <HStack>
          <Subheader py={1}>Patient Info</Subheader>
          <SolidActionButton
            onClick={() => setConfirmingNewAssessment(true)}
            isLoading={addLoading}
            bg={colors.green.hex}
            color="white"
            size="xs"
            fontFamily="Comfortaa"
            ml="auto">
            + NEW PREGNANCY
          </SolidActionButton>
        </HStack>
        <VStack
          divider={<StackDivider borderColor="blackAlpha.300" />}
          spacing={1}
          align="flex-start"
          w="100%"
          py={2}
          px={4}
          bg={`${colors.pink.hex}66`}
          boxShadow="inset 0 0 4px #333">
          <PatientContact />
        </VStack>
        <NoteForm
          note={patientNote}
          boxProps={{ background: 'transparent', boxShadow: 'none' }}
          placeholder="Patient notes"
          onSubmit={data =>
            update(appName === 'app' ? adminPatientRef : practicePatientRef, 'patientNote', data)
          }
        />
      </Flex>
      {selectedAssessment ? <PregnancySummaryBox /> : null}
      {user?.id ? (
        <DraftView
          templateKey="consentForm"
          draft={drafts?.consentFormTemplates}
          name="Consent forms"
        />
      ) : null}
      {user?.id ? <PatientConsentForms adminView patientId={user.id} /> : null}
      <AddAssessmentAlert
        onConfirm={createAssessment}
        isOpen={confirmingNewAssessment}
        onCancel={() => setConfirmingNewAssessment(false)}
      />
    </VStack>
  )
}
