import { AppName } from '@hb/shared'

const claimPaths = [
  '/claims',
  '/claims/:claimId',
  '/claims/:claimId/:displayedItem',
  '/claims/:claimId/:displayedItem/:subItem',
]

export const pregnancyPaths = [
  '/pregnancies',
  '/pregnancies/:pregnancyId',
  '/pregnancies/:pregnancyId/:displayedItem',
  '/pregnancies/:pregnancyId/:displayedItem/:subItem',
]

const patientPaths = [
  '/patients',
  '/patients/:patientId',
  '/patients/:patientId/:displayedItem',
  '/patients/:patientId/:displayedItem/:subItem',
  ...pregnancyPaths.map(path => `/patients/:patientId${path}`),
]

export const getDashboardPaths = (appName: AppName) => {
  const basePath = appName === 'app' ? '/admin' : ''
  const paths: string[] = []
  if (basePath) paths.push(basePath)

  pregnancyPaths.forEach(path => {
    paths.push(`${basePath}${path}`)
  })
  if (appName === 'app')
    claimPaths.forEach(path => {
      paths.push(`${basePath}${path}`)
    })
  patientPaths.forEach(path => {
    paths.push(`${basePath}${path}`)
  })
  return paths
}
