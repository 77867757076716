import {
  AnyObject,
  AppCollectionDataTypes,
  AppCollectionsState,
  AppName,
  Collection,
  CollectionId,
  CollectionItem,
  CollectionState,
  DBMetadata,
  DynamicDropdownField,
  Field,
  fieldFormat,
  FieldMapValue,
  FieldTypes,
  formatDropdownValue,
  getCollectionId,
  getDateString,
  getDynamicDropdownOption,
  getDynamicDropdownValue,
  notUndefinedOrNull,
  timeStringFrom24Hour,
} from '@hb/shared'
import React from 'react'
import { CopyTooltip } from '../components/CopyTooltip'
import { useAuth } from '../store/auth'

const getCollectionItem = (
  collections: AppCollectionsState,
  collection: Collection<any>,
  id: string,
) => {
  const collectionId = getCollectionId(collection)
  const collectionState = collections[collectionId] as CollectionState<
    AppCollectionDataTypes[CollectionId]
  >
  if (!collectionState) throw new Error(`Collection ${collectionId} not found`)
  return collectionState.items.find(item => item.id === id)
}

const formatIdField = <T extends AnyObject>(
  value: string,
  collections: AppCollectionsState,
  field: T,
) => {
  const collectionItem = getCollectionItem(collections, field.collection, value)
  return collectionItem?.name || 'Could not find item'
}

export const formatValue = ({
  field,
  value,
  collections,
  textOnly,
}: {
  field?: Field
  value?: any
  collections?: AppCollectionsState
  textOnly?: boolean
}) => {
  if (field?.format) return field.format(value)
  switch (field?.type) {
    case FieldTypes.ID:
      if (!value) return 'None'
      if (!collections) return 'Could not find item'
      if (!collections[getCollectionId(field.collection)]) return 'Could not find item'
      return formatIdField(value, collections, field)

    case FieldTypes.DYNAMIC_DROPDOWN:
      return getDynamicDropdownValue({
        value,
        dynamicDropdownOptions: getDynamicDropdownOptions(
          collections || {},
          field,
          value,
          useAuth.getState().admin,
        ),
      })
    case FieldTypes.DROPDOWN:
      return formatDropdownValue(value, field.options)
    case FieldTypes.DATE:
      if (!value) return 'None'
      return getDateString(value, 'short')
    case FieldTypes.TIME:
      if (value === undefined || typeof value !== 'string') return 'None'
      return timeStringFrom24Hour(value.split('-')[0])
    case FieldTypes.ALTERNATE:
      if (value === undefined) return 'None'
      if (typeof value !== 'object') return value
      return textOnly ? (
        value?.main || 'None'
      ) : (
        <CopyTooltip
          prefix={`${field.altPrefix || 'Alt'}:`}
          label={value?.alternate || 'No alternate'}>
          {value?.main || 'None'}
        </CopyTooltip>
      )
    default:
      if (!notUndefinedOrNull(value)) return 'None'
      if (field?.type) {
        const format = fieldFormat[field.type]
        const formatted = format && format(value, field, collections || {})
        if (formatted) return typeof formatted === 'string' ? formatted : ''
      }
      return typeof value === 'string' ? value : ''
  }
}
export const getMetadata = (appName: AppName, isNew: boolean) => {
  const { admin, authUser, claims } = useAuth.getState()
  if (!authUser) throw new Error('Not logged in')

  const isPracticeMember = !!Object.keys(claims?.practiceAccess || {}).length
  let updatedByGroup: DBMetadata['createdByGroup'] = 'patient'
  if (admin && appName === 'app') {
    updatedByGroup = 'admin'
  } else if (isPracticeMember) {
    updatedByGroup = 'practice'
  }
  const metadata: Partial<DBMetadata> = {
    updatedBy: authUser.uid,
    updatedOn: Date.now(),
    updatedByGroup,
  }

  if (isNew) {
    metadata.createdBy = authUser.uid
    metadata.createdOn = Date.now()
    metadata.createdByGroup = updatedByGroup
  }

  return metadata as DBMetadata
}

export const getCreatedMetadata = (appName: AppName) => {
  const { authUser, claims, admin } = useAuth.getState()
  if (!authUser) throw new Error('Not logged in')

  const isPracticeMember = !!Object.keys(claims?.practiceAccess || {}).length
  let createdByGroup: DBMetadata['createdByGroup'] = 'patient'
  if (admin && appName === 'app') {
    createdByGroup = 'admin'
  } else if (isPracticeMember) {
    createdByGroup = 'practice'
  }

  return {
    createdBy: authUser.uid,
    createdOn: Date.now(),
    createdByGroup,
  }
}

export const addMetadata = <T extends AnyObject>(data: T, appName: AppName, isNew: boolean) => ({
  ...data,
  ...getMetadata(appName, isNew),
})

export const addCreatedMetadata = <T extends AnyObject>(data: T, appName: AppName) => ({
  ...data,
  ...getCreatedMetadata(appName),
})

export const xor = (a: any, b: any) => (a && !b) || (!a && b)

export const getDynamicDropdownOptions = (
  collections: AppCollectionsState,
  field: DynamicDropdownField<any>,
  rootValue?: FieldMapValue,
  userIsAdmin?: boolean,
) => {
  const { baseId, collection, listId } = field
  const itemId = baseId && rootValue ? rootValue[baseId] : undefined
  const collectionId = getCollectionId(collection)
  const collectionState = collections[collectionId] as CollectionState<
    AppCollectionDataTypes[CollectionId]
  >
  if (!collectionState) return []
  const item = collectionState.items.find(i => i.id === itemId)

  if (item && listId) {
    const indexable = item as { [key: string]: any }
    if (indexable[listId]) {
      if (indexable[listId] instanceof Array) {
        return indexable[listId].reduce(
          (acc: Array<CollectionItem<any>>, listItem: CollectionItem<any>) => {
            if (!listItem.isInactive || userIsAdmin) {
              return [...acc, getDynamicDropdownOption(listItem)]
            }
            return acc
          },
          [] as Array<CollectionItem<any>>,
        )
      }

      console.error('Dynamic dropdown options is not an array')
    }
  }
  return []
}
