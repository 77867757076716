import { CheckIcon, MinusIcon } from '@chakra-ui/icons'
import {
  Badge,
  Button,
  Center,
  CircularProgress,
  Divider,
  Flex,
  FlexProps,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  AdminAssessmentData,
  AssessmentStage,
  assessmentStageKeys,
  colors,
  getDateString,
  PracticeAssessmentData,
  PracticeAssessmentStage,
  practiceAssessmentStageKeys,
  UpdateCallback,
  UpdatePracticeAssessmentArchivedArgs,
} from '@hb/shared'
import React, { useCallback, useContext, useMemo, useState } from 'react'

import {
  arrayRemove,
  arrayUnion,
  DocumentData,
  DocumentReference,
  updateDoc,
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { functions } from '../../../../backend/functions'
import { PopUpMessageContext } from '../../../../contexts'
import { useApp } from '../../../../contexts/AppContext'
import { ProfileContext } from '../../../../contexts/ProfileContext'
import { useMe } from '../../../../hooks/backend/useMe'

const AssessmentStatusButton = ({
  mode,
  stages,
  onClick,
  id,
  hovered,
  selectedIds,
  onHover,
  onBlur,
}: {
  mode: 'add' | 'move' | null
  id: AssessmentStage | PracticeAssessmentStage
  stages?: Array<AssessmentStage | PracticeAssessmentStage>
  onClick: () => void
  hovered: boolean
  selectedIds: Array<AssessmentStage | PracticeAssessmentStage>
  onHover: () => void
  onBlur: () => void
}) => {
  const { selected, active } = useMemo(
    () => ({
      selected: selectedIds?.includes(id),
      active: stages?.includes(id),
    }),
    [stages, selectedIds, id],
  )
  const pointerEvents = useMemo(() => {
    if (mode && active) return 'none'
    if (mode) return 'auto'
    return active ? 'auto' : 'none'
  }, [active, mode])

  return (
    <MenuItem
      zIndex={1}
      py={2}
      color="gray.600"
      pointerEvents={pointerEvents}
      onClick={onClick}
      closeOnSelect={false}
      fontWeight={600}
      background={hovered && (mode || active) ? 'gray.50' : 'white'}
      px={4}
      onPointerEnter={onHover}
      onPointerLeave={onBlur}>
      {id}
      <Center
        borderRadius={mode === 'move' ? 'full' : 4}
        ml="auto"
        bg={active && mode ? '#efefef' : 'transparent'}
        w={6}
        h={6}
        border={`1px solid ${mode ? '#cdcdcd' : 'transparent'}`}>
        {active || selected ? (
          <CheckIcon
            color={
              mode === 'move' && selectedIds.length && !selected ? 'gray.300' : colors.green.hex
            }
            display={hovered ? 'none' : 'flex'}
            fontSize="0.8rem"
          />
        ) : null}
        {active || selected ? (
          <MinusIcon color="red.600" display={hovered ? 'flex' : 'none'} fontSize="0.8rem" />
        ) : null}
      </Center>
    </MenuItem>
  )
}

export const PregnancyStatusDropdown = ({
  isMobile,
  ...props
}: FlexProps & { isMobile?: boolean }) => {
  const { selectedAssessment, assessmentId } = useContext(ProfileContext)
  const { appName } = useApp()
  const me = useMe()
  const { adminRef, ref, practiceRef, practiceData, assessmentData, populated } =
    selectedAssessment || {}
  const stages = populated?.stages as Array<AssessmentStage | PracticeAssessmentStage>
  const { midwifeId } = populated || {}
  const [hovered, setHovered] = useState<null | AssessmentStage | PracticeAssessmentStage>(null)
  const [mode, setMode] = useState<'add' | 'move' | null>(null)
  const [selectedIds, setSelectedIds] = useState<Array<AssessmentStage | PracticeAssessmentStage>>(
    [],
  )
  const { showError, showSuccess } = useContext(PopUpMessageContext)
  const [loadingIds, setLoadingIds] = useState<Array<AssessmentStage | PracticeAssessmentStage>>([])

  const handleCancel = useCallback(() => {
    setMode(null)
    setHovered(null)
    setSelectedIds([])
    setLoadingIds([])
  }, [])
  const archivedOn = useMemo(
    () => (appName === 'providers-app' ? practiceData?.archivedOn : assessmentData?.archivedOn),
    [appName, assessmentData, practiceData],
  )
  const [loadingArchived, setLoadingArchived] = useState(false)
  const handleArchivePractice = useCallback(() => {
    if (!me) return { error: 'no user' }
    if (!assessmentId) return { error: 'No assessment ID' }
    if (!midwifeId) return { error: 'No practice ID' }
    const updateFunc = httpsCallable<UpdatePracticeAssessmentArchivedArgs, UpdateCallback>(
      functions,
      'updatePracticeAssessmentArchived',
    )
    setLoadingArchived(true)
    return updateFunc({ assessmentId, archived: !archivedOn, practiceId: midwifeId })
      .then(() => {
        showSuccess(`Assessment ${archivedOn ? 'un' : ''}archived`)
      })
      .catch((e: any) => {
        console.error(e)
        showError(
          `Error ${archivedOn ? 'un' : ''}archiving assessment${e?.message ? `: ${e.message}` : ''}`,
        )
      })
      .finally(() => setLoadingArchived(false))
  }, [archivedOn, me, assessmentId, midwifeId, showError, showSuccess])

  const handleArchiveAdmin = useCallback(() => {
    if (!me) return { error: 'no user' }
    if (!ref) return { error: 'No assessment' }
    setLoadingArchived(true)
    return updateDoc(ref, {
      archivedOn: archivedOn ? null : Date.now(),
      archivedBy: archivedOn ? null : me.uid,
    })
      .then(() => showSuccess(`Assessment ${archivedOn ? 'un' : ''}archived`))
      .catch((e: any) => {
        console.error(e)
        showError('Error archiving assessment')
      })
      .finally(() => setLoadingArchived(false))
  }, [archivedOn, me, ref, showError, showSuccess])

  const handleArchiveClick = useCallback(() => {
    if (appName === 'app') return handleArchiveAdmin()
    return handleArchivePractice()
  }, [appName, handleArchiveAdmin, handleArchivePractice])

  const handleConfirm = useCallback(async () => {
    const updateRef:
      | DocumentReference<AdminAssessmentData>
      | DocumentReference<PracticeAssessmentData>
      | null
      | undefined = appName === 'app' ? adminRef : practiceRef
    if (!mode || !updateRef) return
    setLoadingIds([...selectedIds])
    try {
      if (mode === 'add') {
        await updateDoc<AdminAssessmentData | PracticeAssessmentData, DocumentData>(updateRef, {
          stages: arrayUnion(...selectedIds),
        })
        showSuccess('Added to list(s)')
      } else {
        await updateDoc<AdminAssessmentData | PracticeAssessmentData, DocumentData>(updateRef, {
          stages: selectedIds,
        })
        showSuccess('Moved to lists(s)')
      }
    } catch (err) {
      console.error(err)
      showError('Error updating assessment on lists')
    }

    setLoadingIds([])
    setSelectedIds([])
    setMode(null)
  }, [mode, selectedIds, adminRef, showSuccess, showError, appName, practiceRef])

  const handleItemClick = useCallback(
    async (id: AssessmentStage | PracticeAssessmentStage) => {
      const updateRef = appName === 'app' ? adminRef : practiceRef
      if (!updateRef) return
      if (!mode) {
        if (stages?.includes(id)) {
          if (stages.length < 2) {
            showError('Assessment must be on at least one list')
          } else {
            setLoadingIds([id])
            await updateDoc<AdminAssessmentData | PracticeAssessmentData, DocumentData>(updateRef, {
              stages: arrayRemove(id),
            })
            showSuccess(`Removed from ${id}`)
            setLoadingIds([])
          }
        }
        return
      }
      if (selectedIds.includes(id)) {
        setSelectedIds(selectedIds.filter(i => i !== id))
      } else if (mode === 'add') setSelectedIds([...selectedIds, id])
      else setSelectedIds([id])
    },
    [mode, selectedIds, showError, showSuccess, adminRef, practiceRef, stages, appName],
  )

  return (
    <Flex gap={2} align="center" {...props}>
      {/* <i>[Status Dropdown]</i> */}

      {archivedOn ? (
        <Badge colorScheme="red" fontSize="0.85rem">
          {isMobile ? '' : 'ARCHIVED '}
          {getDateString(archivedOn, 'short')}
        </Badge>
      ) : null}
      <Menu>
        <MenuButton
          as={Button}
          variant="outline"
          color="blackAlpha.700"
          bg="white"
          px={4}
          py={1}
          borderRadius="full"
          // boxShadow='0 0 4px rgba(0,0,0,0.5)'
          shadow="md">
          <Flex align="center" justify="center">
            <Text fontWeight={500} fontFamily="Hero-New" fontSize="sm" lineHeight={1}>
              {stages?.join(', ') || 'Not on any lists'}
            </Text>
          </Flex>
        </MenuButton>
        <Portal>
          <MenuList border="1px solid #cdcdcd" boxShadow="md" w="300px" py={0}>
            <VStack w="100%" divider={<Divider />} spacing={0}>
              <HStack w="100%" py={2} bg="gray.100" px={2} justify="space-between">
                {mode ? (
                  <>
                    <Button
                      isDisabled={!!loadingIds.length}
                      onClick={handleCancel}
                      bg="white"
                      size="sm"
                      variant="outline">
                      Cancel
                    </Button>
                    <Button
                      isLoading={!!loadingIds.length}
                      onClick={handleConfirm}
                      bg={colors.green.hex}
                      color="white"
                      size="sm"
                      variant="outline">
                      Save
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() => setMode('add')}
                      bg={colors.green.hex}
                      size="sm"
                      color="white">
                      Add to list(s)
                    </Button>
                    <Button
                      bg={colors.green.hex}
                      onClick={() => {
                        setMode('move')
                        setSelectedIds(stages || [])
                      }}
                      color="white"
                      size="sm">
                      Move to list
                    </Button>
                  </>
                )}
              </HStack>
              {(appName === 'app' ? assessmentStageKeys : practiceAssessmentStageKeys).map(k => (
                <AssessmentStatusButton
                  key={k}
                  id={k}
                  onBlur={() => setHovered(null)}
                  mode={mode}
                  hovered={hovered === k}
                  selectedIds={selectedIds}
                  onClick={() => handleItemClick(k)}
                  onHover={() => setHovered(k)}
                  stages={stages}
                />
              ))}
              <MenuItem
                fontSize="xs"
                fontWeight={500}
                closeOnSelect={false}
                px={4}
                py={3}
                bg="#efefef"
                color={'red.600'}
                onClick={handleArchiveClick}>
                {archivedOn ? `ARCHIVED ${getDateString(archivedOn!, 'short')}` : 'ARCHIVE'}
                <CircularProgress
                  ml={2}
                  opacity={loadingArchived ? 1 : 0}
                  transition="300ms"
                  color="gray"
                  isIndeterminate={loadingArchived}
                  size={4}
                />
                {archivedOn ? <CheckIcon ml="auto" /> : null}
              </MenuItem>
              {appName === 'app' && practiceData?.archivedOn ? (
                <MenuItem
                  fontSize="xs"
                  fontWeight={500}
                  closeOnSelect={false}
                  px={3}
                  py={1}
                  bg="gray.50"
                  color={'red.600'}>
                  {`ARCHIVED BY PROVIDER ${getDateString(practiceData.archivedOn, 'short')}`}
                </MenuItem>
              ) : null}
            </VStack>
          </MenuList>
        </Portal>
      </Menu>
    </Flex>
  )
}
