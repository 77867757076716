import { Box, Flex } from '@chakra-ui/react'
import React, { useRef } from 'react'
import { useProfile } from '../../../../../../contexts'
import { Size, useResizeObserver } from '../../../../../../hooks/useResizeObserver'
import { AssessmentModal } from '../../../../../Assessments/AssessmentModal/AssessmentModal'
import { InsuranceCardsView } from '../../../../../Assessments/InsuranceCardsView'
import { AssessmentAuthorizationsTab } from '../../../Authorizations/AssessmentAuthorizations'
import { ProfileVisits } from '../../../Charting/Visits'
import { ProfileConsentForms } from '../../../ConsentForms/ProfileConsentForms'
import { AssessmentLog } from '../../../Log/AssessmentLog'
import { Resizable } from '../../../Log/Resizable'
import { AssessmentPaymentsTab } from '../../../Payments'
import { isPatientTab, isPregnancyTab, PatientTabName, PregnancyTabName } from '../../../types'
import { AssessmentHeaderRow } from '../../V1/AssessmentHeader'
import { PatientControlPanel } from '../Patient/PatientControlPanel'
import { PregnancyControlPanel } from '../Pregnancy/PregnancyControlPanel'

const PregnancyContentBody = ({ subTab }: { subTab: PregnancyTabName | null }) => {
  if (!subTab || !isPregnancyTab(subTab)) return <PregnancyControlPanel />

  switch (subTab) {
    case 'plans':
      return <InsuranceCardsView />
    case 'payments':
      return <AssessmentPaymentsTab />
    case 'authorizations':
      return <AssessmentAuthorizationsTab />
    default:
      return <PregnancyControlPanel />
  }
}

const PregnancyContent = ({ subTab, size }: { subTab: PregnancyTabName | null; size: Size }) => {
  const { selectedAssessment, user, onTabSelect } = useProfile()
  const bodyRef = useRef<HTMLDivElement>(null)
  const { width, height } = useResizeObserver(bodyRef, 'content')
  return (
    <Flex w="100%" h="100%" flexFlow="column">
      <AssessmentHeaderRow width={size.width} />
      <Flex ref={bodyRef} flex={1} w="100%" minH="0">
        <Resizable
          width={width}
          height={height}
          localStorageId="user-profile-resize"
          leftChild={() => (
            <Box bg="#efefef" w="100%" h="100%" overflowY="auto">
              <PregnancyContentBody subTab={subTab} />
            </Box>
          )}
          rightChild={() => <AssessmentLog maxHeight={size.height} />}
        />
      </Flex>
      {user && selectedAssessment.populated && subTab === 'assessment' ? (
        <AssessmentModal
          assessment={selectedAssessment.populated}
          isOpen={subTab === 'assessment'}
          onClose={() => onTabSelect('pregnancy', null)}
          user={user}
        />
      ) : null}
    </Flex>
  )
}

const PatientContent = ({ subTab }: { subTab: PatientTabName | null }) => {
  if (!subTab || !isPatientTab(subTab)) return <PatientControlPanel />
  switch (subTab) {
    case 'consent-forms':
      return <ProfileConsentForms />
    case 'visits':
      return <ProfileVisits onlyStandaloneVisits={false} assessmentId={null} />
    default:
      return <PatientControlPanel />
  }
}

export const BodyContent = ({ size }: { size: Size }) => {
  const { tabName, subTab, assessmentId } = useProfile()
  if (!tabName && assessmentId)
    return <PregnancyContent size={size} subTab={subTab as PregnancyTabName | null} />
  switch (tabName) {
    case 'pregnancy':
      if (assessmentId)
        return <PregnancyContent size={size} subTab={subTab as PregnancyTabName | null} />
      break
    case 'consent-forms':
      return <ProfileConsentForms />
    default:
      return <PatientContent subTab={subTab as PatientTabName | null} />
  }
  return <PatientContent subTab={null} />
}
