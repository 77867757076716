import { Collapse, Flex, Text } from '@chakra-ui/react'
import {
  capitalizeFirstLetter,
  DropdownField,
  FieldTypes,
  getHasMultiplePlans,
  getInsurancePlan,
  InsuranceCoverageId,
  InsurancePlans,
  medicaidProvidersCollection,
  PopulatedAssessment,
  providersCollection,
} from '@hb/shared'
import React, { useMemo } from 'react'
import { useField } from 'react-final-form'
import { useCollectionItem } from '../../../../../../hooks/backend/useCollectionItem'
import { FormElement } from '../../../../../forms'
import { useInsurancePlanField } from '../../../Assessments/hooks'
import { OtherCoverageDetails } from './OtherCoverageDetails'
import { SubmitClaimMainCoverageDetails } from './PrimaryCoverage'

export const SelectInsurer = ({ assessment }: { assessment: PopulatedAssessment | null }) => {
  const { authorizations, plans } = assessment || {}

  const {
    input: { value: mainCoverageId },
  } = useField<InsuranceCoverageId>('mainCoverageId', {
    subscription: { value: true },
  })
  const {
    input: { value: otherCoverageId },
  } = useField('otherCoverageId', { subscription: { value: true } })

  const otherCoverage = useMemo(
    () => (plans ? getInsurancePlan(plans, otherCoverageId) : null),
    [otherCoverageId, plans],
  )

  const mainCoverage = useMemo(
    () => (plans ? getInsurancePlan(plans, mainCoverageId) : null),
    [mainCoverageId, plans],
  )

  const { item: mainInsurer } = useCollectionItem(
    providersCollection,
    mainCoverage?.insuranceProviderId,
  )

  const { item: otherInsurer } = useCollectionItem(
    otherCoverage?.isMedicaid ? medicaidProvidersCollection : providersCollection,
    otherCoverage?.insuranceProviderId,
  )

  const hasMultiplePlans = useMemo(() => getHasMultiplePlans(plans || {}), [plans])

  const field = useInsurancePlanField(plans)
  const nonSelectedPlans = useMemo<Partial<InsurancePlans>>(() => {
    if (!hasMultiplePlans) return {}
    if (!mainCoverageId) return {}

    const hasMedicaidInsurer = !!plans?.medicaidCoverage?.insuranceProviderId
    const hasPrimaryInsurer = !!plans?.primaryCoverage?.insuranceProviderId

    const otherPlans: Partial<InsurancePlans> = {}

    const addPrimaryCoverage = () => {
      if (hasPrimaryInsurer) otherPlans.primaryCoverage = plans?.primaryCoverage
    }
    const addMedicaidCoverage = () => {
      if (hasMedicaidInsurer) otherPlans.medicaidCoverage = plans?.medicaidCoverage
    }

    if (mainCoverageId === 'primaryCoverage') {
      otherPlans.additionalPlans = plans?.additionalPlans
      addMedicaidCoverage()
    } else if (mainCoverageId === 'medicaidCoverage') {
      otherPlans.additionalPlans = plans?.additionalPlans
      addPrimaryCoverage()
    } else if (mainCoverageId.startsWith('additionalPlans')) {
      const { [mainCoverageId.split('.')[1]]: _, ...additionalPlans } = plans?.additionalPlans || {}
      otherPlans.additionalPlans = additionalPlans
      addPrimaryCoverage()
      addMedicaidCoverage()
    }

    return otherPlans
  }, [mainCoverageId, plans, hasMultiplePlans])

  const otherPlanField = useInsurancePlanField(nonSelectedPlans)

  const authorizationsField = useMemo<DropdownField>(
    () => ({
      placeholder: 'Authorization',
      type: FieldTypes.DROPDOWN,
      optional: true,
      options:
        Object.values(authorizations || {}).map(a => ({
          text: `${a.referenceNumber} - ${capitalizeFirstLetter(a.serviceType)}`,
          id: a.referenceNumber,
        })) || [],
    }),
    [authorizations],
  )

  return (
    <Flex
      border="1px solid #cdcdcd"
      borderRadius={4}
      align="flex-start"
      bg="white"
      boxShadow="md"
      w="100%"
      px={3}
      my={2}
      flexFlow="column"
      py={2}>
      <Text py={1} fontWeight={600} color="gray.500" fontSize="md">
        Insurance Plan
      </Text>
      <FormElement field={field} name="mainCoverageId" />
      <Collapse style={{ width: '100%' }} in={!!mainCoverageId}>
        {!mainInsurer?.abilityId ? (
          <Text px={2} color="red.600" fontSize="md">
            Insurance provider is missing Payer ID (Insurers Page)
          </Text>
        ) : (
          <Text px={2}>Payer ID: {mainInsurer?.abilityId || 'None'}</Text>
        )}
      </Collapse>
      <SubmitClaimMainCoverageDetails
        insuranceProvider={mainInsurer}
        coverage={mainCoverage}
        authorizationsField={authorizationsField}
      />
      <Text py={1} fontWeight={600} color="gray.500" fontSize="md">
        Additional Insurance Plan
      </Text>
      {hasMultiplePlans ? (
        <>
          <FormElement name="otherCoverageId" field={otherPlanField} />
          <Collapse style={{ width: '100%' }} in={!!otherCoverageId}>
            {!otherInsurer?.abilityId ? (
              <Text px={2} color="red.600" fontSize="md">
                Insurance provider is missing Payer ID (Insurers Page)
              </Text>
            ) : (
              <Text px={2}>Payer ID: {otherInsurer?.abilityId || 'None'}</Text>
            )}
          </Collapse>
          <OtherCoverageDetails
            insuranceProvider={otherInsurer}
            coverage={otherCoverage || null}
            authorizationsField={authorizationsField}
          />
        </>
      ) : null}
    </Flex>
  )
}
