import { PopulatedUser } from '@hb/shared'
import { createContext } from 'react'

export type VisitsContextData = {
  selectedVisit: { id: string; isDraft: boolean } | null
  onSelect: (id: string, isDraft: boolean) => void
  patient: PopulatedUser | null
  patientId: string | null
}
export const VisitsContext = createContext<VisitsContextData>({
  selectedVisit: null,
  onSelect: () => {},
  patient: null,
  patientId: null,
})
