import { PopoverProps, Text, VStack } from '@chakra-ui/react'
import { Alert, UpdateCallback, UserGroup } from '@hb/shared'
import React, { useMemo } from 'react'
import { AddAlertPopover } from './AddAlertPopover'
import { AlertEdit } from './AlertEdit'

export const AlertsView = ({
  alerts,
  accessLevel,
  type,
  onDelete,
  onSubmit,
  popoverPlacement,
}: {
  alerts?: Record<number, string | Alert>
  accessLevel?: UserGroup
  type: 'assessment' | 'patient'
  onDelete: (key: string) => Promise<UpdateCallback>
  onSubmit: (key: string, value: any) => Promise<UpdateCallback>
  popoverPlacement?: PopoverProps['placement']
}) => {
  const sorted = useMemo(
    () => Object.keys(alerts || {}).sort((a, b) => parseInt(a, 10) - parseInt(b, 10)),
    [alerts],
  )
  return (
    <VStack w="100%" spacing={1}>
      {alerts ? (
        sorted.map(key => (
          <AlertEdit
            id={key}
            key={key}
            onDelete={() => onDelete(key)}
            onSubmit={data => onSubmit(key, data)}
            alert={alerts[parseInt(key, 10)]}
          />
        ))
      ) : (
        <Text width="100%" color="gray.600">
          No alerts
        </Text>
      )}
      {/* <AddItem
        placeholder="Alert description"
        itemName="Alert"
        onAdd={(value) => onSubmit(`${Date.now()}`, { text: value })}
      /> */}
      <AddAlertPopover placement={popoverPlacement} type={type} accessLevel={accessLevel} inList />
    </VStack>
  )
}
