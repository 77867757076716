import { FileTemplateAttachmentPlacement, TemplateAttachment } from '@hb/shared'
import React, { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react'

export type PdfViewContextValue = {
  scrollPageIndex: number
  onScrollPage: (index: number) => void
  numPages: number | null
  updateNumPages: (num: number | null) => void
  attachments: Record<string, TemplateAttachment<FileTemplateAttachmentPlacement>>
}

const PdfViewContext = createContext<PdfViewContextValue>({
  scrollPageIndex: 0,
  attachments: {},
  onScrollPage: () => {},
  numPages: null,
  updateNumPages: () => {},
})

export const PdfViewProvider = ({
  children,
  attachments,
}: PropsWithChildren<{
  attachments?: Record<string, TemplateAttachment<FileTemplateAttachmentPlacement>>
}>) => {
  const [scrollPageIndex, setScrollPageIndex] = useState(0)
  const [numPages, setNumPages] = useState<number | null>(null)
  const value = useMemo<PdfViewContextValue>(
    () => ({
      scrollPageIndex,
      onScrollPage: setScrollPageIndex,
      attachments: attachments || {},
      numPages,
      updateNumPages: n => setNumPages(n),
    }),
    [scrollPageIndex, numPages, attachments],
  )
  return <PdfViewContext.Provider value={value}>{children}</PdfViewContext.Provider>
}

export const usePdfView = () => useContext(PdfViewContext)
