import { NotificationTopic, UserSettings } from '@hb/shared'
import { useMemo } from 'react'
import { UseDocument } from '../../../types'
import { defaultNotificationSound, notificationSounds } from '../constants'

export const useMyMessageSound = (
  settings: UseDocument<UserSettings>,
  topic: NotificationTopic,
) => {
  const { data: settingsData } = settings || {}
  const customValue = useMemo(
    () => settingsData?.notifications?.sounds?.[topic],
    [settingsData, topic],
  )

  return useMemo(() => {
    if (!customValue) return notificationSounds[defaultNotificationSound]
    if (!notificationSounds[customValue]) return notificationSounds[defaultNotificationSound]
    return notificationSounds[customValue]
  }, [customValue])
}
