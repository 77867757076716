import { Box } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { AppContext } from '../../../../../../contexts/AppContext'
import { ProfileContext } from '../../../../../../contexts/ProfileContext'
import { useUpdateDoc } from '../../../../../../hooks/backend/useUpdateDoc'
import { NoteForm } from '../../../../../forms/NoteForm'

export const PatientNotes = () => {
  const { user, adminPatientRef, practicePatientRef } = useContext(ProfileContext)
  const { patientNote } = user || {}

  const { appName } = useContext(AppContext)
  const update = useUpdateDoc('patient')

  return (
    <Box w="100%" pt={1}>
      <NoteForm
        note={patientNote}
        boxProps={{ background: 'transparent', boxShadow: 'none', p: 0 }}
        placeholder="Patient notes"
        onSubmit={data =>
          update(appName === 'app' ? adminPatientRef : practicePatientRef, 'patientNote', data)
        }
      />
    </Box>
  )
}
