import { useMemo } from 'react'
import { useProfile } from '../../../../contexts'
import { useApp } from '../../../../contexts/AppContext'
import { v1adminProfileTabNames, v1practiceProfileTabNames } from '../constants'
import { V1ProfileTabName } from '../types'

export const useV1ProfileTabs = () => {
  const { appName } = useApp()
  const { assessmentId, assessments } = useProfile()
  return useMemo(() => {
    if (assessmentId) return appName === 'app' ? v1adminProfileTabNames : v1practiceProfileTabNames
    const tabs: V1ProfileTabName[] = []
    tabs.push('patient')
    if (Object.keys(assessments || {}).length) tabs.push('pregnancy')
    tabs.push('visits')
    return tabs
  }, [appName, assessmentId, assessments])
}
