import { Center, Spinner, Text } from '@chakra-ui/react'

import React, { useContext, useEffect, useMemo } from 'react'
import { ProfileContext, UseProfileArgs, useProfileData } from '../../../contexts/ProfileContext'
import { ScreenContext } from '../../../contexts/ScreenContext'
import { ThemeContext } from '../../../contexts/ThemeContext'
import { useAuth } from '../../../store/auth'
import { DefaultModal } from '../../Modals/DefaultModal'
import { ProfileBody as ProfileBodyV1 } from './Body/V1/ProfileBody'
import { ProfileBody as ProfileBodyV2 } from './Body/V2/ProfileBody'
import { PaymentsDueModal } from './Payments/PaymentsDue/PaymentsDueModal'
import { PaymentsProvider } from './Payments/PaymentsProvider'
import { UserProfileProps } from './types'
import { UrgentAlertsPopUp } from './UrgentAlertsPopUp'

export const PatientProfile: React.FC<UserProfileProps> = ({
  userId,
  pregnancyId,
  selectPregnancy,
  tab,
  subTab,
  onTabSelect,
  profileVersion,
  claimId,
  onClose,
}) => {
  const me = useAuth(s => s.user)

  const useProfileArgs = useMemo<UseProfileArgs>(
    () => ({
      tabName: tab || null,
      subTabName: subTab || null,
      onTabSelect,
      selectPregnancy,
      profileVersion,
      userId,
      pregnancyId,
      claimId,
    }),
    [tab, subTab, onTabSelect, selectPregnancy, userId, pregnancyId, claimId, profileVersion],
  )
  const userData = useProfileData(useProfileArgs)
  const { height: screenHeight, isMobile, width: screenWidth } = useContext(ScreenContext)

  const height = useMemo(() => screenHeight - (isMobile ? 10 : 20), [screenHeight, isMobile])
  const width = useMemo(() => screenWidth - (isMobile ? 10 : 20), [screenWidth, isMobile])

  const { user, selectedAssessment, loading } = userData
  const { loading: assessmentLoading } = selectedAssessment || {}
  const { reverseName } = user || {}
  useEffect(() => {
    if (reverseName) document.title = `${reverseName} - HB`
    return () => {
      document.title = 'Hamilton Billing'
    }
  }, [reverseName])

  const isOpen = useMemo(
    () => !!userId || !!pregnancyId || !!claimId,
    [claimId, userId, pregnancyId],
  )

  const ProfileBody = profileVersion === 'v2' ? ProfileBodyV2 : ProfileBodyV1
  return (
    <ProfileContext.Provider value={userData}>
      <ThemeContext.Provider value={{ hideLabels: true, placeholderAbove: true }}>
        <PaymentsProvider>
          <DefaultModal
            isOpen={isOpen}
            closeOnOverlayClick={false}
            overlayHeader
            render={() => (
              <>
                {me && user && !loading && !assessmentLoading ? (
                  <ProfileBody height={height} width={width} />
                ) : (
                  // </Stack>
                  <Center color="gray.600" px={2} py={4} bg="#FFC7BC">
                    <Spinner mr={2} />
                    <Text>Loading...</Text>
                  </Center>
                )}
                <PaymentsDueModal />
                {assessmentLoading || loading ? null : <UrgentAlertsPopUp />}
              </>
            )}
            size="6xl"
            onClose={onClose}
            contentProps={{
              // m: '50px',
              padding: 0,
              maxH: height,
              height,
              maxWidth: '100%',
              width,
              bg: '#efefef',
              overflow: 'hidden',
            }}
            bodyProps={{
              height,
              width,
              padding: 0,
              maxHeight: height,
              overflow: 'hidden',
              display: 'flex',
              flexFlow: 'column',
              overflowY: isMobile ? 'auto' : 'hidden',
            }}
          />
        </PaymentsProvider>
      </ThemeContext.Provider>
    </ProfileContext.Provider>
  )
}
