import { Collapse, Flex, Tab, TabList, TabProps } from '@chakra-ui/react'
import { colors } from '@hb/shared'

import useResizeObserver from '@react-hook/resize-observer'
import React, { useContext, useMemo, useRef } from 'react'
import { ScreenContext, useApp } from '../../../../../contexts'
import { ProfileContext, useProfile } from '../../../../../contexts/ProfileContext'
import { MenuTabList } from '../../../../shared/Nav/MenuTabList'
import { AssessmentHeaderRow } from './AssessmentHeader'
import { PatientHeaderRow } from './PatientHeader'

const ProfileTab = (props: TabProps) => {
  const { isMobile } = useContext(ScreenContext)
  return (
    <Tab
      py={isMobile ? 1 : 0}
      minW="30px"
      color="gray.600"
      borderRadius="full"
      fontSize={['xs', 'sm']}
      lineHeight={1}
      _selected={{
        background: colors.pink.hex,
        fontWeight: 600,
        color: '#333',
      }}
      {...props}
    />
  )
}

const DesktopHeaderTabs = () => {
  const {
    selectedAssessment: { populated: selectedAssessment },
    assessments,
  } = useContext(ProfileContext)
  const numAssessments = Object.keys(assessments || {}).length
  const { appName } = useApp()
  return (
    <TabList height={'36px'} p={1} overflowX="auto" overflowY="hidden" bg="gray.50" maxW="100%">
      <ProfileTab>{appName === 'app' ? 'Patient' : 'Patient / Pregnancy'}</ProfileTab>
      {numAssessments ? (
        <ProfileTab>{appName === 'app' ? 'Assessment' : 'Billing / Insurance'}</ProfileTab>
      ) : null}
      {selectedAssessment ? <ProfileTab>Payments</ProfileTab> : null}
      {appName === 'app' && selectedAssessment ? (
        <>
          <ProfileTab>Authorizations</ProfileTab>
          <ProfileTab>Claims / Charting</ProfileTab>
        </>
      ) : null}
      {selectedAssessment ? <ProfileTab>Files</ProfileTab> : null}
      {/* {selectedAssessment ? <ProfileTab>Visits</ProfileTab> : null} */}
      <ProfileTab>Visits</ProfileTab>
    </TabList>
  )
}

const MobileHeaderTabs = () => {
  const { onTabSelect, tabs, subTab, tabName } = useProfile()
  const path = useMemo(() => (subTab ? `${tabName}/${subTab}` : tabName), [subTab, tabName])
  return (
    <Flex borderBottom="1px solid #cdcdcd" bg={`${colors.green.hex}33`} w="100%">
      <MenuTabList path={path} onSelect={t => onTabSelect(t, null)} tabs={tabs} />
    </Flex>
  )
}

export const ProfileHeader = ({
  onResize,
  width,
}: {
  onResize: (size: { height: number; width: number }) => void
  width: number
}) => {
  const { isMobile } = useContext(ScreenContext)
  const {
    selectedAssessment: { populated: selectedAssessment },
  } = useContext(ProfileContext)
  const contentRef = useRef<HTMLDivElement>(null)
  useResizeObserver(contentRef, ({ contentRect }) => {
    onResize({ height: contentRect.height, width: contentRect.width })
  })
  return (
    <Flex ref={contentRef} flexFlow="column" width="100%" zIndex={2} top={0}>
      <PatientHeaderRow width={width} />
      <Collapse
        style={{ width: '100%', maxWidth: '100%', display: 'flex' }}
        in={!!selectedAssessment}>
        <AssessmentHeaderRow width={width} />
      </Collapse>
      {isMobile ? <MobileHeaderTabs /> : <DesktopHeaderTabs />}
    </Flex>
  )
}
