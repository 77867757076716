import {
  AdminAssessmentData,
  arrayToObject,
  Assessment,
  ASSESSMENTS,
  AssessmentSnippet,
  ASSESSMENTS_ADMIN,
  ASSESSMENT_SNIPPETS,
  getCorrectedValue,
  getPracticeAssessmentCollectionPath,
  midwivesCollection,
  populateAssessment,
  PopulatedAssessmentInterface,
  PracticeAssessmentData,
  providersCollection,
} from '@hb/shared'
import { useMemo } from 'react'
import { useCollection } from '../../../collections/hooks/useCollection'
import { useApp } from '../../../contexts/AppContext'
import { useAuth } from '../../../store/auth'
import { useDocument } from '../useDocument'

export const usePopulatedAssessment = (
  assessment: Assessment | undefined | null,
  snippet?: AssessmentSnippet,
  adminData?: AdminAssessmentData,
  practiceData?: PracticeAssessmentData,
) => {
  const practices = useCollection(midwivesCollection)
  const practicesRecord = useMemo(() => arrayToObject(practices?.items || []) || {}, [practices])

  const insurers = useCollection(providersCollection)
  const insurersRecord = useMemo(() => arrayToObject(insurers?.items || []) || {}, [insurers])

  return useMemo(
    () =>
      assessment
        ? populateAssessment(
            assessment,
            practicesRecord,
            insurersRecord,
            snippet,
            adminData,
            practiceData,
          )
        : null,
    [assessment, snippet, adminData, insurersRecord, practicesRecord, practiceData],
  )
}

export const useAssessmentFromId = (
  admin: boolean,
  id: string | undefined,
): PopulatedAssessmentInterface => {
  const { authUser } = useAuth()
  // const waitingForAdmin = useMemo(() => !admin, [admin])
  const { appName } = useApp()
  const authId = useMemo(() => (authUser ? id : null), [id, authUser])
  const { data: assessment, ref, loading } = useDocument<Assessment>(ASSESSMENTS, authId)
  const adminId = useMemo(
    () => (appName === 'app' && admin && authUser ? id : null),
    [authUser, id, admin, appName],
  )
  const {
    data: snippet,
    ref: snippetRef,
    loading: snippetLoading,
  } = useDocument<AssessmentSnippet>(ASSESSMENT_SNIPPETS, adminId)

  const midwifeId = useMemo(
    () => (assessment ? getCorrectedValue(assessment, 'delivery.midwifeId') : null),
    [assessment],
  )
  const {
    data: practiceData,
    ref: practiceRef,
    loading: practiceLoading,
  } = useDocument<PracticeAssessmentData>(
    midwifeId ? getPracticeAssessmentCollectionPath(midwifeId) : null,
    admin ? id : null,
  )

  const {
    data: adminData,
    ref: adminRef,
    loading: adminLoading,
  } = useDocument<AdminAssessmentData>(ASSESSMENTS_ADMIN, adminId)

  const populated = usePopulatedAssessment(
    assessment || undefined,
    snippet || undefined,
    adminData || undefined,
    practiceData || undefined,
  )

  const insurers = useCollection(providersCollection)
  const practices = useCollection(midwivesCollection)
  const { loading: insurersLoading } = insurers || {}
  const { loading: midwivesLoading } = practices || {}

  return useMemo(
    () => ({
      isPopulated: true,
      ref,
      id: id || '',
      snippetRef,
      assessmentData: assessment,
      adminRef,
      practiceRef,
      practiceData,
      adminData,
      snippetData: snippet,
      loading: !!(
        loading ||
        snippetLoading ||
        adminLoading ||
        insurersLoading ||
        midwivesLoading ||
        practiceLoading
      ),
      populated,
    }),
    [
      populated,
      adminRef,
      snippetRef,
      ref,
      assessment,
      adminLoading,
      practiceLoading,
      snippet,
      adminData,
      practiceData,
      practiceRef,
      loading,
      snippetLoading,
      insurersLoading,
      id,
      midwivesLoading,
    ],
  )
}
