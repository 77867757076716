import { FieldTypes } from '../../constants/FieldTypes'
import { EditorDraft, type Descendant, type EditorVersion } from '../../editor/types'
import { FileDBValue } from '../data'
import { FieldFromType } from '../forms'
import { WithMetadata } from '../shared'

export type TemplateAttachmentPlacementType = 'inline' | 'full-page'

export type InlineFilePlacement = {
  type: 'inline'
  pageIndex: number
  orientation: {
    position: [number, number]
    scale: [number, number]
    rotation: number
  }
}

export type FullPagePlacement = {
  type: 'full-page'
  afterPageIndex: number
}

export type FileTemplateAttachmentPlacement = InlineFilePlacement | FullPagePlacement

export const isInlineFilePlacement = (
  placement: FileTemplateAttachmentPlacement,
): placement is InlineFilePlacement => placement.type === 'inline'

export type TemplateAttachmentType = 'file' | 'input'

type BaseAttachment<
  Placement extends FileTemplateAttachmentPlacement = FileTemplateAttachmentPlacement,
> = {
  id: string
  placement: Placement
}

export interface FileTemplateAttachment<
  Placement extends FileTemplateAttachmentPlacement = FileTemplateAttachmentPlacement,
> extends BaseAttachment<Placement> {
  type: 'file'
  file: FileDBValue
}
export type ExternalPdfFieldType =
  | FieldTypes.TEXT
  | FieldTypes.DATE
  | FieldTypes.SIGNATURE
  | FieldTypes.INITIALS

export interface InputTemplateAttachment<
  Placement extends FileTemplateAttachmentPlacement = FileTemplateAttachmentPlacement,
> extends BaseAttachment<Placement> {
  type: 'input'
  field: FieldFromType<ExternalPdfFieldType>
}

export type TemplateAttachment<
  Placement extends FileTemplateAttachmentPlacement = FileTemplateAttachmentPlacement,
> = FileTemplateAttachment<Placement> | InputTemplateAttachment<Placement>

export const isFileTemplateAttachment = <Placement extends FileTemplateAttachmentPlacement>(
  attachment: TemplateAttachment,
): attachment is FileTemplateAttachment<Placement> => attachment.type === 'file'

export const isAttachedInput = <Placement extends FileTemplateAttachmentPlacement>(
  attachment: TemplateAttachment,
): attachment is InputTemplateAttachment<Placement> => attachment.type === 'input'

export const isInlineAttachment = (
  attachment: TemplateAttachment,
): attachment is FileTemplateAttachment<InlineFilePlacement> =>
  isInlineFilePlacement(attachment.placement)

export type TemplateExternalPdf = {
  baseFile: FileDBValue
  attachments: Record<string, TemplateAttachment>
}

export type Template = {
  // id: string
  name: string
  rank: number
  templateText?: Descendant[]
  editorVersion?: EditorVersion
  isExternalPdf?: boolean
  externalPdf?: TemplateExternalPdf
}

export type TemplateKey =
  | 'assessments'
  | 'authorizations'
  | 'authAppeals'
  | 'authInstructions'
  | 'invoiceAndReceipt'
  | 'claims'
  | 'clinicals'
  | 'consentForm'

export type TemplateCollectionId =
  // assessment templates are stored in templates collection
  | 'templates'
  | 'authTemplates'
  | 'authAppealsTemplates'
  | 'authInstructionsTemplates'
  | 'invoicesReceiptsTemplates'
  | 'claimTemplates'
  | 'clinicalsTemplates'
  | 'consentFormTemplates'

export type TemplateKeyToCollectionId = {
  assessments: 'templates'
  authorizations: 'authTemplates'
  authAppeals: 'authAppealsTemplates'
  authInstructions: 'authInstructionsTemplates'
  invoiceAndReceipt: 'invoicesReceiptsTemplates'
  claims: 'claimTemplates'
  clinicals: 'clinicalsTemplates'
  consentForm: 'consentFormTemplates'
}

export type TemplateEditorDraft = EditorDraft<
  WithMetadata<{
    templateType: TemplateKey
  }>
>
