import { UserContextData } from '@hb/shared'
import { createContext, useCallback, useContext, useMemo } from 'react'
import { PatientTabName, PregnancyTabName, SubTabName, UserProfileTabName } from '../components'
import { isSubTabGroup, SubTab, SubTabGroup } from '../components/shared/Nav/types'
import { getTab } from '../components/shared/Nav/utils'
import { getTabsV1, getTabsV2, patientTab } from '../components/Users/Profile/Nav/tabs'
import { emptyUseQuery } from '../hooks/backend/useQuery'
import { useUserFromId } from '../hooks/backend/user/useUserFromId'
import { useApp } from './AppContext'
import { emptyPopulatedAssessmentInterface } from './AssessmentContext'

export type ProfileEntity = { type: 'patient' } | { type: 'pregnancy'; id: string }

export type ProfilePathData = {
  entity: ProfileEntity | null
  tab: UserProfileTabName | null
  subTab: SubTabName | null
}

type ProfileNav = {
  profileVersion: 'v1' | 'v2'
  tab: SubTab | null
  tabName: UserProfileTabName | null
  subTab: PregnancyTabName | PatientTabName | null
  onTabSelect: (path: string, entity: ProfileEntity | null) => void
  tabs: Array<SubTab | SubTabGroup>
}

type ProfileContextData = UserContextData & ProfileNav
export const ProfileContext = createContext<ProfileContextData>({
  user: null,
  profileVersion: 'v1',
  userId: null,
  viewedTemplate: null,
  adminPatientRef: null,
  loading: false,
  consentForms: {
    data: null,
    error: null,
    loading: false,
  },
  patientRef: null,
  emailVerification: { verified: false, loading: false, refetch: () => {} },
  admin: false,
  setViewedTemplateKey: () => {},
  assessments: null,
  selectPregnancy: () => {},
  officeVisits: emptyUseQuery,
  claims: { data: null, error: null, loading: false },
  selectedAssessment: emptyPopulatedAssessmentInterface,
  assessmentId: undefined,
  practicePatientRef: null,
  tab: patientTab,
  tabName: 'patient',
  subTab: null,
  onTabSelect: () => {},
  tabs: [],
})

export type UseProfileArgs = {
  tabName: UserProfileTabName | null
  subTabName: SubTabName | null
  onTabSelect: (path: ProfilePathData) => void
  selectPregnancy: (id: string | null) => void
  userId?: string
  pregnancyId?: string
  claimId?: string
  profileVersion?: 'v1' | 'v2'
}

export const useProfileData = (args: UseProfileArgs) => {
  const {
    tabName,
    subTabName,
    onTabSelect,
    selectPregnancy,
    userId: _userId,
    pregnancyId: _pregnancyId,
    claimId,
    profileVersion,
  } = args
  const { appName } = useApp()
  const userData = useUserFromId(true, selectPregnancy, _userId, _pregnancyId, claimId)
  const { assessmentId } = userData
  const tabs = useMemo(
    () =>
      profileVersion === 'v2' ? getTabsV2(appName, assessmentId) : getTabsV1(appName, assessmentId),
    [appName, assessmentId, profileVersion],
  )
  const handleTabSelect = useCallback(
    (path: string, entity: ProfileEntity | null) => {
      if (profileVersion === 'v1') {
        const [p, subP] = path.split('/')
        onTabSelect({ tab: p as UserProfileTabName, entity: null, subTab: subP as SubTabName })
        return
      }

      if (entity) {
        if (entity.type === 'pregnancy') {
          selectPregnancy(entity.id)
        } else selectPregnancy(null)
      }
      const pathParts = path.split('/')
      const tabName = pathParts[0] as UserProfileTabName
      const tab = tabs.find(t => t.path === tabName)
      if (!tab) return
      const subTabName = pathParts[1] as PregnancyTabName
      if (!subTabName) {
        onTabSelect({ tab: tabName, entity, subTab: null })
        return
      }
      const subTab = isSubTabGroup(tab) ? tab.children?.find(t => t.path === subTabName) : null
      if (!subTab) return
      onTabSelect({ tab: tabName, entity, subTab: subTabName })
    },
    [tabs, onTabSelect, profileVersion, selectPregnancy],
  )
  const tab = useMemo(
    () => getTab(tabs, subTabName ? `${tabName}/${subTabName}` : tabName) || null,
    [tabs, tabName, subTabName],
  )
  return useMemo<ProfileContextData>(
    () => ({
      ...userData,
      tab,
      subTab: subTabName,
      onTabSelect: handleTabSelect,
      profileVersion: profileVersion || 'v1',
      tabs,
      tabName: tabName,
    }),
    [userData, tab, subTabName, tabs, handleTabSelect, tabName, profileVersion],
  )
}

export const useProfile = () => useContext(ProfileContext)
