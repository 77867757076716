import { AddIcon, CloseIcon } from '@chakra-ui/icons'
import { Box, Flex, HStack, IconButton, StackProps, Text, Tooltip, VStack } from '@chakra-ui/react'
import {
  ASSESSMENTS,
  ASSESSMENTS_ADMIN,
  FileDBValue,
  FileField,
  getPatientFileField,
  UpdateCallback,
} from '@hb/shared'
import { deleteField, DocumentReference, setDoc, updateDoc } from 'firebase/firestore'
import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import { deleteFile } from '../../backend'
import { ProfileContext, ScreenContext, useApp } from '../../contexts'
import { addMetadata } from '../../utils'
import { ActionButton } from '../Buttons'
import { EditableFileView } from '../forms'
import { Loading } from '../Loading'

export type AddFileProps = {
  assessmentId: string | undefined
  filePrefix: string
  handleFileUpload: (key: string, data: any) => Promise<UpdateCallback>
  isAddingFile: boolean
  setIsAddingFile: (b: boolean) => void
}

export type AddFileComponent = FC<AddFileProps>

const AddFile = ({
  assessmentId,
  filePrefix,
  handleFileUpload,
  isAddingFile,
  setIsAddingFile,
}: AddFileProps) => {
  const newFileId = useMemo(() => (isAddingFile ? `${Date.now()}` : null), [isAddingFile])
  const newFileField = useMemo(
    () => (newFileId && assessmentId ? getPatientFileField(newFileId) : null),
    [newFileId, assessmentId],
  )
  return (
    <Box w="100%">
      {newFileField && newFileId ? (
        <HStack spacing={0} w="100%">
          <EditableFileView
            focusOnMount
            baseStoragePath={`${filePrefix}/${newFileId}`}
            fieldPathSegments={[]}
            onSubmit={async v => {
              if (v) return handleFileUpload(newFileId, v)
              setIsAddingFile(false)
              return { success: 'cancelled' }
            }}
            stackProps={{ width: 'auto', flex: 1 }}
            field={newFileField}
          />
          <Tooltip placement="top" hasArrow label="Cancel">
            <IconButton
              aria-label="Cancel"
              icon={<CloseIcon />}
              onClick={() => setIsAddingFile(false)}
              size="xs"
            />
          </Tooltip>
        </HStack>
      ) : (
        <ActionButton bg="white" onClick={() => setIsAddingFile(true)} size="sm" gap={1}>
          <AddIcon w={3} />
          <Text>Upload New File</Text>
        </ActionButton>
      )}
    </Box>
  )
}

export const FilesListView = ({
  files,
  docRef,
  stackProps,
  mergeLoading,
  mergingFileIds,
  preview,
  AddFileComponent,
  onFileSelect,
}: {
  files?: Record<string, FileDBValue>
  docRef: DocumentReference
  stackProps?: StackProps
  mergeLoading?: boolean
  mergingFileIds?: Array<string> | null
  onFileSelect?: (fileId: string, selected: boolean) => void
  preview?: boolean
  AddFileComponent?: AddFileComponent
}) => {
  const { assessmentId } = useContext(ProfileContext)

  const fields = useMemo<Record<string, FileField> | null>(() => {
    if (!assessmentId) {
      return null
    }
    const children: Record<string, FileField> = {}
    if (files) {
      Object.keys(files).forEach(key => {
        if (files[key]) {
          children[key] = getPatientFileField(key)
        }
      })
    }
    return children
  }, [files, assessmentId])

  const [isAddingFile, setIsAddingFile] = useState(false)

  // const fieldKeys = useMemo(() => Object.keys(fields || {}), [fields])
  const { appName } = useApp()

  const handleFileUpload = useCallback(
    (key: string, data: any) =>
      setDoc(docRef, { files: { [key]: addMetadata(data, appName, true) } }, { merge: true })
        .then(() => {
          setIsAddingFile(false)
          return { success: 'File uploaded' }
        })
        .catch(err => ({
          error: err?.message || 'Error occurred uploading file',
        })),
    [setIsAddingFile, docRef, appName],
  )

  const fileKeys = useMemo(
    () =>
      Object.keys(files || {}).sort(
        (a, b) =>
          new Date(files?.[a].uploadedOn || 0).getTime() -
          new Date(files?.[b].uploadedOn || 0).getTime(),
      ),
    [files],
  )

  const filePrefix = useMemo(() => {
    const [baseCollection] = docRef.path.split('/')
    switch (baseCollection) {
      case ASSESSMENTS:
        return `${ASSESSMENTS}/${assessmentId}/shared-files`
      case 'midwives':
        return `${ASSESSMENTS}/${assessmentId}/practice-files`
      case ASSESSMENTS_ADMIN:
        return `${ASSESSMENTS}/${assessmentId}/admin-files`
      default:
        return docRef.path
    }
  }, [docRef, assessmentId])

  const { isMobile } = useContext(ScreenContext)
  const handleDeleteFile = useCallback(
    async (key: string) => {
      if (docRef) {
        if (filePrefix) {
          const resizedFilePath = `${filePrefix}/resized_${key}`
          await deleteFile(resizedFilePath).catch(() => {})
          const originalFilePath = `${filePrefix}/${key}`
          await deleteFile(originalFilePath).catch(() => {})
        }
        await updateDoc(docRef, `files.${key}`, deleteField())
        return { success: 'File deleted' }
      }
      return { error: 'Unknown error' }
    },
    [docRef, filePrefix],
  )

  return (
    <VStack bg="gray.50" align="flex-start" p={2} {...stackProps}>
      {fileKeys.length || isAddingFile ? (
        fileKeys.map(k =>
          fields?.[k] ? (
            <Box
              bg={isMobile ? 'white' : 'transparent'}
              boxShadow={isMobile ? '1px 1px 4px #00000077' : 'none'}
              borderRadius={6}
              w="100%"
              key={k}
              position="relative">
              <EditableFileView
                baseStoragePath={`${filePrefix}/${k}`}
                fieldPathSegments={[]}
                value={files?.[k]}
                stackProps={{ opacity: files?.[k].uploadedOn ? 1 : 0.7 }}
                onSubmit={v => (v ? handleFileUpload(k, v) : handleDeleteFile(k))}
                selectedIndex={mergingFileIds?.indexOf(k)}
                onSelect={
                  onFileSelect
                    ? selected => {
                        onFileSelect(k, selected)
                      }
                    : undefined
                }
                field={fields[k]}
              />
              {files?.[k].combiningIntoPdf ||
              (mergingFileIds && mergingFileIds.includes(k) && mergeLoading) ? (
                <Flex
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  borderRadius={6}
                  bottom={0}
                  bg="rgba(255,255,255,0.5)"
                  justify="center"
                  align="center">
                  <Loading text="Combining into pdf" />
                </Flex>
              ) : null}
            </Box>
          ) : null,
        )
      ) : (
        <Text fontStyle="italic" px={2} color="gray.600">
          No files yet
        </Text>
      )}
      {preview ? null : (
        <>
          {AddFileComponent ? (
            <AddFileComponent
              filePrefix={filePrefix}
              assessmentId={assessmentId}
              handleFileUpload={handleFileUpload}
              isAddingFile={isAddingFile}
              setIsAddingFile={setIsAddingFile}
            />
          ) : (
            <AddFile
              filePrefix={filePrefix}
              assessmentId={assessmentId}
              handleFileUpload={handleFileUpload}
              isAddingFile={isAddingFile}
              setIsAddingFile={setIsAddingFile}
            />
          )}
        </>
      )}
    </VStack>
  )
}
