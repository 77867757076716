import { Button, HStack, Input, Text, useToast, VStack } from '@chakra-ui/react'
import { FieldMapValue, getDateString } from '@hb/shared'
import React, { useCallback, useContext, useState } from 'react'
import { ProfileContext } from '../../../../../../contexts/ProfileContext'
import { useUpdateDoc } from '../../../../../../hooks/backend/useUpdateDoc'
import { DefaultModal } from '../../../../../Modals/DefaultModal'

export const SaveDraftModal = ({
  savingAsDraft,
  onClose,
  onComplete,
}: {
  savingAsDraft: { id: string; data: FieldMapValue } | null
  onClose: () => void
  onComplete: (submittedDraftId: string) => void
}) => {
  const { selectedAssessment } = useContext(ProfileContext)
  const { populated: assessment, adminRef } = selectedAssessment || {}
  const [isSaving, setIsSaving] = useState(false)
  const [name, setName] = useState(getDateString(Date.now(), 'short'))
  const onUpdate = useUpdateDoc()
  const toast = useToast()
  const onSaveDraft = useCallback(async () => {
    if (!adminRef) {
      toast({ description: 'No assessment selected', status: 'error' })
      return
    }
    if (!savingAsDraft) {
      toast({ description: 'Unexpected error', status: 'error' })
      return
    }
    if (!name) {
      toast({ description: 'Please enter a name', status: 'error' })
      return
    }
    const nameMatchId = Object.entries(assessment?.claimsDrafts || {}).find(
      ([, draft]) => draft.name === name,
    )?.[0]
    if (nameMatchId && nameMatchId !== savingAsDraft.id) {
      toast({
        description: 'A draft with this name already exists',
        status: 'error',
      })
      return
    }
    const { id, data } = savingAsDraft
    setIsSaving(true)
    try {
      const { error } = await onUpdate(adminRef, 'claimsDrafts', {
        [id]: { name, data },
      })
      setIsSaving(false)
      if (error) {
        toast({ description: error, status: 'error' })
      } else {
        onComplete(id)
      }
    } catch (err: any) {
      toast({
        description: err.message || 'An unknown error occurred',
        status: 'error',
      })
    }
  }, [assessment, toast, onUpdate, adminRef, savingAsDraft, name, onComplete])

  return (
    <DefaultModal
      isOpen={!!savingAsDraft}
      onClose={onClose}
      overlayHeader
      render={() => (
        <VStack w="100%" align="flex-start" p={3} bg="gray.100" spacing={0}>
          <Text pb={3} fontFamily="hero-new" fontSize="lg">
            Save draft
          </Text>
          <Text fontFamily="hero-new" fontSize="xs">
            DRAFT NAME
          </Text>
          <Input bg="white" value={name} onChange={e => setName(e.target.value)} />
          <HStack w="100%" pt={2}>
            <Button
              ml="auto"
              bg={name ? 'green.400' : 'gray.300'}
              color="white"
              onClick={onSaveDraft}
              isLoading={isSaving}>
              SAVE
            </Button>
          </HStack>
        </VStack>
      )}
    />
  )
}
