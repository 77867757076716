import {
  Assessment,
  GoogleUserProps,
  OnUserSnapshot,
  UpdateCallback,
  User,
  UserCallback,
  UserRole,
  WithId,
} from '@hb/shared'
import { sendPasswordResetEmail as firebaseSendPasswordResetEmail } from 'firebase/auth'
import {
  doc,
  DocumentSnapshot,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  where,
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import cloneDeep from 'lodash.clonedeep'
import { get as nestedGet } from 'nested-property'
import { auth } from '../../../backend/auth'
import { functions } from '../../../backend/functions'
import {
  USERS_REF,
  USER_INVITES_REF,
  USER_ROLES_REF,
} from '../../../collections/firestoreCollections'

export const sendOverduePaymentReminder = httpsCallable(functions, 'sendOverduePaymentReminder')

export const getCorrectedValue = (assessment?: Assessment, path?: string) => {
  const formData = assessment?.data

  return nestedGet(assessment?.corrections, path || '') || nestedGet(formData, path || '')
}

export const nestedDeleteUndefined = (inp: any) => {
  const o = cloneDeep(inp)
  if (typeof o === 'object' && !(o instanceof Array) && o !== null) {
    const keys = Object.keys(o)

    for (let i = 0; i < keys.length; i += 1) {
      const child = o[keys[i]]
      if (child === undefined) {
        delete o[keys[i]]
      }
      if (typeof child === 'object' && !(child instanceof Array)) {
        o[keys[i]] = nestedDeleteUndefined(child)
      }
    }
  }
  return o
}

export const sendPasswordResetEmail = ({ email }: { email: string }) =>
  firebaseSendPasswordResetEmail(auth, email, {
    url: 'https://hamiltonbilling.com/pregnancy',
  })

// updates field in user object - key can be nested
export async function dbUpdateUser(uid: string, key: string, data: any): Promise<UpdateCallback> {
  let updated

  if (typeof data === 'object' && !(data instanceof Array) && data !== null) {
    updated = { ...nestedDeleteUndefined(data), updatedOn: Date.now() }
  } else {
    updated = data
  }
  const userDoc = await getDoc(doc(USERS_REF, uid))
  const isInvite = !userDoc.exists
  try {
    await setDoc(
      doc(isInvite ? USER_INVITES_REF : (USERS_REF as any), uid),
      { [key]: updated },
      { merge: true },
    )
  } catch (err) {
    console.error(err)
    return { error: 'Error updating user' }
  }
  return { success: 'Successfully updated!' }
}
export const populateUserData = (
  authUser: GoogleUserProps,
  dbUser: Omit<User, 'id'>,
): WithId<User> => {
  const { uid, displayName, email } = authUser
  return {
    ...dbUser,
    id: uid,
    name: displayName || undefined,
    email: email || undefined,
    // availableForms: populateAvailableForms(dbUser),
  }
}
function createUser(
  uid: string,
  userItem: Omit<User, 'id' | 'forceUpdateOn'>,
  callback: (c: UserCallback) => void,
  onUserSnapshot: (s: DocumentSnapshot<User>) => void,
  fromInvite?: boolean,
) {
  const userRef = doc(USERS_REF, uid)
  setDoc(userRef, userItem as any)
    .then(() => {
      onSnapshot(userRef, s => onUserSnapshot(s))
      callback({
        success: fromInvite ? 'Invitation accepted!' : 'Welcome to Hamilton Billing!',
      })
    })
    .catch(err => {
      console.error(err)
      callback({ error: 'Error signing up.' })
    })
}

let hasSignedIn = false
export function dbGetOrCreateUser(
  p: GoogleUserProps,
  callback: (c: UserCallback) => void,
  onUserSnapshot: OnUserSnapshot,
) {
  const { uid, displayName, email } = p
  const roleRef = doc(USER_ROLES_REF, uid)
  const handleSnapshot = (s: DocumentSnapshot<User>, role: UserRole) => {
    onUserSnapshot(p, { ...s.data(), role } as Omit<User, 'id'>)
  }

  const onRoleSnapshot = (role: UserRole) => {
    const userRef = doc(USERS_REF, uid)
    getDoc(userRef)
      .then(res => {
        if (res.exists()) {
          handleSnapshot(res, role)
          onSnapshot(userRef, s => handleSnapshot(s, role))
          if (!hasSignedIn) {
            callback({
              success: 'Welcome back!',
            })
            hasSignedIn = true
          }
        } else {
          const now = Date.now()
          const userItem: Omit<User, 'forceUpdateOn'> = { isInvite: false, createdOn: now }
          if (displayName) userItem.name = displayName
          if (email) userItem.email = email
          userItem.joinedOn = Date.now()
          getDocs(query(USER_INVITES_REF, where('email', '==', email)))
            .then(match => {
              if (!match.empty) {
                createUser(
                  uid,
                  userItem,
                  c => {
                    callback(c)
                  },
                  s => handleSnapshot(s, role),
                  true,
                )
              } else {
                createUser(uid, userItem, callback, s => handleSnapshot(s, role))
              }
            })
            .catch(err => {
              console.error(err)
              createUser(uid, userItem, callback, s => handleSnapshot(s, role))
            })
        }
      })
      .catch(err => {
        console.error(err)
        callback({ error: 'Error signing in.' })
      })
  }

  return onSnapshot(
    roleRef,
    (s: DocumentSnapshot) => {
      const { role } = s.data() || {}
      if (role) onRoleSnapshot(role)
      else onRoleSnapshot('user')
    },
    err => {
      console.error(roleRef.path)
      console.error(err)
    },
  )
}

const getEmailVerifiedFunction = httpsCallable<{ userId: string }, boolean>(
  functions,
  'getEmailVerified',
)
const manuallySetEmailVerifiedFunction = httpsCallable(functions, 'manuallySetEmailVerified')

export const getEmailVerified = async (userId: string) => {
  const res = await getEmailVerifiedFunction({ userId })
  return res.data
}
export const manuallySetEmailVerified = async (userId: string) => {
  const res = await manuallySetEmailVerifiedFunction({ userId })
  return res.data
}
