import {
  AppName,
  AssessmentSnippet,
  AssessmentStage,
  ASSESSMENT_SNIPPETS,
  Claim,
  CollectionFilter,
  colors,
  getCoverageNextActionsCount,
  getPracticeAssessmentCollectionPath,
  getPracticeUsersCollectionPath,
  newPatientField,
  newPracticePatientField,
  PracticeAssessmentData,
  PracticeAssessmentStage,
  PresetFilter,
  stateOptions,
  UserRoleItem,
  USER_ROLES,
} from '@hb/shared'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ProfilePathData, ScreenContext, SearchBarProvider, useApp } from '../../contexts'
import { useAppRole } from '../../hooks'
import { useAppModals } from '../../store'
import { DataColumn, DataListTab } from '../../types/data'
import { DataList } from '../DataList'
import {
  assessmentColumns,
  claimsColumns,
  getProviderAssessmentColumns,
  userColumns,
} from '../DataList/columns'
import { AssessmentInterface } from '../DataList/columns/users/types'
import { getRowBackground } from '../DataView'
import { PatientProfile, PregnancyTabName, UserProfileTabName } from '../Users'
import { getVisitsTab } from '../Users/Profile/Charting/visitsList'
import { PageContainer } from '../Views/PageContainer'

const allAssessmentsTab: DataListTab<AssessmentInterface> = {
  collection: ASSESSMENT_SNIPPETS,
  columns: assessmentColumns,
  archived: false,
  defaultSortKey: 'nextActionDate',
  itemName: 'Assessment',
}

const inquiryAssessmentsTab: DataListTab<AssessmentInterface> = {
  collection: ASSESSMENT_SNIPPETS,
  stage: 'Inquiry',
  archived: false,
  columns: assessmentColumns,
  defaultSortKey: 'nextActionDate',
  itemName: 'Assessment',
}

const authTaskListTab: DataListTab<AssessmentInterface> = {
  collection: ASSESSMENT_SNIPPETS,
  stage: 'Authorization',
  archived: false,
  columns: assessmentColumns,
  defaultSortKey: 'nextActionDate',
  itemName: 'Assessment',
}

const sendClaimListTab: DataListTab<AssessmentInterface> = {
  collection: ASSESSMENT_SNIPPETS,
  stage: 'Send Claim',
  archived: false,
  columns: assessmentColumns,
  defaultSortKey: 'nextActionDate',
  itemName: 'Assessment',
}

const allClaimsTab: DataListTab<Claim> = {
  collection: 'claims',
  archived: false,
  columns: claimsColumns,
  defaultSortKey: 'nextActionDate',
  rightJustifyTab: true,
  itemName: 'Claim',
}

const claimsListTab: DataListTab<Claim> = {
  ...allClaimsTab,
  filters: [['assessmentOnClaimsList', '==', true]],
  rightJustifyTab: false,
}

const completeAssessmentsTab: DataListTab<AssessmentInterface> = {
  collection: ASSESSMENT_SNIPPETS,
  stage: 'Complete',
  archived: false,
  columns: assessmentColumns,
  defaultSortKey: 'nextActionDate',
  itemName: 'Assessment',
}

const archivedAssessmentsTab: DataListTab<AssessmentInterface> = {
  collection: ASSESSMENT_SNIPPETS,
  archived: true,
  columns: assessmentColumns,
  defaultSortKey: 'nextActionDate',
  itemName: 'Assessment',
}

const patientsTab: DataListTab<UserRoleItem> = {
  collection: 'user-roles',
  role: 'user',
  columns: userColumns,
  rightJustifyTab: true,
  archived: false,
  // creation: {
  //   field: newPatientField,
  // },
  defaultSortKey: 'createdOn',
  itemName: 'Patient',
}

// provider assessment tabs
const getDeliveredBackgroundColor = (index: number) =>
  index % 2 === 0 ? `${colors.pink.hex}22` : `${colors.pink.hex}45`
const getTransferredBackgroundColor = (index: number) =>
  index % 2 === 0 ? `${colors.green.hex}22` : `${colors.green.hex}34`
const getAssessmentBackgroundColor = (
  item: AssessmentInterface | PracticeAssessmentData,
  index: number,
) => {
  if (item.deliveredOn && item.deliveredOn !== Infinity) return getDeliveredBackgroundColor(index)
  if (item.delivery?.isTransfer || item.deliveredOn === Infinity)
    return getTransferredBackgroundColor(index)
  return getRowBackground(index)
}
const getPostpartumBackgroundColor = (
  item: AssessmentInterface | PracticeAssessmentData,
  index: number,
) => {
  if (item.delivery?.isTransfer || item.deliveredOn === Infinity)
    return getTransferredBackgroundColor(index)
  return getRowBackground(index)
}

const getPracticeAssessmentTab = (
  practiceId: string,
  stage: AssessmentStage | PracticeAssessmentStage,
  superAdmin: boolean,
): DataListTab<PracticeAssessmentData> => ({
  collection: getPracticeAssessmentCollectionPath(practiceId),
  columns: getProviderAssessmentColumns(stage, practiceId, superAdmin),
  presetFilters: presetAssessmentFilters,
  secondarySortKey: 'nextActionDate',
  archived: false,
  stage: undefined,
  defaultSortKey: 'edd',
  filters: [['stages', 'array-contains', stage]],
  searchStringPath: 'stringified',
  itemName: 'Pregnancy',
  getItemBackgroundColor:
    stage !== 'Postpartum' ? getPostpartumBackgroundColor : getAssessmentBackgroundColor,
})

const getCurrentTab = (practiceId: string, superAdmin: boolean) =>
  getPracticeAssessmentTab(practiceId, 'Current', superAdmin)
const getPostpartumTab = (practiceId: string, superAdmin: boolean) =>
  getPracticeAssessmentTab(practiceId, 'Postpartum', superAdmin)
const getGynTab = (practiceId: string, superAdmin: boolean) =>
  getPracticeAssessmentTab(practiceId, 'Gyn', superAdmin)
const getOtherTab = (practiceId: string, superAdmin: boolean) =>
  getPracticeAssessmentTab(practiceId, 'Other', superAdmin)
const getDischargedTab = (practiceId: string, superAdmin: boolean) =>
  getPracticeAssessmentTab(practiceId, 'Discharged', superAdmin)

const presetAssessmentFilters: Array<PresetFilter> = [
  {
    label: 'Plan Design',
    filters: [
      {
        label: 'Fully-Insured',
        filter: ['planCoverageType', '==', 'Fully Insured'],
      },
      {
        label: 'Self-Funded',
        filter: ['planCoverageType', '==', 'Self-Funded'],
      },
      {
        label: 'Split-Funded',
        filter: ['planCoverageType', '==', 'Split-Funded'],
      },
    ],
  },
  {
    label: 'State of Plan',
    searchable: true,
    filters: stateOptions.map(state => ({
      label: state.text,
      filter: ['planState', '==', state.id] as CollectionFilter,
    })),
  },
  {
    label: 'Medicaid/Marketplace',
    filters: [
      {
        label: 'Marketplace Plans',
        filter: ['isMarketplacePlan', '==', true],
      },
      {
        label: 'Medicaid Plans',
        filter: ['isMedicaidPlan', '==', true],
      },
    ],
  },
  {
    label: 'Complaints',
    filter: ['hasComplaints', '==', true],
  },
]

const getClaimBackgroundColor = (item: Claim, index: number) => {
  if (item.deliveredOn === Infinity) {
    return getTransferredBackgroundColor(index)
  }
  return getRowBackground(index)
}

const getAdminRowHeight = (data: AssessmentInterface) => {
  return getCoverageNextActionsCount(data as AssessmentSnippet) * 40
}

const getAssessmentTab = (
  baseTab: DataListTab<AssessmentInterface>,
  midwifeId?: string,
  superAdmin?: boolean,
): DataListTab<AssessmentInterface> => ({
  ...baseTab,
  itemName: midwifeId ? 'Pregnancy' : 'Assessment',
  searchStringPath: 'stringified',
  // itemHeight: midwifeId ? 30 : 36,
  secondarySortKey: 'nextActionDate',
  collection: midwifeId ? getPracticeAssessmentCollectionPath(midwifeId) : baseTab.collection,
  defaultSortKey: midwifeId ? 'edd' : 'nextActionDate',
  itemHeight: midwifeId ? 40 : getAdminRowHeight,
  presetFilters: presetAssessmentFilters,
  getItemBackgroundColor: getAssessmentBackgroundColor,
  columns: (midwifeId
    ? getProviderAssessmentColumns(baseTab.stage, midwifeId, !!superAdmin)
    : baseTab.columns) as Record<string, DataColumn<AssessmentInterface>>,
})

const getClaimTab = (baseTab: DataListTab<Claim>, midwifeId?: string): DataListTab<Claim> => {
  // TODO: remove hasComplaints global filter
  const filters: Array<CollectionFilter> = midwifeId
    ? [...(baseTab.filters || []), ['midwifeId', '==', midwifeId]]
    : baseTab.filters || []
  return {
    ...baseTab,
    searchStringPath: 'stringified',
    filters,
    secondarySortKey: 'nextActionDate',
    presetFilters: presetAssessmentFilters,
    getItemBackgroundColor: getClaimBackgroundColor,
  }
}

const getPatientTab = (
  baseTab: DataListTab<UserRoleItem>,
  onCreateClick: () => void,
  midwifeId?: string,
): DataListTab<UserRoleItem> => ({
  ...baseTab,
  searchStringPath: 'stringified',
  collection: midwifeId ? getPracticeUsersCollectionPath(midwifeId) : USER_ROLES,
  creation: {
    ...baseTab.creation,
    onCreateClick,
    field: midwifeId ? newPracticePatientField : newPatientField,
  },
  role: midwifeId ? undefined : 'user',
})

const getTabs = (
  appName: AppName,
  onCreatePatientClick: () => void,
  midwifeId?: string,
  superAdmin?: boolean,
): Record<string, DataListTab<any>> => {
  const tabs: Record<string, DataListTab<any>> = {}

  if (!midwifeId) {
    tabs['All Assessments'] = getAssessmentTab(allAssessmentsTab, midwifeId, superAdmin)
    tabs.Inquiry = getAssessmentTab(inquiryAssessmentsTab, midwifeId, superAdmin)
    tabs['Auth / Task List'] = getAssessmentTab(authTaskListTab, midwifeId, superAdmin)
    tabs['Office Visits'] = getVisitsTab(appName, null, null, null, true)
    tabs['Send Claim'] = getAssessmentTab(sendClaimListTab, midwifeId, superAdmin)
    tabs.Claims = getClaimTab(claimsListTab, midwifeId)
    tabs.Complete = getAssessmentTab(completeAssessmentsTab, midwifeId, superAdmin)
  } else {
    tabs['All Pregnancies'] = getAssessmentTab(allAssessmentsTab, midwifeId, !!superAdmin)
    tabs.Inquiry = getPracticeAssessmentTab(midwifeId, 'Inquiry', !!superAdmin)
    tabs.Current = getCurrentTab(midwifeId, !!superAdmin)
    tabs.Postpartum = getPostpartumTab(midwifeId, !!superAdmin)
    tabs.Gyn = getGynTab(midwifeId, !!superAdmin)
    tabs.Other = getOtherTab(midwifeId, !!superAdmin)
    tabs['Office Visits'] = getVisitsTab(appName, midwifeId, null, null, true)
    tabs.Discharged = getDischargedTab(midwifeId, !!superAdmin)
    if (superAdmin) {
      tabs.Complete = getPracticeAssessmentTab(midwifeId, 'Complete', !!superAdmin)
    }
  }
  tabs.Archived = getAssessmentTab(archivedAssessmentsTab, midwifeId)
  tabs.Patients = getPatientTab(patientsTab, onCreatePatientClick, midwifeId)

  return tabs
}

export const AssessmentsList = ({ midwifeId }: { midwifeId?: string }) => {
  const { pregnancyId, claimId, displayedItem, patientId, subItem } = useParams() as {
    pregnancyId?: string
    claimId?: string
    patientId?: string
    displayedItem: string
    subItem?: string
  }
  const { appName } = useApp()
  const { open: openInviteModal } = useAppModals(s => s.invitePatient)
  const [profileVersion] = useState<'v1' | 'v2'>('v1')
  const role = useAppRole()
  const history = useHistory()
  const tabs = useMemo(
    () => getTabs(appName, () => openInviteModal(), midwifeId, role === 'super-admin'),
    [midwifeId, role, openInviteModal, appName],
  )

  const basePath = useMemo(() => (midwifeId ? '' : '/admin'), [midwifeId])
  const handleTabSelect = useCallback(
    ({ subTab: subTabName, tab: tabName }: ProfilePathData) => {
      const path = subTabName ? `${tabName}/${subTabName}` : tabName
      if (patientId) {
        if (pregnancyId) {
          history.push(`${basePath}/patients/${patientId}/pregnancies/${pregnancyId}/${path}`)
        } else history.push(`${basePath}/patients/${patientId}/${path}`)
      } else if (pregnancyId) {
        history.push(`${basePath}/pregnancies/${pregnancyId}/${path}`)
      } else if (claimId) {
        history.push(`${basePath}/claims/${claimId}/${path}`)
      }
    },
    [pregnancyId, basePath, claimId, history, patientId],
  )

  const handleAssessmentSelect = useCallback(
    (
      id: string | null,
      tabName?: UserProfileTabName,
      subTabName?: PregnancyTabName | UserProfileTabName,
    ) => {
      if (id) {
        const path = subTabName ? `${tabName}/${subTabName}` : tabName
        const assessmentPath = `${id}${profileVersion === 'v1' ? `/pregnancy/${path ? `/${path}` : ''}` : ''}`
        if (patientId) {
          history.push(`${basePath}/patients/${patientId}/pregnancies/${assessmentPath}`)
        } else history.push(`${basePath}/pregnancies/${assessmentPath}`)
      } else if (patientId) history.push(`${basePath}/patients`)
      else history.push(`${basePath}/pregnancy`)
    },
    [basePath, history, patientId, profileVersion],
  )

  const handleClose = useCallback(() => {
    if (patientId) {
      history.push(`${basePath}/patients`)
    } else if (pregnancyId) {
      history.push(`${basePath}/pregnancies`)
    } else {
      history.push(`${basePath}/claims`)
    }
  }, [pregnancyId, basePath, history, patientId])

  const { contentHeight } = useContext(ScreenContext)
  return (
    <PageContainer fullWidth>
      <SearchBarProvider>
        <DataList height={contentHeight - 70} rootPath="pregnancies" tabs={tabs} />
        <PatientProfile
          tab={displayedItem as UserProfileTabName}
          subTab={subItem as PregnancyTabName}
          onTabSelect={handleTabSelect}
          profileVersion={profileVersion}
          selectPregnancy={handleAssessmentSelect}
          onClose={handleClose}
          userId={patientId}
          pregnancyId={pregnancyId}
          claimId={claimId}
        />
      </SearchBarProvider>
    </PageContainer>
  )
}
