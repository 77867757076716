import { Box, Divider, Flex, HStack, Stack, Text, VStack } from '@chakra-ui/react'
import {
  addressToString,
  colors,
  Field,
  FieldMap,
  FieldMapValue,
  FieldTypes,
  IdField,
  InsuranceProvider,
  midwivesCollection,
  providersCollection,
  taxIdField,
} from '@hb/shared'

import React, { useContext, useMemo, useState } from 'react'
import { ProfileContext } from '../../../../contexts'
import { useDeleteDocData, useUpdateDoc } from '../../../../hooks/backend/useUpdateDoc'
import { ActionButton } from '../../../Buttons'
import { FloatingContainer } from '../../../Containers'
import { CopyId } from '../../../CopyId'
import { DataView, GenericEditModal } from '../../../DataView'
import { NoteForm } from '../../../forms/NoteForm'
import { SelectAssessmentBox } from '../Assessments/SelectAssessmentBox'
import { AssessmentDocuments } from '../Documents/AssessmentDocuments'
import { DraftView } from '../Documents/DraftView'
import { ReferenceNumbersEdit } from '../ReferenceNumberEdit'

const refNumber: Field = {
  placeholder: 'Reference #',
  type: FieldTypes.TEXT,
}
const statusField: Field = {
  placeholder: 'Status',
  type: FieldTypes.DROPDOWN,
  options: [
    { id: 'approved-in', text: 'Approved / In Network' },
    { id: 'approved-oon', text: 'Approved / Out of Network' },
    { id: 'denied', text: 'Denied' },
    { id: 'pending', text: 'Pending' },
    { id: 'canceled', text: 'Canceled' },
  ],
}

const serviceType: Field = {
  placeholder: 'Service Type',
  type: FieldTypes.DROPDOWN,
  options: [
    { id: 'global', text: 'Global' },
    { id: 'prenatal', text: 'Prenatal' },
    { id: 'delivery', text: 'Delivery' },
    { id: 'postpartum', text: 'Postpartum' },
    { id: 'gyn', text: 'GYN' },
    { id: 'other', text: 'Other' },
  ],
}

const insuranceProviderId: IdField<InsuranceProvider> = {
  optional: true,
  type: FieldTypes.ID,
  collection: providersCollection,
  placeholder: 'Insurance Provider',
  // allowOther: true,
}

export const toAuthorizationName = (data?: FieldMapValue) => {
  const { referenceNumber, status } = data || {}
  return `Auth Ref #: ${referenceNumber} - ${
    typeof status === 'string' ? status.toUpperCase() : 'error'
  }`
}
const authorizationField: FieldMap = {
  name: 'Authorization',
  toName: toAuthorizationName,
  children: {
    referenceNumber: refNumber,
    status: statusField,
    serviceType,
    insuranceProviderId,
    dates: {
      optional: true,
      type: FieldTypes.TEXTAREA,
      placeholder: 'Dates...',
    },
    cptCodes: {
      optional: true,
      type: FieldTypes.TEXTAREA,
      placeholder: 'CPT Codes...',
    },
    icd: {
      optional: true,
      type: FieldTypes.TEXTAREA,
      placeholder: 'ICD-10...',
    },
    midwifeId: {
      type: FieldTypes.ID,
      placeholder: 'Provider',
      optional: true,
      collection: midwivesCollection,
    },
    taxId: taxIdField,
    npi: {
      type: FieldTypes.TEXT,
      optional: true,
      placeholder: 'NPI',
    },
    address: {
      type: FieldTypes.TEXTAREA,
      optional: true,
      placeholder: 'Address',
    },
    authType: {
      type: FieldTypes.DROPDOWN,
      placeholder: 'Auth Type',
      options: [
        { id: 'outpatient', text: 'Outpatient' },
        { id: 'inpatient', text: 'Inpatient' },
      ],
    },
    callRefNumber: {
      type: FieldTypes.TEXT,
      placeholder: 'Call Ref #',
      optional: true,
    },
    agent: {
      type: FieldTypes.TEXTAREA,
      optional: true,
      placeholder: 'Agent...',
    },
    notes: {
      type: FieldTypes.TEXTAREA,
      optional: true,
      placeholder: 'Notes...',
    },
  },
}

export const AssessmentAuthorizations = ({ preview }: { preview?: boolean }) => {
  const {
    selectedAssessment: { adminRef, populated: selectedAssessment },
  } = useContext(ProfileContext)
  const update = useUpdateDoc()
  const deleteDocData = useDeleteDocData()
  const { insuranceProvider, midwife, authorizations } = selectedAssessment || {}

  const sortedAuths = useMemo(
    () =>
      Object.entries(authorizations || {}).sort(([a], [b]) => parseInt(b, 10) - parseInt(a, 10)),
    [authorizations],
  )

  const [selectedAuth, setSelectedAuth] = useState<null | string>(null)
  const autofill = useMemo(
    () =>
      (selectedAuth && authorizations?.[selectedAuth]) || {
        insuranceProviderId: insuranceProvider?.id,
        cptCodes: insuranceProvider?.cptCodes?.join('\n'),
        icd: insuranceProvider?.icdCodes?.join('\n'),
        midwifeId: midwife?.id,
        taxId: midwife?.taxId,
        npi: midwife?.npi,
        address: midwife?.servicingAddresses?.[0]
          ? addressToString(midwife.servicingAddresses[0], true)
          : midwife?.servicingAddresses?.[0],
      },
    [insuranceProvider, midwife, selectedAuth, authorizations],
  )
  return (
    <Stack>
      {sortedAuths.length ? (
        sortedAuths.map(([authKey, authData], index) => (
          <HStack position="relative" key={authKey} align="flex-start" w="100%">
            <Flex
              flex={1}
              key={authKey}
              onClick={() => setSelectedAuth(authKey)}
              borderRadius={4}
              bg="white"
              cursor="pointer"
              _hover={{ bg: 'whiteAlpha.800' }}
              // border='1px solid #cdcdcd'
            >
              <DataView
                onDelete={() => deleteDocData(adminRef, `authorizations.${authKey}`)}
                index={index}
                field={authorizationField}
                initExpanded={false}
                noBody
                data={authData}
              />
            </Flex>
            <CopyId top="unset" bottom="unset" item={{ id: authKey }} />
          </HStack>
        ))
      ) : (
        <Text px={2} opacity={0.9} fontStyle="italic">
          No authorizations yet
        </Text>
      )}
      {preview ? null : (
        <ActionButton size="sm" bg="white" onClick={() => setSelectedAuth(`${Date.now()}`)}>
          + Add Auth
        </ActionButton>
      )}
      {selectedAuth ? (
        <GenericEditModal
          data={autofill}
          isOpen={!!selectedAuth}
          onClose={() => setSelectedAuth(null)}
          field={authorizationField}
          onSubmit={async data => {
            if (!selectedAuth) return { error: 'no auth selected' }
            return update(adminRef, `authorizations.${selectedAuth}`, data)
          }}
        />
      ) : null}
    </Stack>
  )
}

export const AssessmentAuthorizationsTab = () => {
  const {
    selectedAssessment: { adminRef, populated: selectedAssessment },
    assessmentId,
    patientRef,
  } = useContext(ProfileContext)
  const update = useUpdateDoc()
  const { insuranceProvider, drafts, authNote } = selectedAssessment || {}

  const { id: patientId } = patientRef || {}

  return (
    <VStack w="100%" p={2} bg="gray.100">
      <Box w="100%" borderRadius={6} bg="white">
        <FloatingContainer position="relative" bg={`${colors.pink.hex}88`}>
          <AssessmentAuthorizations />
        </FloatingContainer>
      </Box>
      <ReferenceNumbersEdit id="authReferenceNumbers" />
      <NoteForm note={authNote} onSubmit={data => update(adminRef, 'authNote', data)} />
      <SelectAssessmentBox>
        <Stack mt={4}>
          <DraftView
            name="Clinicals Template"
            templateKey="clinicals"
            draft={drafts?.clinicalsTemplates}
          />
          {assessmentId && patientId ? (
            <AssessmentDocuments
              adminView
              patientId={patientId}
              category="clinicals"
              assessmentId={assessmentId}
            />
          ) : null}
          <Divider />
          <DraftView
            name="Auth Instructions Template"
            templateKey="authInstructions"
            draft={drafts?.authInstructionsTemplates}
          />
          {assessmentId && patientId ? (
            <AssessmentDocuments
              adminView
              patientId={patientId}
              category="authInstructions"
              assessmentId={assessmentId}
            />
          ) : null}
          <Divider />
          <DraftView
            name="Auth Appeals Template"
            templateKey="authAppeals"
            draft={drafts?.authAppealsTemplates}
          />
          {assessmentId && patientId ? (
            <AssessmentDocuments
              adminView
              category="authAppeals"
              assessmentId={assessmentId}
              patientId={patientId}
            />
          ) : null}
        </Stack>
        <Box bg="gray.50" p={2}>
          <Text fontFamily="Comfortaa" fontWeight={600} color="gray.700">
            {insuranceProvider ? `${insuranceProvider.name} ` : 'Insurer '} Auth Sheet
          </Text>
          <Text
            fontStyle={insuranceProvider?.authSheet ? 'normal' : 'italic'}
            color={insuranceProvider?.authSheet ? 'gray.700' : 'gray.500'}
            whiteSpace="pre-wrap">
            {insuranceProvider
              ? insuranceProvider.authSheet || `No auth sheet for ${insuranceProvider.name}`
              : 'No insurance provider'}
          </Text>
        </Box>
      </SelectAssessmentBox>
    </VStack>
  )
}
