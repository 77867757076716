import { CloseIcon } from '@chakra-ui/icons'
import { Box, Button, Center, CircularProgress, Flex, HStack, Text } from '@chakra-ui/react'
import { Descendant, EditorDraft, Template, WithId } from '@hb/shared'

import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ReactEditor } from 'slate-react'
import { DefaultModalProps } from '../Modals/DefaultModal'
import { EditorModalContainer, EditorSize, SubmitText } from '../RichText'
import { TemplateToolbar } from '../RichText/TemplateToolbar'
import { TextEditor } from '../RichText/TextEditor'
import { AssessmentEditorProvider } from './contexts/documentEditor'

import { TemplateInsurancePlansProvider } from './contexts'
import { useTemplateData } from './contexts/data'
import { TemplateViewRef, useTemplateView } from './contexts/view'
import { EditorFaxButton, useOnFax } from './EditorFaxButton'
import { EditorSaveButton, useOnSave } from './EditorSaveButton'
import { TextEditorSubmitButton, useOnSubmit } from './EditorSubmitButton'
import { ExternalPdfTemplateView } from './ExternalPdf/ExternalPdfTemplateView'
import { TemplateMenu } from './TemplateMenu'
import { TemplateViewProps } from './types'
import { getWithVariables } from './withVariables'

export const TemplateViewModal: React.FC<
  Omit<TemplateViewProps, 'editorSize'> & Omit<DefaultModalProps, 'render'>
> = ({ submitText, ...modalProps }) => (
  <EditorModalContainer
    render={(editorSize, onBack) => (
      <TemplateViewBody onBack={onBack} editorSize={editorSize} submitText={submitText} />
    )}
    {...modalProps}
  />
)
const TemplateViewHeader = ({
  onBack,
  submitText,
}: {
  onBack?: () => void
  submitText: SubmitText
}) => {
  const {
    shortcutArgs: { assessment },
  } = useTemplateData()

  const {
    onFax,
    onSave,
    onSubmit,
    updateInitialText,
    editorVersion,
    template: { data: template },
    type,
    setManualTemplateId,
  } = useTemplateView()

  const saveManager = useOnSave(editorVersion)

  const faxManager = useOnFax()
  const submitManager = useOnSubmit(type, template, editorVersion, submitText)
  return (
    <HStack align="center" width="100%" px={2} py={1} zIndex={4} bg="white">
      <Box minW="0" flex={1}>
        <TemplateMenu
          insuranceProviderId={assessment?.insuranceProvider?.id}
          onChange={(t: WithId<Template>) => {
            // manualTemplateSelectedOn.current = Date.now()
            setManualTemplateId(t.id)
            if (updateInitialText) updateInitialText(t.editorVersion || 'v1', t.templateText || [])
          }}
          template={template}
          templateType={type}
        />
      </Box>
      <Flex gap={1}>
        {onFax && !submitManager.submitConfirm ? <EditorFaxButton {...faxManager} /> : null}
        {onSubmit && !faxManager.faxSending ? (
          <TextEditorSubmitButton template={template} {...submitManager} />
        ) : null}
        {onSave && !submitManager.submitConfirm ? <EditorSaveButton {...saveManager} /> : null}
        {onBack ? (
          <Button
            variant="outline"
            onClick={onBack}
            size="xs"
            bg="gray.50"
            color="gray.500"
            alignItems="center"
            gap={1}>
            <CloseIcon position="relative" top="1px" w={2} />
            <Text>Close</Text>
          </Button>
        ) : null}
      </Flex>
    </HStack>
  )
}

const RichTextTemplateView = ({
  editorSize,
  submitText,
  onBack,
}: {
  editorSize: EditorSize
  submitText: SubmitText
  onBack?: () => void
}) => {
  const { initialText, editorVersion, type } = useTemplateView()
  const initTextUpdatedOn = useRef(Date.now())

  const [initText, setInitialText] = useState(initialText)
  const [isRestarting, setIsRestarting] = useState(false)

  const t0 = useRef<ReturnType<typeof setTimeout> | undefined>()
  const restartWithText = useCallback((text: Descendant[]) => {
    setIsRestarting(true)
    if (t0.current) clearTimeout(t0.current)
    setInitialText(text)
    t0.current = setTimeout(() => {
      setIsRestarting(false)
    }, 250)
  }, [])

  useEffect(() => {
    if (initialText?.length) {
      initTextUpdatedOn.current = Date.now()
      restartWithText(initialText || [])
    }
  }, [restartWithText, initialText])

  // const usedVersion = useMemo(
  //   () => (template ? template?.editorVersion || 'v1' : editorVersion),
  //   [template, editorVersion],
  // )

  const ref = useRef<ReactEditor>(null)

  const decorators = useMemo(() => [getWithVariables(editorVersion)], [editorVersion])

  const toolbars = useMemo(() => [TemplateToolbar], [])
  return isRestarting ? (
    <Center gap={2} w={`${editorSize.width}px`} h={`${editorSize.height}px`} p={4}>
      <CircularProgress color="green.500" size={8} isIndeterminate />
      <Text>Loading template...</Text>
    </Center>
  ) : (
    <TextEditor
      withDownload
      version={editorVersion}
      decorators={decorators}
      toolbars={toolbars}
      templateType={type}
      value={initText}
      ref={ref}
      {...editorSize}>
      <TemplateViewHeader
        // manualTemplateSelectedOn={manualTemplateSelectedOn}
        // setManualTemplateId={setManualTemplateId}
        // usedVersion={usedVersion}
        onBack={onBack}
        // templateType={templateType}
        // template={template}
        submitText={submitText}
      />
    </TextEditor>
  )
}

const TemplateViewBody: React.FC<TemplateViewProps> = ({ editorSize, onBack, submitText }) => {
  const { template: templateData } = useTemplateView()
  const { data: template } = templateData

  // if (templateLoading)
  //   return (
  //     <Center w={`${editorSize.width}px`} h={`${editorSize.height}px`} p={4}>
  //       <CircularProgress color="gray.500" size={5} isIndeterminate />
  //     </Center>
  //   )
  if (template?.isExternalPdf) {
    return (
      <ExternalPdfTemplateView
        template={template}
        submitText={submitText}
        height={editorSize.height}
        width={editorSize.width}
        onClose={onBack}
      />
    )
  }
  return <RichTextTemplateView editorSize={editorSize} submitText={submitText} onBack={onBack} />
}

type AssessmentTemplateViewProps = {
  size: EditorSize
  submitText: SubmitText
  draft: EditorDraft | null
}

export const AssessmentTemplateView = forwardRef<TemplateViewRef, AssessmentTemplateViewProps>(
  ({ size, submitText, draft }, ref) => (
    <TemplateInsurancePlansProvider>
      <AssessmentEditorProvider ref={ref} draft={draft}>
        <TemplateViewBody
          editorSize={size}
          // editorVersion={version}
          // templateType="assessments"
          submitText={submitText}
          // id={templateId}
        />
      </AssessmentEditorProvider>
    </TemplateInsurancePlansProvider>
  ),
)
