import { FileDBValue } from '../data'
import { UserGroup, WithMetadata } from '../shared'
import { Template } from '../templates'
import { Address, Hospital, Physician, WithAlternate } from '../utils'

export type BilledCharge = {
  amount: number
}
export type BilledCharges = Record<string, number>

export type Practice = {
  id: string
  name: string
  logoFile?: FileDBValue
  description?: string
  fname: string
  createdOn?: number
  createdBy?: string
  email: string
  abilityId?: string
  isInactive: boolean
  prm?: number
  npi?: string
  lname: string
  isInvite?: boolean
  rank: number
  zipCode?: string
  billedCharges?: BilledCharges
}

export interface License {
  number: string
  state: string
  credentials: Record<string, boolean>
}

export interface MedicaidId {
  state: string
  id: string
}

export interface Provider {
  fname?: string
  lname?: string
  npi?: string
  medicaidIds?: Array<MedicaidId>
  inActive?: boolean
}

// TODO: Separate Referring Provider info to its own type
// TODO: Separate addresses to their own type

export type PracticeAccessRole = 'admin' | 'super-admin' | 'user'
export type PracticeUserAccess = {
  role: PracticeAccessRole
  email: string
  fname: string | null
  lname: string | null
  nickname: string | null
  addedOn: number
  adminColor?: string
  adminColorNotes?: string
  adminColorUpdatedBy?: string
  adminColorUpdatedOn?: number
  addedBy: string
}
export type PracticeUsers = Record<string, PracticeUserAccess>
export type PracticeAccess = {
  sendInvoicesTo?: string
  users?: PracticeUsers
}

// VISIBLE TO ADMINS AND PRACTICE MEMBERS ONLY - Needs to be implemented/migrated from admin data
export interface ProtectedPracticeData {
  // serviceAddress? : Address
  affiliatedHospitals?: Array<Hospital>
  npi?: string
  isGroup?: boolean
  group?: {
    name?: string
    npi?: string
    medicaidIds?: Array<MedicaidId>
  }
  fax?: WithAlternate<string>
  claimsAddress: string
  servicingAddresses?: Array<Address>
  medicaidIds?: Array<MedicaidId>
  addressNotes?: string
  phone?: string
  licenses?: Array<License>
}

export type PracticeConsentForm = WithMetadata<
  Template & {
    isActive: boolean
    rank: number
  }
>

export interface AdminPracticeData {
  id: string
  specialty?: string
  affiliatedHospitals?: Array<Hospital>
  // npi?: string
  isGroup?: boolean
  group?: {
    name?: string
    npi?: string
    medicaidIds?: Array<MedicaidId>
  }
  fax?: WithAlternate<string>
  claimsAddress: string
  servicingAddresses?: Array<Address>
  paymentsAddress?: Address
  corporateAddress?: Address
  payToAddress?: Address
  // on provider log in, tax ID should show unless its an SSN
  taxId?: string
  taxIdIsSSN?: boolean
  feinTaxClass?: string
  ssn?: string
  fname?: string
  lname?: string
  dob?: number
  medicaidIds?: Array<MedicaidId>
  email?: WithAlternate<string>
  notes?: string
  addressNotes?: string
  phone?: string
  licenses?: Array<License>
  prmNotes?: string
  retainer?: number
  retainerNotes?: string
  collaborativePhysicians?: Physician[]
  renderingProviders?: Array<Provider>
  acceptedPaymentMethods?: string
  paymentInfo?: string
  redFlagged?: boolean
  redFlaggedReason?: string
}

export type PracticeWithAdmin = Omit<Practice, 'email'> & Partial<AdminPracticeData>

export interface BaseAuthentication {
  userId: string
  ip: string
  authenticatedOn: number
  userGroup: UserGroup
  providerFirst: string
  providerLast: string
  practiceId: string
}

export interface ProviderAuthentication extends BaseAuthentication {
  previousVersions: BaseAuthentication[]
}

export type SubmittedAuthentication = { practiceId: string; userId: string }

export type ICD10 = { code: string; description: string; prefix?: string }
export type CPTCode = {
  code: string
  icd10: ICD10[]
  description: string
  notes?: string
}

export type CPTCodeGroup = { name: string; codes: CPTCode[] }

export const isCptCodeGroup = (item: CPTCode | CPTCodeGroup): item is CPTCodeGroup =>
  (item as CPTCodeGroup).codes !== undefined
