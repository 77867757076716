import { Calculation } from 'final-form-calculate'
import cloneDeep from 'lodash.clonedeep'
import { get as nestedGet, set as nestedSet } from 'nested-property'
import {
  AppCollectionsState,
  CollectionItem,
  CollectionState,
  getCollectionId,
} from '../collections'
import { ARRAY_ERROR, FieldTypes } from '../constants'
import {
  AlternateField,
  AlternateFieldBlueprint,
  AnyObject,
  BooleanField,
  CheckboxField,
  DateField,
  DropdownField,
  DropdownOptionItem,
  Field,
  FieldBlueprint,
  FieldLabels,
  FieldMap,
  FieldMapValue,
  FieldStructure,
  FileDBValue,
  FileField,
  FileFieldBlueprint,
  Form,
  FormElement,
  IdField,
  InfoStage,
  ListField,
  NumberField,
  Shortcut,
  ShortcutMap,
  SignatureField,
  Stage,
  ValidationErrors,
  Validator,
} from '../types'
import { formatDollarValue, formatDropdownValue, getAllOptions, padZeros } from './data'
import {
  getDateString,
  getDateTimeString,
  getLocalDateString,
  getTimezoneOffset,
  timeStringFrom24Hour,
  timeStringTo24Hour,
} from './dates'

export const isListField = (
  field: Field | ListField | FieldMap | ShortcutMap | Shortcut | InfoStage,
): field is ListField => !!field.itemFields

export const isFieldMap = (field: Field | FieldMap | ListField): field is FieldMap =>
  !!field.children

export const validateOptional: Validator<Field> = (
  value?: any,
  field?: Field,
): string | undefined => {
  if (field && !field.optional) {
    if (!value) return 'This field is required'
  }

  return undefined
}

export const notUndefinedOrNull = <T>(value?: T | undefined | null): value is T =>
  value !== undefined && value !== null
export const toBoolean = (value?: any) =>
  notUndefinedOrNull(value) && value !== '' ? undefined : 'This field is required'
export const notUndefinedOrNullValidation = (value?: any) =>
  notUndefinedOrNull(value) ? undefined : 'This field is required'
const fileValidation = (value?: FileDBValue) =>
  value && (value.dataUrl || value.storagePath) ? undefined : 'This field is required'
export const defaultPhoneValidation = (value?: string) =>
  value && value === value.replace(/\D/g, '') && (value.length === 10 || value.length === 11)
    ? undefined
    : 'Please enter a valid phone number with area code'

export const defaultEmailValidation = (value?: string) =>
  value && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
    ? undefined
    : 'Please enter a valid email address'

export const formatPhoneNumber = (v?: string) => {
  if (v === 'None' || v === 'zzz') return 'None'
  if (v) {
    switch (v.length) {
      case 0:
        return ''
      case 1:
      case 2:
      case 3:
        return `(${v}`
      case 4:
      case 5:
      case 6:
        return `(${v.substring(0, 3)}) ${v.substring(3)}`
      case 7:
      case 8:
      case 9:
      case 10:
        return `(${v.substring(0, 3)}) ${v.substring(3, 6)}-${v.substring(6)}`
      case 12:
        return `(${v.substring(2, 5)}) ${v.substring(5, 8)}-${v.substring(8)}`
      default:
        return `${v[0]} (${v.substring(1, 4)}) ${v.substring(4, 7)}-${v.substring(7)}`
    }
  }
  return ''
}

const usTimezones: Record<string, string> = {
  '-04:00': 'EDT',
  '-05:00': 'EST',
  '-06:00': 'CST',
  '-07:00': 'MST',
  '-08:00': 'PST',
}
// export const timeParse = (v?: string) => {
//   if (!v) return undefined
//   const myTimezoneUTC = new Date().toLocaleTimeString('en-us', { timeZoneName: 'longOffset' }).split(' ')[2].substring(3)
//   return `${timeStringTo24Hour(v)}${myTimezoneUTC}`
// }
export const formatTime = (v?: string) => {
  if (!v) return ''
  const time = v.substring(0, 8)
  const offset = v.substring(8)
  const timezone = usTimezones[offset] || `${offset.substring(0, 3)} GMT`
  // const offset = v.substring(8)
  return `${timeStringFrom24Hour(time)} ${timezone}`
}

export const fieldFormat: {
  [key in FieldTypes]?: (
    val: any | undefined,
    field: Field,
    collections?: AppCollectionsState,
  ) => any
} = {
  [FieldTypes.DOLLAR_AMOUNT]: formatDollarValue,
  [FieldTypes.PHONE]: formatPhoneNumber,
  [FieldTypes.PERCENTAGE]: (val?: any) => (notUndefinedOrNull(val) ? `${val}%` : ''),
  [FieldTypes.NUMBER]: (val, field) => {
    const asNumberField = field as NumberField
    if (!notUndefinedOrNull(val)) return ''
    if (asNumberField.numDigits !== undefined) return padZeros(val, asNumberField.numDigits)
    return `${val}`
  },
  [FieldTypes.BOOLEAN]: val => {
    if (val === true) return 'Yes'
    if (val === false) return 'No'
    return undefined
  },
  [FieldTypes.TIME]: formatTime,
  [FieldTypes.DROPDOWN]: (val, field) =>
    formatDropdownValue(val, (field as DropdownField)?.options),
  [FieldTypes.ID]: (val, field, collections) => {
    const { collection } = field as IdField<any>
    const collectionId = getCollectionId(collection)
    const collectionState = collections?.[collectionId] as CollectionState<any>
    const match = collectionState?.items?.find(i => i.id === val)
    return match ? match.name : val
  },
  [FieldTypes.MULTIPLE_SELECT]: (val, field) => {
    if (!val) return ''
    const { options } = field as DropdownField
    const selectedOptions = getAllOptions(options).filter(o => val?.includes(o.id))
    return selectedOptions.map(o => o.text).join(', ')
  },
}

export const formatField = (field: Field, value: any, collections?: AppCollectionsState) => {
  const format = field.format || fieldFormat[field.type]
  return format
    ? format(value, field, collections)
    : `${value !== undefined && value !== null ? value : ''}`
}

export const numberParse = (val?: string) => {
  if (val !== undefined) {
    if (val[val.length - 1] === '.' && !Number.isNaN(parseFloat(val.substring(0, val.length - 1))))
      return val
    const asFloat = parseFloat(val)
    if (!Number.isNaN(asFloat)) return asFloat
  }
  return undefined
}

export const timeParse = (v?: string) => {
  if (!v) return undefined
  return `${timeStringTo24Hour(v)}${getTimezoneOffset()}`
}

export const fieldParse: {
  [key in FieldTypes]?: (val?: any) => any
} = {
  [FieldTypes.DOLLAR_AMOUNT]: v => {
    const parsed = numberParse(v.replace(/(\$|,)/g, ''))
    return parsed === undefined || parsed === null || Number.isNaN(parsed)
      ? undefined // @ts-expect-error - strings are valid here
      : Number(`${Math.round(`${parsed}e2`)}e-2`)
  },
  [FieldTypes.TIME]: timeParse,
  [FieldTypes.PERCENTAGE]: numberParse,
  [FieldTypes.PHONE]: val => val.replace(/\D/g, ''),
  [FieldTypes.CHECKBOX]: val => val,
  [FieldTypes.NUMBER]: numberParse,
  [FieldTypes.BOOLEAN]: (val?: string) => {
    if (val) {
      if (val === 'Yes') {
        return true
      }
      if (val === 'No') {
        return false
      }
    }

    return undefined
  },
}

export const composeValidators =
  (...validators: Validator<Field>[]) =>
  (value?: any, field?: Field) =>
    validators.reduce<string | undefined>(
      (error, validator) => error || validator(value, field),
      undefined,
    )

const initialsValidation = (val?: any) => {
  if (!val || typeof val !== 'string') return 'Required'
  if (val.length < 2) return 'Please enter your initials'
  return undefined
}

const timeValidation = (val?: any) => {
  if (!val) return 'Required'
  if (typeof val !== 'string') return 'Invalid time'
  // expected format is HH:MM:SSgmtOffset
  const time = val.substring(0, 8)
  const offset = val.substring(8)
  const [hours, minutes, seconds] = time.split(':').map(v => parseInt(v, 10))

  if (hours < 0 || hours > 23) return 'Invalid time'
  if (minutes < 0 || minutes > 59) return 'Invalid time'
  if (seconds < 0 || seconds > 59) return 'Invalid time'
  if (offset.length !== 6) return 'Invalid time'
  if (offset[0] !== '+' && offset[0] !== '-') return 'Invalid time'
  if (offset[3] !== ':') return 'Invalid time'
  return undefined
}

const dateValidation = (val?: any, field?: DateField) => {
  const { minDate: min, maxDate: max } = field || {}
  if (!val) return 'Required'
  const valDate = new Date(val).setHours(0, 0, 0, 0)
  const fourDigitValidation = new Date('January 1, 1900').setHours(0, 0, 0, 0)
  if (valDate < fourDigitValidation) {
    return 'Please enter the year with four digits'
  }
  if (min !== undefined || max !== undefined) {
    const today = new Date().setHours(0, 0, 0, 0)
    // make sure year is above 1900
    if (min !== undefined) {
      const minDate = typeof min === 'number' ? new Date(min).setHours(0, 0, 0, 0) : today
      if (valDate < minDate) {
        return today === minDate
          ? 'Should be in the future'
          : `Should be before ${getDateString(minDate)}`
      }
    }
    if (max !== undefined) {
      const maxDate = typeof max === 'number' ? new Date(max).setHours(0, 0, 0, 0) : today
      if (valDate > maxDate) {
        return today === maxDate
          ? 'Should be in the past'
          : `Should be before ${getDateString(maxDate)}`
      }
    }
  }

  return undefined
}

const numberValidation: (formatNumber?: (v: any) => string) => Validator<NumberField> =
  formatNumber => (val?: any, field?: NumberField) => {
    const { min, max, optional } = field || {}
    const realMin = min === null ? Number.NEGATIVE_INFINITY : min
    if (!optional && (val === undefined || val === null)) return 'Required'
    if (typeof val === 'string' || Number.isNaN(Number(val))) return 'Not a number'
    if (max === undefined && !realMin && val < 0) return 'Should be positive'
    if (realMin !== undefined && val < realMin) {
      const formatted = formatNumber ? formatNumber(realMin) : realMin
      return `Should be above ${formatted}`
    }
    if (max === 0 && val >= 0) return 'Should be negative'
    if (max !== undefined && val > max) {
      const formatted = formatNumber ? formatNumber(max) : max
      return `Should be below ${formatted}`
    }

    return undefined
  }
export const defaultValidation: Record<FieldTypes, Validator<any>> = {
  [FieldTypes.TEXT]: toBoolean,
  [FieldTypes.TEXTAREA]: toBoolean,
  [FieldTypes.DROPDOWN]: toBoolean,
  [FieldTypes.BOOLEAN]: notUndefinedOrNullValidation,
  [FieldTypes.CHECKBOX]: () => undefined,
  [FieldTypes.DATE]: dateValidation,
  [FieldTypes.TIME]: timeValidation,
  [FieldTypes.DATETIME]: numberValidation(getDateTimeString),
  [FieldTypes.ID]: notUndefinedOrNullValidation,
  [FieldTypes.DYNAMIC_DROPDOWN]: notUndefinedOrNullValidation,
  [FieldTypes.DOLLAR_AMOUNT]: numberValidation(formatDollarValue),
  [FieldTypes.PERCENTAGE]: numberValidation(v => `${v}%`),
  [FieldTypes.PHONE]: defaultPhoneValidation,
  [FieldTypes.EMAIL]: defaultEmailValidation,
  [FieldTypes.FILE]: fileValidation,
  [FieldTypes.ALTERNATE]: notUndefinedOrNullValidation,
  [FieldTypes.NUMBER]: numberValidation(),
  [FieldTypes.MULTIPLE_SELECT]: notUndefinedOrNullValidation,
  [FieldTypes.SIGNATURE]: (val: any, field: SignatureField) => {
    if (!val && !field.optional) return 'Required'
    const todayString = getLocalDateString(new Date())
    if (val.date !== todayString) return "Entered date does not match today's date"
    if (!val.body) return 'Missing signature'
    return undefined
  },
  [FieldTypes.INITIALS]: initialsValidation,
}

export const isField = (field: FormElement): field is Field => field.type !== undefined
export const isBooleanField = (field: Field): field is BooleanField | CheckboxField =>
  field.type === FieldTypes.BOOLEAN || field.type === FieldTypes.CHECKBOX

export const defaultFieldValidate = (child: Field, value?: any, ignoreNowRequirement?: boolean) => {
  const validate = child.validate || defaultValidation[child.type]
  const validated = { ...child }
  if (validated.type === FieldTypes.DATE && ignoreNowRequirement) {
    delete validated.maxDate
    delete validated.minDate
  }
  return validate(value, validated)
}

export const validateListField = (
  child: ListField,
  value: Array<any> | undefined | null,
  ignoreNowRequirement?: boolean,
): ValidationErrors => {
  const validate =
    child.itemFields.validate ||
    (isField(child.itemFields)
      ? defaultValidation[child.itemFields.type]
      : defaultStageValidate(child.itemFields, ignoreNowRequirement))
  if (!child.optional && !value?.length) {
    return { [ARRAY_ERROR]: 'Required' }
  }
  const error = value?.reduce(
    (acc, curr: any) => {
      const e = validate(curr)
      return [...acc, e || null]
    },
    [] as Array<ValidationErrors | null>,
  ) as Array<ValidationErrors | null>
  if (value?.length && error.find(e => !!e)) {
    return error
  }
  return undefined
}

export const defaultStageValidate =
  (stage?: FieldMap | Stage | null, ignoreNowRequirement?: boolean) => (values?: FieldMapValue) => {
    if (!stage || isInfoStage(stage)) return undefined
    const errors: ValidationErrors = {}
    // if (!values || !Object.keys(values).length) return {}
    const childNames = Object.keys(stage.children)
    for (let i = 0; i < childNames.length; i += 1) {
      const childName = childNames[i]
      const child = stage.children[childName]

      if (!isInfoStage(child)) {
        const isActive = !child.condition || child.condition(values)
        if (isActive) {
          if (isListField(child)) {
            const listError = validateListField(child, values?.[childName], ignoreNowRequirement)
            if (listError) errors[childName] = listError
          } else if (isField(child)) {
            if (!(child.optional && !values?.[childName])) {
              const error = defaultFieldValidate(child, values?.[childName], ignoreNowRequirement)
              if (error) errors[childName] = error
            }
          } else if (!(child.optional && !values?.[childName])) {
            const validate = child.validate || defaultStageValidate(child, ignoreNowRequirement)
            const error = validate(values && values[childName])
            if (error && Object.keys(error).length) errors[childName] = error
          }
        }
      }
    }
    return Object.keys(errors).length ? errors : undefined
  }

export const isFieldItem = (
  field: Field | Shortcut | FieldMap | ShortcutMap | ListField,
): field is Field | Shortcut => !field.children
export const isShortcut = (field: Field | Shortcut): field is Shortcut => !!field.shortcut

export const isInfoStage = (
  stage: Stage | InfoStage | Shortcut | FieldMap | Field | ListField | ShortcutMap,
): stage is InfoStage => stage.info === true

export function isPopulated(labels: FieldLabels) {
  if (typeof labels === 'function') {
    return true
  }
  return false
}

export const getFieldName = (field: FormElement): string => {
  if (isField(field)) return field.placeholder
  return field.name
}

export const createAlternateField = (args: AlternateFieldBlueprint): AlternateField => ({
  type: FieldTypes.ALTERNATE,
  ...args,
})

export const createFileField = (props: FileFieldBlueprint): FileField => ({
  type: FieldTypes.FILE,
  ...props,
})

type NumberFieldBlueprint = FieldBlueprint & Omit<NumberField, 'type'>
export const createNumberField = (props: NumberFieldBlueprint): NumberField => ({
  type: FieldTypes.NUMBER,
  ...props,
})

export const otherField = {
  type: FieldTypes.TEXT,
  placeholder: 'Please specify...',
}

export const getFieldIsRedFlagged = (field: Field, value: any) => {
  switch (field.type) {
    case FieldTypes.DROPDOWN:
      return getAllOptions(field.options).find(i => i.id === value)?.redFlagReason
    case FieldTypes.BOOLEAN:
    case FieldTypes.CHECKBOX:
      return value ? field.redFlagYesReason : field.redFlagNoReason
    default:
      return undefined
  }
}

export const getDynamicDropdownOption = (item: CollectionItem<AnyObject>): DropdownOptionItem => ({
  text: `${item.name}${item.isInactive ? ' (Inactive)' : ''}`,
  id: item.name || item.id,
})

export const getDynamicDropdownValue = ({
  value,
  dynamicDropdownOptions,
}: {
  value?: any
  dynamicDropdownOptions?: DropdownOptionItem[]
}) => {
  if (!dynamicDropdownOptions) return 'Error: Field Data not provided'
  if (dynamicDropdownOptions.length === 0) return 'N/A'
  const dynamicDropdownMatch = dynamicDropdownOptions.find(o => o.id === value)
  return dynamicDropdownMatch ? dynamicDropdownMatch.text : 'None'
}

export const injectFields = (
  fieldMap: FieldMap,
  fields: Record<string, Field | FieldMap>,
): FieldMap => ({ ...fieldMap, children: { ...fieldMap.children, ...fields } })

export const getNestedFields = (fields: Record<string, Form | string>): FieldMap =>
  Object.keys(fields).reduce(
    (acc, formId) => {
      const form = cloneDeep(fields[formId])
      if (typeof form === 'string') return acc
      const nestedPath = form.path ? `${form.path.split('.').join('.children.')}` : ''

      const currChildren = nestedGet(acc.children, `${nestedPath}.children`) || {}

      const stageNames = Object.keys(form.stages)
      for (let i = 0; i < stageNames.length; i += 1) {
        const stage = form.stages[stageNames[i]]
        if (!isInfoStage(stage)) {
          if (stage.calculate) {
            acc.calculate = [...stage.calculate, ...acc.calculate]
          }
        }
        // if(stage.calculate){
        //   if(!acc.calculate)
        // }
      }
      if (nestedPath) {
        nestedSet(acc.children, nestedPath, {
          name: form.pathName,
          initExpanded: true,
          children: {
            ...currChildren,
            ...form.stages,
          },
        })
        return acc
      }
      return { ...acc, children: { ...acc.children, ...form.stages } }
    },
    {
      children: {},
      name: 'Assessment Questionnaire',
      description: 'All questionnaire fields',
      calculate: [] as Calculation[],
    },
  )

// returns a nested array of field names
export const getFieldStructure = (fieldMap: FieldMap, value?: any): FieldStructure => {
  const fieldIds = Object.keys(fieldMap.children)
  const children = fieldIds.reduce((acc, fieldId) => {
    const field = fieldMap.children[fieldId]
    if (isInfoStage(field)) return acc
    if (isField(field) || isListField(field)) return acc
    if (field.condition && !field.condition(value)) return acc
    return [...acc, getFieldStructure(field, value?.[fieldId])]
  }, [] as FieldStructure[])

  return { name: fieldMap.name, children }
}

export const getFieldFromPath = (fieldMap: FieldMap, path = ''): FormElement =>
  nestedGet(fieldMap.children, path.split('.').join('.children.'))

export const makeFieldOptional = (field: Field): Field => ({
  ...field,
  optional: true,
})

export const makeListFieldOptional = (field: ListField): ListField => ({
  ...field,
  optional: true,
})

export const nestedModifyFields = (
  fieldMap: FieldMap,
  modifier: (field: Field) => Field,
  listFieldModifier: (field: ListField) => ListField,
  fieldPaths?: string[],
  fieldMapModifier?: (modified: FieldMap) => FieldMap,
) => {
  const newFieldMap = fieldMapModifier ? fieldMapModifier(cloneDeep(fieldMap)) : cloneDeep(fieldMap)
  const fieldNames = Object.keys(fieldMap.children)
  fieldNames.forEach(fieldName => {
    const field = getFieldFromPath(newFieldMap, fieldName)
    if (isInfoStage(field)) return
    if (isListField(field)) {
      const fieldPath = `children.${fieldName}`
      const baseListField =
        fieldPaths === undefined || fieldPaths.includes(fieldName)
          ? listFieldModifier(field)
          : field
      const newListField: ListField = {
        ...baseListField,
        itemFields: isField(field.itemFields)
          ? { ...field.itemFields, optional: true }
          : nestedModifyFields(field.itemFields, modifier, listFieldModifier),
      }
      nestedSet(newFieldMap, fieldPath, newListField)
    }
    if (isField(field)) {
      const fieldPath = `children.${fieldName}`
      if (fieldPaths === undefined || fieldPaths.includes(fieldName)) {
        nestedSet(newFieldMap, fieldPath, modifier(field))
      } else {
        nestedSet(newFieldMap, fieldPath, field)
      }
    } else if (isFieldMap(field)) {
      const fieldPath = `children.${fieldName}`
      nestedSet(
        newFieldMap,
        fieldPath,
        nestedModifyFields(
          field,
          modifier,
          listFieldModifier,
          fieldPaths
            ?.filter(path => path.startsWith(fieldPath))
            .map(p => p.slice(fieldPath.length)),
          fieldMapModifier,
        ),
      )
    }
  })
  return newFieldMap
}

export const makeAllFieldsOptional = (fieldMap: FieldMap): FieldMap =>
  nestedModifyFields(fieldMap, makeFieldOptional, makeListFieldOptional)
export const makeFieldsOptional = (fieldMap: FieldMap, optionalFields: string[]) =>
  nestedModifyFields(fieldMap, makeFieldOptional, makeListFieldOptional, optionalFields)

export const makeAllFieldsAdmin = (fieldMap: FieldMap): FieldMap =>
  nestedModifyFields(
    fieldMap,
    field => {
      if (field.type === FieldTypes.DATE) {
        return {
          ...makeFieldOptional(field),
          minDate: undefined,
          maxDate: undefined,
        } as DateField
      }
      return makeFieldOptional(field)
    },
    makeListFieldOptional,
    undefined,
    modified => ({
      ...modified,
      validate: () => undefined,
    }),
  )

// export const makeAllFieldsOptional = (fieldMap: FieldMap): FieldMap => {
//   const newFieldMap = cloneDeep(fieldMap)
//   const fieldNames = Object.keys(fieldMap.children)
//   fieldNames.forEach((fieldName) => {
//     const field = getFieldFromPath(newFieldMap, fieldName)
//     if (isInfoStage(field)) return
//     if (isListField(field)) {
//       const fieldPath = `children.${fieldName}`
//       const newListField: ListField = {
//         ...field,
//         optional: true,
//         itemFields: isField(field.itemFields)
//           ? { ...field.itemFields, optional: true }
//           : makeAllFieldsOptional(field.itemFields),
//       }
//       nestedSet(newFieldMap, fieldPath, newListField)
//     }
//     if (isField(field)) {
//       const fieldPath = `children.${fieldName}`
//       nestedSet(newFieldMap, fieldPath, { ...field, optional: true })
//     } else if (isFieldMap(field)) {
//       const fieldPath = `children.${fieldName}`
//       nestedSet(newFieldMap, fieldPath, makeAllFieldsOptional(field))
//     }
//   })
//   return newFieldMap
// }

const fieldCheck = (
  field: Field,
  data: any,
  check: (checkedField: Field, checkedData: any) => boolean,
) => check(field, data)

const fieldMapCheck = (
  fieldMap: FieldMap,
  data: FieldMapValue | undefined | null,
  check: (field: Field, fieldData: any) => boolean,
) => {
  const fieldNames = Object.keys(fieldMap.children)
  for (let i = 0; i < fieldNames.length; i += 1) {
    const fieldName = fieldNames[i]
    const field = fieldMap.children[fieldName]
    if (!formElementCheck(field, data?.[fieldName], check)) return false
  }
  return true
}

const listFieldCheck = (
  listField: ListField,
  data: any[] | null | undefined,
  check: (field: Field, fieldData: any) => boolean,
) => {
  if (!data) return false
  for (let i = 0; i < data.length; i += 1) {
    if (formElementCheck(listField.itemFields, data[i], check)) return true
  }
  return false
}

export const formElementCheck = (
  field: FormElement,
  data: any,
  check: (checkedField: Field, checkedData: any) => boolean,
) => {
  if (isField(field)) return fieldCheck(field, data, check)
  if (isListField(field)) return listFieldCheck(field, data, check)
  if (isInfoStage(field)) return false
  return fieldMapCheck(field, data, check)
}
type FieldFindResult = {
  field: Field
  data: any
  fieldPathSegments: string[]
}

const fieldFind = (
  field: Field,
  data: any,
  fieldPathSegments: string[],
  check: (checkedField: Field, checkedData: any) => boolean,
): Array<FieldFindResult> => (check(field, data) ? [{ field, data, fieldPathSegments }] : [])

const fieldMapFind = (
  fieldMap: FieldMap,
  data: FieldMapValue,
  check: (field: Field, fieldData: any) => boolean,
  fieldPathSegments: string[] = [],
): Array<FieldFindResult> => {
  const found: FieldFindResult[] = []
  const fieldNames = Object.keys(fieldMap.children)
  for (let i = 0; i < fieldNames.length; i += 1) {
    const fieldName = fieldNames[i]
    const field = fieldMap.children[fieldName]
    const fieldData = data[fieldName]
    found.push(...formElementFind(field, fieldData, check, [...fieldPathSegments, fieldName]))
  }
  return found
}

const listFieldFind = (
  listField: ListField,
  data: any[] | null | undefined,
  check: (field: Field, fieldData: any) => boolean,
  fieldPathSegments: string[] = [],
) => {
  const found: FieldFindResult[] = []
  if (!data) return found
  for (let i = 0; i < data.length; i += 1) {
    found.push(
      ...formElementFind(listField.itemFields, data[i], check, [
        ...fieldPathSegments,
        i.toString(),
      ]),
    )
  }
  return found
}

export const formElementFind = (
  field: FormElement,
  data: any,
  check: (checkedField: Field, checkedData: any) => boolean,
  fieldPathSegments: string[] = [],
): Array<FieldFindResult> => {
  if (isField(field)) return fieldFind(field, data, fieldPathSegments, check)
  if (isListField(field)) return listFieldFind(field, data, check, fieldPathSegments)
  if (isInfoStage(field)) return []
  return fieldMapFind(field, data, check, fieldPathSegments)
}

export const getFormElementHasAnyFileFields = (field: FormElement) =>
  formElementCheck(field, undefined, checkedField => checkedField.type === FieldTypes.FILE)

export const getFormElementHasAnyDataUrls = (field: FormElement, data: any) =>
  formElementCheck(
    field,
    data,
    (checkedField, checkedData) => checkedField.type === FieldTypes.FILE && checkedData?.dataUrl,
  )

export const findFormElementsWithDataUrls = (field: FormElement, data: any) =>
  formElementFind(
    field,
    data,
    (checkedField, checkedData) => checkedField.type === FieldTypes.FILE && checkedData?.dataUrl,
  )

export const getFormElementName = (field: FormElement): string => {
  if (isField(field)) return field.placeholder
  if (isListField(field)) return field.name
  return field.name
}

const unResizedTypes = ['image/gif', 'image/svg+xml']
export const isResizedType = (type?: string): boolean =>
  !!type && type.indexOf('image/') === 0 && !unResizedTypes.includes(type)

// example: /path/to/file.jpg -> /path/to/resized_file.jpg
export const getResizedStoragePath = (path: string, type: string | undefined) => {
  if (!isResizedType(type)) return path
  const pathParts = path.split('/').filter(part => !!part)
  const fileName = pathParts.pop()
  if (!fileName) throw new Error('No file name')
  if (fileName.startsWith('resized_')) return path
  return `${pathParts.join('/')}/resized_${fileName}`
}
