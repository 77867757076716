import { Box, Flex } from '@chakra-ui/react'
import React, { useRef } from 'react'
import { useProfile } from '../../../../../../contexts'
import { useResizeObserver } from '../../../../../../hooks/useResizeObserver'
import { AssessmentPreview } from '../../../../../Assessments'
import { Loading } from '../../../../../Loading'
import { AssessmentAuthorizations } from '../../../Authorizations/AssessmentAuthorizations'
import { AssessmentFiles } from '../../../Files/AssessmentFiles'
import { AssessmentPayments } from '../../../Payments'
import { CardsView } from '../Containers/CardsView'
import { PreviewBox } from '../Containers/PreviewBox'

export const PregnancyControlPanel = () => {
  const { selectedAssessment, user, assessmentId, onTabSelect } = useProfile()
  const bodyRef = useRef<HTMLDivElement>(null)
  const { width } = useResizeObserver(bodyRef, 'content')
  return (
    <Flex ref={bodyRef} w="100%" h="100%" flexFlow="column">
      <CardsView
        width={width}
        elements={[
          <PreviewBox maxH={400} title="Assessment">
            {user && assessmentId ? (
              <AssessmentPreview
                id={assessmentId}
                onSelect={() => onTabSelect('pregnancy/assessment', null)}
                user={user}
                assessment={selectedAssessment.assessmentData}
              />
            ) : (
              <Loading />
            )}
          </PreviewBox>,
          <PreviewBox onGo={() => onTabSelect('pregnancy/payments', null)} title="Payments">
            <AssessmentPayments preview />
          </PreviewBox>,
          <PreviewBox
            onGo={() => onTabSelect('pregnancy/authorizations', null)}
            title="Authorizations">
            <Box w="100%" p={2}>
              <AssessmentAuthorizations />
            </Box>
          </PreviewBox>,
          <PreviewBox onGo={() => onTabSelect('pregnancy/authorizations', null)} title="Files">
            <AssessmentFiles preview />
          </PreviewBox>,
        ]}
      />
    </Flex>
  )
}
