import { Box, Flex } from '@chakra-ui/react'
import {
  AssessmentStatus,
  defaultInitialValue,
  Descendant,
  EditorDraft,
  EditorVersion,
  PopulatedAssessment,
} from '@hb/shared'
import useResizeObserver from '@react-hook/resize-observer'

import * as React from 'react'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Text as SlateText } from 'slate'
// import { AssessmentInterfaceContext } from '../../contexts/AssessmentContext'

import { useEditorWidth } from '../../RichText'
import { TemplateViewRef } from '../../Templates/contexts'
import { AssessmentTemplateView } from '../../Templates/TemplateViewModal'
import { TemplateViewProps } from '../../Templates/types'
import { AssessmentHistory } from './AssessmentHistory'

// fix for buggy v1 stuff
const normalizeV1Text = (text: Descendant[]): Descendant[] =>
  text.map(node => (SlateText.isText(node) ? { type: 'span', children: [node] } : node))
const getAssessmentSubmitText = (sentOn?: number): TemplateViewProps['submitText'] =>
  sentOn
    ? { submit: 'Resend assessment', confirm: 'Confirm resend' }
    : { submit: 'Send Assessment', confirm: 'Confirm send' }

const awaitingText: Descendant[] = [
  {
    type: 'span',
    children: [{ text: "Awaiting assessment results, we'll get back to you soon!" }],
  },
]

export const AssessmentEditor: React.FC<{
  admin?: boolean
  assessmentId?: string
  assessment: PopulatedAssessment
  width: number
  height: number
  status?: AssessmentStatus
}> = ({ admin, assessment, width, height }) => {
  const {
    results,
    sentOn,
    draft,
    draftSavedOn,
    draftEditorVersion,
    editorVersion: resultsVersion,
  } = assessment || {}

  const getInitialText = useCallback(() => {
    let version: EditorVersion = 'v1'

    let text = awaitingText
    const setToResults = () => {
      text = results || defaultInitialValue
      version = resultsVersion || 'v1'
    }
    const setToDraft = () => {
      text = draft || defaultInitialValue
      version = draftEditorVersion || 'v1'
    }
    if (admin) {
      if (sentOn && draftSavedOn && draft && results) {
        if (draftSavedOn > sentOn) setToDraft()
        else setToResults()
      } else if (results) setToResults()
      else if (draft) setToDraft()
      else text = defaultInitialValue
    } else if (sentOn && results) setToResults()
    return { text: version === 'v1' ? normalizeV1Text(text) : text, editorVersion: version }
  }, [admin, sentOn, draft, draftSavedOn, results, draftEditorVersion, resultsVersion])
  const init = useRef(getInitialText())
  const editorRef = useRef<TemplateViewRef>(null)
  const [initialDraft] = useState<EditorDraft>(init.current)
  // const [editorVersion, setEditorVersion] = useState<EditorVersion>(init.current.version)

  const updateInitialText = useCallback((version: EditorVersion, text: Descendant[]) => {
    editorRef.current?.updateDraft({ text, editorVersion: version })
  }, [])
  const [headerHeight, setHeaderHeight] = useState(0)
  const headerRef = useRef<HTMLDivElement>(null)

  useResizeObserver(headerRef, entry => {
    setHeaderHeight(entry.target.scrollHeight)
  })

  const editorSize = useEditorWidth(width - 16, height - headerHeight - 10)

  const submitText = useMemo(() => getAssessmentSubmitText(sentOn), [sentOn])
  return (
    <Flex flexFlow="column" w="100%" h="100%" overflow="hidden">
      <Box height="auto" w="100%" ref={headerRef}>
        <AssessmentHistory assessment={assessment} updateText={updateInitialText} />
      </Box>
      <Box flex={1} minH="0" w="100%">
        <AssessmentTemplateView
          ref={editorRef}
          submitText={submitText}
          draft={initialDraft}
          size={editorSize}
        />
      </Box>
    </Flex>
  )
}
