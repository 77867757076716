import {
  HStack,
  IconButton,
  Image,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from '@chakra-ui/react'
import { partnerEmailField, partnerNameField, partnerPhoneField } from '@hb/shared'
import React, { useContext } from 'react'
import { ProfileContext } from '../../../../contexts/ProfileContext'
import { EditableRow } from '../../../forms/Input'

export const PartnerPopover = () => {
  const {
    selectedAssessment: { populated: selectedAssessment },
  } = useContext(ProfileContext)
  const { mergedSignOnData } = selectedAssessment || {}
  const { hasPartner, partnerContact } = mergedSignOnData || {}
  const { name, email, phone } = partnerContact || {}
  return hasPartner ? (
    <Popover trigger="hover">
      <PopoverTrigger>
        <IconButton
          size="md"
          variant="ghost"
          _hover={{ bg: 'whiteAlpha.500' }}
          borderRadius="full"
          aria-label="Partner"
          icon={<Image src="/images/svg/family.svg" width="26px" opacity={0.5} />}
        />
      </PopoverTrigger>
      <PopoverContent>
        <HStack bg="gray.100" w="100%" borderBottom="1px solid #cdcdcd">
          <Text color="gray.600" fontWeight={600} fontSize="lg" px={2}>
            Partner Info
          </Text>
        </HStack>
        <VStack spacing={0} width="100%">
          <EditableRow
            index={0}
            label="Partner Name"
            value={name}
            parentValue={mergedSignOnData}
            field={partnerNameField}
          />
          <EditableRow
            index={1}
            label="Partner Email"
            value={email}
            parentValue={mergedSignOnData}
            field={partnerEmailField}
          />
          <EditableRow
            index={1}
            label="Partner Phone"
            value={phone}
            parentValue={mergedSignOnData}
            field={partnerPhoneField}
          />
        </VStack>
      </PopoverContent>
    </Popover>
  ) : null
}
