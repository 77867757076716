import { Box, Button, Flex, Stack, Text, Tooltip, useDisclosure } from '@chakra-ui/react'
import { generatePaymentSchedule, getAssessmentName } from '@hb/shared'
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { ScreenContext, useApp } from '../../../../contexts'
import { PopUpMessageContext } from '../../../../contexts/PopUpMessage/PopUpMessageContext'
import { ProfileContext } from '../../../../contexts/ProfileContext'
import { useResizeObserver } from '../../../../hooks'
import { useAppRole } from '../../../../hooks/backend/user/useAppRole'
import { useUpdateDoc } from '../../../../hooks/backend/useUpdateDoc'
import { ActionButton, SolidActionButton } from '../../../Buttons/ActionButton'
import LoadingIcon from '../../../LoadingIcon'
import { DefaultModal } from '../../../Modals/DefaultModal'
import { SelectAssessmentBox } from '../Assessments/SelectAssessmentBox'
import { AssessmentDocuments } from '../Documents/AssessmentDocuments'
import { DraftView } from '../Documents/DraftView'
import { PaymentsDue } from './PaymentsDue'
import { PaymentsLog } from './PaymentsLog'
import { PaymentsContext } from './PaymentsProvider'
import { ReceivedPayments } from './ReceivedPayments/ReceivedPayments'

export const NoPayments = () => {
  const { modal } = useContext(PaymentsContext)
  const {
    user,
    assessmentId,
    selectedAssessment: { populated: selectedAssessment, adminRef },
  } = useContext(ProfileContext)
  const { processResponse } = useContext(PopUpMessageContext)
  const [isLoading, setIsLoading] = useState(false)
  const { payments } = selectedAssessment || {}
  const { appName } = useApp()
  const update = useUpdateDoc()
  const generate = useCallback(async () => {
    if (payments?.due) {
      return processResponse({ error: 'Patient already has payments' })
    }
    if (!selectedAssessment || !user || !assessmentId)
      return processResponse({ error: 'no assessment' })
    setIsLoading(true)
    const res = await update(
      adminRef,
      'payments',
      generatePaymentSchedule(user, assessmentId, selectedAssessment),
    )
    setIsLoading(false)
    return processResponse(res)
  }, [user, adminRef, payments, processResponse, selectedAssessment, update, assessmentId])
  return (
    <Flex direction="column" py={2} px={3}>
      <Text fontSize="md">No payments scheduled for this pregnancy</Text>
      {appName === 'app' ? (
        <Flex mt={1}>
          <SolidActionButton isLoading={isLoading} onClick={generate} size="sm">
            Generate Payment Schedule
          </SolidActionButton>
          <ActionButton bg="white" size="sm" ml={2} onClick={modal.onOpen}>
            Manual Entry
          </ActionButton>
        </Flex>
      ) : null}
    </Flex>
  )
}

export const AssessmentPayments = ({ preview }: { preview?: boolean }) => {
  const {
    selectedAssessment: { populated: selectedAssessment },
  } = useContext(ProfileContext)
  const { appName } = useApp()
  const appRole = useAppRole()
  const { payments } = selectedAssessment || {}

  const bodyRef = useRef<HTMLDivElement>(null)
  const { width } = useResizeObserver(bodyRef, 'content')
  const mobileLayout = useMemo(() => width < 400, [width])
  return (
    <Flex ref={bodyRef} w="100%" flexFlow="column">
      {payments && Object.keys(payments).length ? (
        <>
          <PaymentsDue mobileLayout={mobileLayout} />
          {(appName === 'app' || appRole === 'super-admin') && !preview ? (
            <ReceivedPayments width={width} mobileLayout={mobileLayout} />
          ) : null}
        </>
      ) : (
        <NoPayments />
      )}
    </Flex>
  )
}

export const AssessmentPaymentsTab = () => {
  const {
    selectedAssessment: { populated: selectedAssessment },
    loading,
    assessmentId,
    user,
  } = useContext(ProfileContext)
  const patientId = user?.id
  const { appName } = useApp()
  const { midwife, drafts } = selectedAssessment || {}
  const name = useMemo(
    () => (selectedAssessment ? getAssessmentName(selectedAssessment) : ''),
    [selectedAssessment],
  )
  if (!midwife && loading) return <LoadingIcon />
  return (
    <Box px={2} w="100%">
      <SelectAssessmentBox overlay>
        {midwife ? (
          <Stack pt={4} direction="column" spacing={3}>
            {/* <PaymentsNote /> */}
            <PaymentsLog />
            <AssessmentPayments />
            {appName === 'app' ? (
              <DraftView
                name="Receipts/Invoices Template"
                draft={drafts?.invoicesReceiptsTemplates}
                templateKey="invoiceAndReceipt"
              />
            ) : null}
            {assessmentId && patientId ? (
              <AssessmentDocuments
                adminView
                patientId={patientId}
                category="invoiceAndReceipt"
                assessmentId={assessmentId}
              />
            ) : null}
          </Stack>
        ) : (
          <Text p={4}>
            <i>No midwife assigned to {name}</i>
          </Text>
        )}
      </SelectAssessmentBox>
    </Box>
  )
}

export const PaymentsPopUp = () => {
  const { width: screenWidth } = useContext(ScreenContext)
  const { isOpen, onClose, onOpen } = useDisclosure()
  const width = useMemo(() => Math.min(screenWidth * 0.95, 600), [screenWidth])
  return (
    <>
      <Tooltip placement="top" label="Patient Payments">
        <Button
          onClick={onOpen}
          bg="green.600"
          color="white"
          position="absolute"
          left={2}
          top={2}
          size="xs">
          $
        </Button>
      </Tooltip>
      <DefaultModal
        size="2xl"
        overlayHeader
        isOpen={isOpen}
        contentProps={{ p: 0, width: `${width}px` }}
        bodyProps={{ p: 2 }}
        onClose={onClose}
        render={() => <AssessmentPaymentsTab />}
      />
    </>
  )
}
