import { Box, Flex } from '@chakra-ui/react'
import React, { useRef } from 'react'
import { useProfile } from '../../../../../../contexts'
import { useResizeObserver } from '../../../../../../hooks'
import { AssessmentsView } from '../../../../../Assessments/AssessmentSummary'
import { LoadingOverlay } from '../../../../../LoadingOverlay'
import { Visits } from '../../../Charting/Visits'
import { CardsView } from '../Containers/CardsView'
import { PreviewBox } from '../Containers/PreviewBox'

const PregnanciesPreview = () => {
  return (
    <PreviewBox title="Pregnancies">
      <Box w="100%">
        <AssessmentsView preview />
      </Box>
    </PreviewBox>
  )
}

const VisitsPreview = () => {
  const { userId, assessmentId } = useProfile()
  return (
    <PreviewBox fixed h="325px" title="Visits">
      {userId ? (
        <Visits
          onlyStandaloneVisits={false}
          preview
          patientId={userId}
          assessmentId={assessmentId || null}
        />
      ) : (
        <LoadingOverlay text="Loading visits..." />
      )}
    </PreviewBox>
  )
}

export const PatientControlPanel = () => {
  const bodyRef = useRef<HTMLDivElement>(null)
  const { width } = useResizeObserver(bodyRef, 'content')
  return (
    <Flex bg="#dedede" ref={bodyRef} w="100%" h="100%" flexFlow="column">
      <CardsView width={width} elements={[<PregnanciesPreview />, <VisitsPreview />]} />
    </Flex>
  )
}
