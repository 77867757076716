import { UseProfileArgs } from '../../../contexts'

export type UserProfileTabName =
  | 'patient'
  // | 'assessment' // to be removed with v2
  | 'pregnancy'
  | 'payments'
  | 'auth' // to be removed with v2
  | 'claims'
  | 'files'
  | 'visits'
  | 'consent-forms'

export type V1ProfileTabName =
  | 'patient'
  // | 'assessment'
  | 'pregnancy'
  | 'payments'
  | 'auth'
  | 'claims'
  | 'files'
  | 'visits'
  | 'consent-forms'

export type PatientTabName = 'consent-forms' | 'visits'
export type PregnancyTabName =
  | 'assessment'
  | 'sign-on'
  | 'authorizations'
  | 'plans'
  | 'payments'
  | 'insurance'
export type SubTabName = PatientTabName | PregnancyTabName
const patientTabNames: PatientTabName[] = ['consent-forms', 'visits']
const pregnancyTabNames: PregnancyTabName[] = [
  'assessment',
  'authorizations',
  'sign-on',
  'plans',
  'payments',
  'insurance',
]
export const isPatientTab = (tab: SubTabName): tab is PatientTabName =>
  patientTabNames.includes(tab as PatientTabName)
export const isPregnancyTab = (tab: SubTabName): tab is PregnancyTabName =>
  pregnancyTabNames.includes(tab as PregnancyTabName)

export interface UserProfileProps {
  userId?: string
  onClose: () => void
  selectPregnancy: (id: string | null) => void
  pregnancyId?: string
  tab?: UserProfileTabName
  subTab?: SubTabName | null
  profileVersion: 'v1' | 'v2'
  onTabSelect: UseProfileArgs['onTabSelect']
  claimId?: string
}
