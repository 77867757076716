import { CircularProgress, HStack, Text, VStack } from '@chakra-ui/react'
import { Fax, objectToArray } from '@hb/shared'
import { query, where } from 'firebase/firestore'
import React, { useContext, useMemo } from 'react'
import { FAX_RECEIVED_REF, FAX_SENT_REF } from '../../collections/firestoreCollections'
import { ProfileContext } from '../../contexts'
import { useQuery } from '../../hooks/backend/useQuery'
import { Expandable } from '../Expandable'
import { FaxPreview } from '../Faxes/FaxView'
import { BoxHeader } from '../Text'

export const AssessmentFaxes = () => {
  const { assessmentId } = useContext(ProfileContext)
  const sentFaxesQuery = useMemo(
    () => (assessmentId ? query(FAX_SENT_REF, where('assessmentId', '==', assessmentId)) : null),
    [assessmentId],
  )
  const receivedFaxesQuery = useMemo(
    () =>
      assessmentId ? query(FAX_RECEIVED_REF, where('assessmentId', '==', assessmentId)) : null,
    [assessmentId],
  )
  const { data: sentFaxes, loading: loadingSentFaxes } = useQuery<Fax>(sentFaxesQuery)
  const { data: receivedFaxes, loading: loadingReceivedFaxes } = useQuery<Fax>(receivedFaxesQuery)
  const sortedByDate = useMemo(() => {
    const arr = [...objectToArray(sentFaxes || {}), ...objectToArray(receivedFaxes || {})]
    return arr.sort((a, b) => {
      const aDate = new Date(a.created_at).getTime()
      const bDate = new Date(b.created_at).getTime()
      if (!aDate || !bDate) return 0
      return bDate - aDate
    })
  }, [sentFaxes, receivedFaxes])

  return (
    <Expandable
      initExpanded
      borderRadius={6}
      boxShadow="md"
      px={2}
      py={1}
      bg="gray.50 "
      header={() => <BoxHeader>Faxes</BoxHeader>}>
      {loadingReceivedFaxes || loadingSentFaxes ? (
        <HStack>
          <CircularProgress isIndeterminate /> <Text>Loading faxes...</Text>
        </HStack>
      ) : (
        <VStack py={2}>
          {sortedByDate.map(fax => (
            <FaxPreview key={fax.id} fax={fax} />
          ))}
        </VStack>
      )}
    </Expandable>
  )
}
