import {
  AssessmentDocumentArgs,
  Descendant,
  EditorDraft,
  EditorVersion,
  formatPhoneNumber,
  isDocumentType,
  isVersion2Draft,
  SendFaxArgs,
  TemplateEditorDraft,
  templateKeyToCollection,
  templateTypeNames,
  UpdateCallback,
} from '@hb/shared'

import React, { useCallback, useContext, useMemo } from 'react'
import { useApp } from '../../contexts'
import { PopUpMessageContext } from '../../contexts/PopUpMessage/PopUpMessageContext'
import { ProfileContext } from '../../contexts/ProfileContext'
import { sendAssessmentDocument } from '../../hooks/backend/documents/utils'
import { sendFax } from '../../hooks/backend/faxes'
import { useUpdateDoc } from '../../hooks/backend/useUpdateDoc'

import { addMetadata } from '../../utils'
import {
  AssessmentDataProvider,
  TemplateInsurancePlansProvider,
  TemplateViewProvider,
} from './contexts'
import { TemplateViewModal } from './TemplateViewModal'
import { TemplateViewProps } from './types'

export const ViewedTemplateModal = () => {
  const {
    selectedAssessment,
    assessmentId,
    viewedTemplate,
    patientRef,
    adminPatientRef,
    setViewedTemplateKey,
    user,
  } = useContext(ProfileContext)
  const { showError, showSuccess } = useContext(PopUpMessageContext)
  const { adminRef, populated } = selectedAssessment || {}
  const handleFax = useCallback(
    async (data: Omit<SendFaxArgs, 'assessmentId' | 'category'>) => {
      if (!assessmentId) return { error: 'Internal error - missing assessment id' }
      if (!viewedTemplate) return { error: 'Internal error - missing viewed template' }
      try {
        await sendFax({
          ...data,
          assessmentId,
          category: viewedTemplate.templateType,
        })
        return {
          success: `Fax sent to ${formatPhoneNumber(data.faxToNumber)}.`,
        }
      } catch (error: any) {
        return {
          error: error?.message || 'An error occurred - post a message in the group chat!',
        }
      }
    },
    [assessmentId, viewedTemplate],
  )

  const handleSubmit = useCallback(
    async (
      text: Descendant[],
      editorVersion: EditorVersion,
      templateId: string | null,
      name: string,
    ): Promise<UpdateCallback> => {
      if (!viewedTemplate?.collection) {
        return { error: 'internal error' }
      }
      if (
        !isDocumentType(viewedTemplate.templateType) &&
        viewedTemplate.templateType !== 'consentForm'
      ) {
        return { error: 'Not a sendable document type' }
      }
      const documentType = viewedTemplate.templateType
      if (!documentType) {
        return { error: 'internal error' }
      }
      const documentTypeName = templateTypeNames[documentType]

      if (!patientRef?.id) return { error: 'Internal error - no patient id' }
      if (!assessmentId && documentType !== 'consentForm')
        return { error: 'Internal error - no assessment id' }
      const document: AssessmentDocumentArgs = {
        assessmentId: assessmentId || null,
        patientId: patientRef.id,
        templateId,
        name,
        type: documentType,
        editorVersion,
        text,
      }
      try {
        await sendAssessmentDocument({
          document,
        })
        const successMessage = `Successfully sent ${documentTypeName} document`
        showSuccess(successMessage)
        return { success: successMessage }
      } catch (err: any) {
        const errMessage = `Error sending document: ${err?.message || 'No message'}`
        showError(errMessage)
        return { error: errMessage }
      }
    },
    [viewedTemplate, assessmentId, showError, showSuccess, patientRef],
  )

  const submitText = useMemo<TemplateViewProps['submitText']>(() => {
    switch (viewedTemplate?.templateType) {
      case 'authAppeals':
        return { submit: 'Send Auth Appeal', confirm: 'Confirm send' }
      case 'authInstructions':
        return { submit: 'Send Auth Instructions', confirm: 'Confirm send' }
      case 'invoiceAndReceipt':
        return { submit: 'Send Invoice / Receipt', confirm: 'Confirm send' }
      case 'claims':
        return { submit: 'Send Claim Document', confirm: 'Confirm send' }
      case 'consentForm': {
        return { submit: 'Send Consent Form', confirm: 'Confirm send' }
      }
      default:
        return {
          submit: 'Error - unhandled viewed template collection',
          confirm: 'Error - unhandled viewed template collection',
        }
    }
  }, [viewedTemplate])

  const update = useUpdateDoc('draft')
  const canSubmit = useMemo(
    () =>
      viewedTemplate
        ? isDocumentType(viewedTemplate.templateType) ||
          viewedTemplate.templateType === 'consentForm'
        : false,
    [viewedTemplate],
  )

  const { appName } = useApp()

  const baseDraft = useMemo(() => {
    if (viewedTemplate?.templateType === 'consentForm') {
      return user?.drafts?.consentFormTemplates
    }
    if (!selectedAssessment) return undefined
    const collectionName = viewedTemplate
      ? templateKeyToCollection[viewedTemplate.templateType]
      : ''
    return collectionName ? populated?.drafts?.[collectionName] : undefined
  }, [selectedAssessment, viewedTemplate, user, populated])

  const handleSave = useCallback(
    async (d: Descendant[], v: EditorVersion) => {
      if (!viewedTemplate) {
        return { error: 'internal error' }
      }
      const collectionName = templateKeyToCollection[viewedTemplate.templateType]
      const newDraft: TemplateEditorDraft = addMetadata(
        {
          templateType: viewedTemplate.templateType,
          text: d,
          editorVersion: v,
        },
        appName,
        !baseDraft,
      )
      return update(
        viewedTemplate.templateType === 'consentForm' ? adminPatientRef : adminRef,
        'drafts',
        {
          [collectionName]: newDraft,
        },
      )
    },
    [adminPatientRef, adminRef, update, viewedTemplate, baseDraft, appName],
  )

  const draft = useMemo<EditorDraft | null>(() => {
    if (!baseDraft) return null
    return isVersion2Draft(baseDraft) ? baseDraft : { text: baseDraft, editorVersion: 'v1' }
  }, [baseDraft])

  return selectedAssessment && viewedTemplate ? (
    <TemplateViewProvider
      onSave={handleSave}
      onFax={handleFax}
      draft={draft}
      autoTemplateId={viewedTemplate.id}
      type={viewedTemplate.templateType}
      onSubmit={canSubmit ? handleSubmit : undefined}>
      <TemplateInsurancePlansProvider>
        <AssessmentDataProvider editMode="document" type={viewedTemplate.templateType}>
          <TemplateViewModal
            isOpen={!!viewedTemplate}
            submitText={submitText}
            // overlayHeader={false}
            onClose={() => setViewedTemplateKey(null)}
            {...viewedTemplate}
          />
        </AssessmentDataProvider>
      </TemplateInsurancePlansProvider>
    </TemplateViewProvider>
  ) : null
}
