import { Box, Button, Center, Divider, Text, VStack } from '@chakra-ui/react'
import { colors } from '@hb/shared'
import React, { useMemo } from 'react'
import { useProfile } from '../../../../../../contexts/ProfileContext'
import { PatientPregnancySummary } from '../../../Patient/PatientPregnancySummary'
import { PatientAlerts } from './PatientAlerts'
import { PatientName } from './PatientName'
import { PatientNotes } from './PatientNotes'

const GoToConsentForms = () => {
  const { onTabSelect, consentForms } = useProfile()
  const { data } = consentForms
  const { numConsentForms, numSigned, progress } = useMemo(() => {
    const numConsentForms = Object.keys(data || {}).length
    const numSigned = Object.values(data || {}).filter(c => !!c.signedOn).length
    const progress = numConsentForms ? (numSigned / numConsentForms) * 100 : 0
    return { numConsentForms, numSigned, progress }
  }, [data])
  return (
    <Center w="100%">
      <Button
        onClick={() => onTabSelect('consent-forms', null)}
        color={numConsentForms ? 'white' : 'gray.500'}
        textShadow={numConsentForms ? '1px 1px 3px #00000077' : 'none'}
        bg={numConsentForms ? colors.green.hex : 'gray.50'}
        size="sm"
        border="1px solid"
        borderColor={numConsentForms ? colors.green.hex : 'gray.400'}
        position="relative">
        <Box
          position="absolute"
          top={0}
          left={0}
          bottom={0}
          w={`${progress}%`}
          bg={colors.green.hex}
        />
        <Text zIndex={1}>
          {numConsentForms
            ? `${numSigned}/${numConsentForms} Consent Form${numConsentForms === 1 ? '' : 's'} Signed`
            : 'No Consent Forms'}
        </Text>
      </Button>
    </Center>
  )
}

export const PatientInfo = () => {
  return (
    <VStack
      divider={<Divider borderColor="#00000033" />}
      p={1}
      bg={`${colors.pink.hex}77`}
      flexFlow="column"
      h="100%"
      w="100%">
      <PatientName />
      <PatientPregnancySummary />
      <PatientAlerts />
      <PatientNotes />
      <GoToConsentForms />
    </VStack>
  )
}
