import { ShortcutArgs, TemplateKey } from '@hb/shared'
import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react'

export type EditMode = 'template' | 'document'
type TemplateDataContextValue = {
  shortcutArgs: ShortcutArgs
  type: TemplateKey
}
export const TemplateDataContext = createContext<TemplateDataContextValue>({
  type: 'assessments',
  shortcutArgs: { assessmentId: null },
})

export const TemplateDataProvider = ({
  data,
  type,
  children,
}: PropsWithChildren<{
  data: ShortcutArgs
  type: TemplateKey
}>) => {
  const value = useMemo<TemplateDataContextValue>(
    () => ({ shortcutArgs: data, type }),
    [data, type],
  )
  return <TemplateDataContext.Provider value={value}>{children}</TemplateDataContext.Provider>
}

export const useTemplateData = () => useContext(TemplateDataContext)
