import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  ASSESSMENTS,
  colors,
  formatField,
  makeAllFieldsOptional,
  Note,
  planDesignField,
  signOnFields,
} from '@hb/shared'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { updateSharedNote } from '../../backend'
import { useApp } from '../../contexts/AppContext'
import { PopUpMessageContext } from '../../contexts/PopUpMessage/PopUpMessageContext'
import { ProfileContext, useProfile } from '../../contexts/ProfileContext'
import { useUpdateDoc } from '../../hooks/backend/useUpdateDoc'
import { useMobileLayout } from '../../hooks/useMobileLayout'
import { useScreen } from '../../hooks/useScreen'
import { addMetadata } from '../../utils'
import { DataView } from '../DataView/DataView'
import { NoteForm } from '../forms/NoteForm'
import { MenuTabList } from '../shared/Nav/MenuTabList'
import { SubTab } from '../shared/Nav/types'
import { PregnancyTabName } from '../Users'
import { SignOnRemindersView } from '../Users/Profile/Assessments/SignOnRemindersView'
import { SkipToSignOn } from '../Users/Profile/Assessments/SkipToSignOn'
import { getV1PregnancyTabs } from '../Users/Profile/Nav/tabs'
import { AssessmentPaymentsTab } from '../Users/Profile/Payments/AssessmentPayments'
import { AssessmentFaxes } from './AssessmentFaxes'
import { AssessmentModal } from './AssessmentModal/AssessmentModal'
import { AssessmentPreview } from './AssessmentPreview'
import { InsuranceCardsView } from './InsuranceCardsView'
import { AdminInsurancePlansView } from './InsurancePlansView'

const ArchivedAssessments = ({ preview }: { preview?: boolean }) => {
  const { assessmentId, selectPregnancy, user, assessments } = useContext(ProfileContext)
  const archivedAssessments = useMemo(
    () =>
      Object.entries(assessments || {}).filter(
        ([id, val]) => !!val.archivedOn && id !== assessmentId,
      ),
    [assessments, assessmentId],
  )
  return user ? (
    <Accordion allowMultiple defaultIndex={[]} width="100%">
      <AccordionItem>
        <AccordionButton py={1}>
          <Box fontFamily="Hero-New" as="span" flex="1" textAlign="left">
            <Text
              fontWeight={500}
              fontSize="xs">{`ARCHIVED PREGNANCIES (${archivedAssessments.length})`}</Text>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel px={2} py={0}>
          <Flex w="100%" direction="column" gap={2}>
            {archivedAssessments.map(([id, assessment]) => (
              <AssessmentPreview
                id={id}
                key={id}
                preview={preview}
                buttonText={id === assessmentId ? 'Selected' : 'Select'}
                onSelect={() => selectPregnancy(id)}
                user={user}
                assessment={assessment}
              />
            ))}
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  ) : null
}

const AssessmentNote = () => {
  const {
    selectedAssessment: { populated: selectedAssessment },
    assessmentId,
  } = useContext(ProfileContext)
  const { sharedNote } = selectedAssessment || {}
  const { appName } = useApp()
  const { processResponse } = useContext(PopUpMessageContext)

  const handleSubmitSharedNote = useCallback(
    async (data: Note) => {
      if (!assessmentId) return processResponse({ error: 'No assessment selected' })
      const { text } = data
      try {
        await updateSharedNote({
          appName,
          assessmentId,
          note: text,
          noteId: 'sharedNote',
        })
        return processResponse({ success: 'Note saved' })
      } catch (err: any) {
        return processResponse({ error: err.message || 'Error saving note' })
      }
    },
    [appName, assessmentId, processResponse],
  )

  const { mobileLayout, ref: contentRef } = useMobileLayout(700)
  return (
    <Flex gap={2} flexFlow={mobileLayout ? 'column' : 'row'} ref={contentRef} w="100%">
      <NoteForm
        onSubmit={handleSubmitSharedNote}
        placeholder="Shared note (visible to HB and practice)"
        note={sharedNote}
      />
    </Flex>
  )
}
export const AssessmentsView = ({ preview }: { preview?: boolean }) => {
  const {
    assessments,
    user,
    selectPregnancy,
    assessmentId,
    selectedAssessment: { populated: selectedAssessment, assessmentData: selectedAssessmentData },
    admin,
    profileVersion,
  } = useProfile()
  const notSelected = useMemo(() => {
    const entries = Object.entries(assessments || {})
    const filtered = preview ? entries : entries.filter(([id]) => id !== assessmentId)
    return filtered.sort(([, a], [, b]) => b.createdOn - a.createdOn)
  }, [assessments, assessmentId, preview])
  const notArchived = useMemo(() => notSelected.filter(([, val]) => !val.archivedOn), [notSelected])

  const [assessmentModalIsOpen, setAssessmentModalIsOpen] = useState(false)

  const onSelect = useCallback(
    (id: string) => {
      switch (profileVersion) {
        case 'v2':
          selectPregnancy(id)
          break
        default:
          if (id === assessmentId) setAssessmentModalIsOpen(true)
          else selectPregnancy(id)
      }
    },
    [assessmentId, profileVersion, selectPregnancy],
  )

  const notSelectedButtonText = useMemo(() => {
    switch (profileVersion) {
      case 'v2':
        return 'View'
      default:
        return 'Open'
    }
  }, [profileVersion])

  return user ? (
    <VStack align="flex-start" spacing={1} width="100%">
      {assessmentId && !preview ? (
        <Flex flexFlow="column" w="100%">
          <Text fontWeight="semibold" color="gray.500">
            Selected assessment
          </Text>
          <AssessmentPreview
            id={assessmentId}
            selected
            buttonText={profileVersion === 'v2' ? 'View' : 'Open'}
            preview={preview}
            onSelect={() => onSelect(assessmentId)}
            user={user}
            assessment={selectedAssessmentData}
          />
          {preview ? null : <AssessmentNote />}
        </Flex>
      ) : null}
      <Flex px={preview ? 2 : 0} flexFlow="column" w="100%">
        {preview ? null : (
          <Text px={2} fontWeight="semibold" color="gray.500">
            {assessmentId ? 'Other assessments' : 'Assessments'}
          </Text>
        )}
        <Flex w="100%" direction="column" pt={preview ? 2 : 0} gap={2}>
          {notArchived.map(([id, assessment]) => (
            <AssessmentPreview
              id={id}
              key={id}
              preview={preview}
              selected={id === assessmentId}
              buttonText={notSelectedButtonText}
              onSelect={() => onSelect(id)}
              user={user}
              assessment={assessment}
            />
          ))}
        </Flex>
      </Flex>
      <ArchivedAssessments preview={preview} />
      {user && assessmentModalIsOpen ? (
        <AssessmentModal
          assessment={selectedAssessment}
          admin={admin}
          user={user}
          id={assessmentId}
          isOpen={assessmentModalIsOpen}
          onClose={() => setAssessmentModalIsOpen(false)}
        />
      ) : null}
    </VStack>
  ) : (
    <Text color="red.500">Error: no patient</Text>
  )
}

const SignOnInfoPanel = ({ mobileLayout }: { mobileLayout: boolean }) => {
  const { appName } = useApp()
  const {
    selectedAssessment: { ref, populated: selectedAssessment },
    assessmentId,
  } = useContext(ProfileContext)
  const { signOnData, signOnCorrections, selectedCoverage, insuranceCoverage } =
    selectedAssessment || {}
  const { memberId, 'plan-design-and-state-mandates': planDesignAndStateMandates } =
    selectedCoverage || {}
  const { planDesign } = planDesignAndStateMandates || {}
  const planDesignText = useMemo(() => {
    if (!planDesign) return ''
    return `${formatField(planDesignField, planDesign)}`
  }, [planDesign])
  const update = useUpdateDoc('Sign on data')
  const optionalSignOnFields = useMemo(() => makeAllFieldsOptional(signOnFields), [])

  return (
    <VStack w="100%">
      <Flex flexFlow={mobileLayout ? 'column' : 'row'} gap={2} align="center" w="100%">
        <Flex w="100%" gap={2} align="center" flex={mobileLayout ? 'unset' : 1}>
          <SignOnRemindersView />
          <HStack
            px={2}
            bg={colors.green.hex}
            flex={1}
            minW="0"
            py={1}
            color="white"
            textShadow="1px 1px 1px rgba(0,0,0,0.5)"
            fontFamily="Comfortaa"
            borderRadius={6}
            boxShadow="md">
            <Text flex={1} minW="0" isTruncated mr="4px" fontWeight={600}>
              {`${insuranceCoverage || '(No insurer set)'} Member ID:`}
            </Text>
            <Text fontFamily="Hero-New">
              {typeof memberId === 'string' ? memberId || 'None' : 'None'}
              {planDesignText ? ` - ${planDesignText}` : ''}
            </Text>
          </HStack>
          {appName === 'app' ? <SkipToSignOn mobileLayout={mobileLayout} /> : null}
        </Flex>
      </Flex>
      <Box w="100%" borderRadius={4} p={2} bg="white" boxShadow="md">
        <DataView
          initExpanded
          withEditModal
          adminView
          childrenExpanded
          onSubmit={data =>
            update(ref, 'signOnCorrections', addMetadata(data, appName, !signOnCorrections))
          }
          baseStoragePath={`${ASSESSMENTS}/${assessmentId}/signOnCorrections`}
          updateField={(path, value) => update(ref, `signOnCorrections.${path}`, value)}
          field={optionalSignOnFields}
          corrections={signOnCorrections}
          data={signOnData}
        />
      </Box>
      <InsuranceCardsView />
      {appName === 'app' ? <AssessmentFaxes /> : null}
    </VStack>
  )
}

const DesktopTabs = ({ tabs, mobileLayout }: { tabs: Array<SubTab>; mobileLayout: boolean }) => {
  const { subTab, onTabSelect } = useProfile()
  const handleSelect = useCallback(
    (updated: string) => {
      onTabSelect(`pregnancy/${updated}`, null)
    },
    [onTabSelect],
  )
  if (mobileLayout) {
    return <MenuTabList onSelect={handleSelect} tabs={tabs} path={subTab} />
  }
  return (
    <TabList>
      {tabs.map((tab, i) => (
        <Tab
          mr={3}
          key={i}
          background="white"
          fontSize="sm"
          _selected={{ background: colors.pink.hex, color: '#333' }}
          shadow="md">
          {tab.name}
        </Tab>
      ))}
    </TabList>
  )
}

const getTabIndex = (tabs: SubTab[], subTab?: PregnancyTabName): number => {
  if (!subTab) return 0
  const idx = tabs.findIndex(t => t.path === subTab)
  return idx >= 0 ? idx : 0
}

export const AssessmentSummary = ({ width }: { width: number }) => {
  const { appName } = useApp()
  const { isMobile } = useScreen()
  const mobileLayout = useMemo(() => width < 500, [width])
  const { onTabSelect, subTab, profileVersion } = useProfile()
  // const [tabIndex, setTabIndex] = useState(0)
  const tabs = useMemo(() => getV1PregnancyTabs(appName), [appName])
  const tabIndex = useMemo(() => getTabIndex(tabs, subTab as PregnancyTabName), [tabs, subTab])
  const onTabChange = useCallback(
    (tabIndex: number) => {
      const tab = tabs[tabIndex]
      if (profileVersion === 'v1') onTabSelect(`pregnancy/${tab.path}`, null)
      else onTabSelect(`pregnancy/${tab.path}`, null)
    },
    [tabs, onTabSelect, profileVersion],
  )
  return (
    <Box w="100%">
      <Tabs onChange={onTabChange} index={tabIndex} size="sm" isLazy variant="soft-rounded">
        {!isMobile ? (
          <Flex
            borderBottom={mobileLayout ? '1px solid #cdcdcd' : 'none'}
            bg={mobileLayout ? 'white' : 'transparent'}
            gap={0}
            px={mobileLayout ? 0 : 4}
            pt={mobileLayout ? 0 : 2}
            w="100%">
            <DesktopTabs mobileLayout={mobileLayout} tabs={tabs} />
          </Flex>
        ) : null}
        <TabPanels>
          {appName === 'app' ? (
            <TabPanel p={isMobile ? 1 : 2}>
              <AssessmentsView />
            </TabPanel>
          ) : (
            <TabPanel p={isMobile ? 1 : 2}>
              <SignOnInfoPanel mobileLayout={mobileLayout} />
            </TabPanel>
          )}
          {appName === 'app' ? (
            <TabPanel>
              <SignOnInfoPanel mobileLayout={mobileLayout} />
            </TabPanel>
          ) : (
            <TabPanel>
              <AssessmentsView />
            </TabPanel>
          )}
          {appName === 'providers-app' ? (
            <TabPanel>
              <AssessmentPaymentsTab />
            </TabPanel>
          ) : null}
          <TabPanel p={mobileLayout ? 0 : 2}>
            <AdminInsurancePlansView />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}
