import { useMemo } from 'react'
import { useApp, usePracticeAccess } from '../../../contexts'
import { useCollectionItem } from '../useCollectionItem'
import { useSelectedPracticeCollection } from './useSelectedPracticeCollection'

export const useSelectedPractice = () => {
  const { selectedPracticeId } = usePracticeAccess()
  const { appName } = useApp()
  const selectedPracticeCollection = useSelectedPracticeCollection()

  const { item, loading } = useCollectionItem(selectedPracticeCollection, selectedPracticeId)

  return useMemo(
    () => ({
      id: selectedPracticeId,
      practice: appName === 'app' ? undefined : item || null,
      loading,
    }),
    [item, loading, appName, selectedPracticeId],
  )
}
