import {
  Descendant,
  EditorDraft,
  EditorVersion,
  PopulatedNode,
  SendFaxArgs,
  Template,
  TemplateKey,
  templateKeyToCollection,
  UpdateCallback,
} from '@hb/shared'
import React, {
  createContext,
  forwardRef,
  PropsWithChildren,
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { emptyUseDocument, useDocument } from '../../../hooks/backend/useDocument'
import { UseDocument } from '../../../types'

type TemplateViewContextValue = {
  onSave:
    | ((text: Descendant[], editorVersion: EditorVersion) => Promise<UpdateCallback>)
    | undefined
  onSubmit:
    | ((
        text: PopulatedNode[],
        editorVersion: EditorVersion,
        templateId: string | null,
        name: string,
      ) => Promise<UpdateCallback>)
    | undefined
  onFax:
    | ((args: Omit<SendFaxArgs, 'assessmentId' | 'category'>) => Promise<UpdateCallback>)
    | undefined
  initialText?: Descendant[]
  editorVersion: EditorVersion
  template: UseDocument<Template>
  type: TemplateKey
  updateInitialText?: (version: EditorVersion, text: Descendant[]) => void
  manualTemplateId: string | null
  setManualTemplateId: (id: string | null) => void
}

const TemplateViewContext = createContext<TemplateViewContextValue>({
  onSave: () => {
    throw new Error('Outside of template view context')
  },
  onSubmit: () => {
    throw new Error('Outside of template view context')
  },
  onFax: () => {
    throw new Error('Outside of template view context')
  },
  manualTemplateId: null,
  template: emptyUseDocument,
  type: 'assessments',
  editorVersion: 'v2',
  setManualTemplateId: () => {
    throw new Error('Outside of template view context')
  },
})

type TemplateViewProviderProps = {
  type: TemplateKey
  onSave?: TemplateViewContextValue['onSave']
  onSubmit?: TemplateViewContextValue['onSubmit']
  onFax?: TemplateViewContextValue['onFax']
  autoTemplateId?: string
  draft?: EditorDraft | null
}

export type TemplateViewRef = {
  draft: EditorDraft | null
  updateDraft: (draft: EditorDraft) => void
  manualTemplateId: string | null
  updateManualTemplateId: (id: string | null) => void
}

export const TemplateViewProvider = forwardRef<
  TemplateViewRef,
  PropsWithChildren<TemplateViewProviderProps>
>(({ children, onSave, onSubmit, autoTemplateId, draft: propDraft, type, onFax }, ref) => {
  const [manualTemplateId, setManualTemplateId] = useState<string | null>(null)

  const collection = useMemo(() => templateKeyToCollection[type], [type])
  const templateData = useDocument<Template>(collection, manualTemplateId || autoTemplateId)

  const { data: template } = templateData

  const [draft, setDraft] = useState<EditorDraft | null>(propDraft || null)

  const updateDraft = useCallback((draft: EditorDraft | null) => {
    setDraft(draft)
    setManualTemplateId(null)
  }, [])
  const updateManualTemplateId = useCallback((id: string | null) => {
    setDraft(null)
    setManualTemplateId(id)
  }, [])

  const editorVersion = useMemo<EditorVersion>(() => {
    if (manualTemplateId) return template?.editorVersion || 'v1'
    if (draft) return draft.editorVersion || 'v1'
    if (template) return template?.editorVersion || 'v1'
    return 'v2'
  }, [draft, manualTemplateId, template])

  const initialText = useMemo(() => {
    if (manualTemplateId) return template?.templateText || []
    if (draft) return draft.text
    return template?.templateText || undefined
  }, [draft, manualTemplateId, template])

  useImperativeHandle(
    ref,
    () => ({ draft, updateDraft, manualTemplateId, updateManualTemplateId }),
    [manualTemplateId, draft, updateDraft, updateManualTemplateId],
  )

  const contextValue = useMemo<TemplateViewContextValue>(
    () => ({
      onSave,
      onSubmit,
      onFax,
      editorVersion,
      type,
      initialText,
      template: templateData,
      manualTemplateId,
      setManualTemplateId: updateManualTemplateId,
    }),
    [
      type,
      onSave,
      onSubmit,
      onFax,
      initialText,
      editorVersion,
      templateData,
      manualTemplateId,
      updateManualTemplateId,
    ],
  )
  return (
    <TemplateViewContext.Provider value={contextValue}>{children}</TemplateViewContext.Provider>
  )
})

export const useTemplateView = () => useContext(TemplateViewContext)
