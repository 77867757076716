import { PopulatedAssessment } from '@hb/shared'
import { useMemo } from 'react'

export const useAssessmentAutoTemplate = (assessment: PopulatedAssessment | null) => {
  const { mergedData, insuranceProvider } = assessment || {}

  return useMemo(() => {
    const { plan } = mergedData?.['insurance-info']?.primaryCoverage || {}
    if (plan && plan.templateId) return plan.templateId
    if (insuranceProvider && insuranceProvider.defaultTemplateId) {
      return insuranceProvider.defaultTemplateId
    }
    return undefined
  }, [mergedData, insuranceProvider])
}
