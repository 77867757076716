import { AppName } from '@hb/shared'
import { SubTab, SubTabGroup } from '../../../shared/Nav/types'
import { PregnancyTabName, UserProfileTabName } from '../types'

type PregnancyTab = SubTab<PregnancyTabName>
const assessmentTab: PregnancyTab = {
  path: 'assessment',
  name: 'Assessment',
}
const insuranceInfoTab: PregnancyTab = {
  path: 'insurance',
  name: 'Insurance Info',
}
const signOnInfoTab: PregnancyTab = {
  path: 'sign-on',
  name: 'Sign On Info',
}
const paymentsTab: PregnancyTab = {
  path: 'payments',
  name: 'Payments',
}
const plansTab: PregnancyTab = {
  path: 'plans',
  name: 'Insurance Plans',
}
const authorizationsTab: PregnancyTab = {
  path: 'authorizations',
  name: 'Authorizations',
}

export const getV1PregnancyTabs = (appName: string): SubTab[] => {
  const tabs: Array<SubTab> = []
  tabs.push(appName === 'app' ? assessmentTab : insuranceInfoTab)
  tabs.push(appName === 'app' ? signOnInfoTab : assessmentTab)
  tabs.push(plansTab)
  return tabs
}

export const getPregnancyTabs = (appName: string): SubTab[] => {
  const tabs: Array<SubTab> = []
  tabs.push(appName === 'app' ? assessmentTab : insuranceInfoTab)
  // tabs.push(appName === 'app' ? signOnInfoTab : summaryTab)
  if (appName === 'app') {
    tabs.push(signOnInfoTab)
    tabs.push(authorizationsTab)
  }
  // if (appName === 'providers-app') tabs.push(paymentsTab)
  tabs.push(paymentsTab)
  tabs.push(plansTab)
  return tabs
}

/*
 const tabs = useMemo<Array<string>>(() => {
    const tabs: Array<string> = []
    tabs.push(appName === 'app' ? 'Patient' : 'Patient / Pregnancy')
    if (selectedAssessment) tabs.push(appName === 'app' ? 'Assessment' : 'Billing / Insurance')
    if (appName === 'app' && selectedAssessment) {
      tabs.push('Payments')
      tabs.push('Authorizations')
      tabs.push('Claims / Charting')
    }
    if (selectedAssessment) tabs.push('Files')
    if (selectedAssessment) tabs.push('Visits')
    return tabs
  }, [appName, selectedAssessment])
*/

const getPregnancyTab = (appName: AppName): SubTabGroup => {
  return {
    path: 'pregnancy',
    // name: appName === 'app' ? 'Assessment' : 'Billing / Insurance',
    name: 'Pregnancy',
    children: getPregnancyTabs(appName),
  }
}

type ProfileTab = SubTab<UserProfileTabName>
export const patientTab: ProfileTab = {
  path: 'patient',
  name: 'Patient',
}
export const pregnancyTab: ProfileTab = {
  path: 'pregnancy',
  name: 'Pregnancy',
}
const consentFormTab: ProfileTab = {
  path: 'consent-forms',
  name: 'Consent Forms',
}
const patientPregnancyTab: ProfileTab = {
  path: 'patient',
  name: 'Patient / Pregnancy',
}

const claimsTab: ProfileTab = {
  path: 'claims',
  name: 'Claims / Charting',
}
const filesTab: ProfileTab = { path: 'files', name: 'Files' }
const visitsTab: ProfileTab = { path: 'visits', name: 'Visits' }

export const getTabsV2 = (
  appName: AppName,
  assessmentId: string | null | undefined,
): Array<SubTab | SubTabGroup> => {
  const tabs: Array<SubTab | SubTabGroup> = []
  tabs.push(appName === 'app' ? patientTab : patientPregnancyTab)
  if (assessmentId) tabs.push(getPregnancyTab(appName))
  if (appName === 'app' && assessmentId) {
    tabs.push(paymentsTab)
    tabs.push(authorizationsTab)
    tabs.push(claimsTab)
  }
  if (assessmentId) tabs.push(filesTab)
  if (assessmentId) tabs.push(visitsTab)
  tabs.push(consentFormTab)
  return tabs
}

export const getTabsV1 = (
  appName: AppName,
  assessmentId: string | null | undefined,
): Array<SubTab | SubTabGroup> => {
  const tabs: Array<SubTab | SubTabGroup> = []
  tabs.push(patientTab)
  if (assessmentId) tabs.push(assessmentTab)
  if (appName === 'app' && assessmentId) {
    tabs.push(paymentsTab)
    tabs.push(authorizationsTab)
    tabs.push(claimsTab)
  }
  if (assessmentId) tabs.push(filesTab)
  if (assessmentId) tabs.push(visitsTab)
  tabs.push(consentFormTab)
  return tabs
}
