import { AppName } from '@hb/shared'
import { adminAccess } from '@hb/shared/src/constants/users'
import React, { useMemo } from 'react'
import { Redirect, useParams } from 'react-router'
import { useApp } from '../../contexts'
import { View } from '../../types'

export const AdminAssessmentsRedirect = () => {
  const params = useParams<{
    assessmentId?: string
    displayedItem?: string
    subItem?: string
  }>()
  const { appName } = useApp()
  const toPath = useMemo(() => {
    const { assessmentId, displayedItem, subItem } = params
    let p = appName === 'app' ? `/admin/pregnancies` : `/pregnancies`
    if (!assessmentId) return p
    p += `/${assessmentId}`
    if (!displayedItem) return p
    p += `/${displayedItem}`
    if (!subItem) return p
    p += `/${subItem}`
    return p
  }, [params, appName])
  return <Redirect to={toPath} />
}

export const getAdminAssessmentRedirectView = (appName: AppName): View => {
  const prefix = appName === 'app' ? '/admin' : ''
  return {
    name: 'Admin Assessment Redirect',
    path: [
      `${prefix}/assessments/`,
      `${prefix}/assessments/:assessmentId`,
      `${prefix}/assessments/:assessmentId/:displayedItem`,
      `${prefix}/assessments/:assessmentId/:displayedItem/:subItem`,
    ],
    access: adminAccess,
    component: AdminAssessmentsRedirect,
  }
}
